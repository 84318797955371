.home-banner {
    background: linear-gradient(90deg, #12519B 10.49%, #8A1364 94.07%);
    padding: 100px 0px;
}

.blue-bg {
    background: #1F3D6D;
}

.banner-icon-btn {
    display: flex;
    white-space: nowrap;
    color: #fff;
    align-items: center;
    margin-right: 40px;
    font-size: 18px;
    font-weight: 600;
    line-height: 19.81px;
    letter-spacing: 0.48153945803642273px;
    text-align: left;
    min-width: 210px;
}

.banner-icon-btn img {
    margin-right: 10px;
}

.hm-bnr-title {
    font-size: 48px;
    font-weight: 700;
    line-height: 68px;
    text-align: left;
    color: #fff;
}

.hm-bnr-title b {
    height: 50px;
    overflow: hidden;
    display: inline-block;
    font-weight: 700;
    line-height: 135%;
}

.hm-bnr-title span {
    color: #FF84D7;
    transition: 300ms all;
    display: inline-block;
    /* animation: translateWord 1.3s 1; */
}

@keyframes translateWord {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(-60px);
    }

    100% {
        transform: translateY(-150%);
    }
}

.home-banner .site-btn {
    border: 0px;
    background: #fff;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    line-height: 19.81px;
    letter-spacing: 0.48153945803642273px;
    text-align: center;
    color: #2A5DAA;
    text-decoration: none;
    text-transform: capitalize;
    min-width: 260px;
}

.background {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    position: relative;
}

.mn-center-icon {
    width: 90px;
    height: 90px;
    border-radius: 100%;
    background: #fff;
    display: flex;
    box-shadow: 0px 6.21px 12.1px 0px #0000001F;
}

.mn-center-icon>img {
    max-width: 100%;
    margin: auto;
}

.mn-side-circle {
    background: #66095F;
    box-shadow: 0px 4.07px 7.94px 0px #0000001F;
    width: 59.05px;
    height: 59.05px;
    border-radius: 100%;
    outline: 1.74px dashed #B8A5B7;
    outline-offset: 4px;
    display: flex;
    position: absolute;
}

.mn-side-circle>img {
    animation-name: rotateReverseAnimation;
    animation-duration: 20s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.mn-side-circle>img {
    margin: auto;
}

.small-icons {
    animation-name: rotateAnimation;
    animation-duration: 20s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.bi1 {
    top: -9%;
    left: 42%;
}

.bi2 {
    top: 89%;
    left: 40%;
}

.bi3 {
    top: 66%;
    left: 84%;
}

.bi4 {
    top: 17%;
    left: -3%;
}

.bi5 {
    top: 17%;
    left: 85%;
}

.bi6 {
    top: 66%;
    left: -3%;
}

@keyframes rotateAnimation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes rotateReverseAnimation {
    0% {
        transform: rotate(360deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

@media screen and (max-width:580px) {
    .hm-bnr-title {
        font-size: 26px;
        line-height: 40.57px;
    }

    .hm-bnr-title b {
        height: auto;
        line-height: 40.57px;
        display: inline;
    }

    .banner-icon-btn {
        font-size: 13px;
        min-width: 155px;
        margin-right: 10px;
    }

    .banner-icon-btn img {
        max-width: 22px;
    }

    .home-banner .site-btn {
        min-width: 180px;
        padding: 8px 10px;
        font-size: 13px;
    }

    .background {
        margin-top: 30px;
    }

    .bi1 {
        top: 0%;
        left: 40%;
    }

    .bi2 {
        top: 80%;
        left: 40%;
    }

    .bi3 {
        top: 58%;
        left: 75%;
    }

    .bi4 {
        top: 21%;
        left: 3%;
    }

    .bi5 {
        top: 19%;
        left: 75%;
    }

    .bi6 {
        top: 60%;
        left: 5%;
    }

}

.page-title {
    width: 950px;
    max-width: 100%;
    margin: auto;
}
.element-space-40{
    padding-bottom: 40px !important;
}
.element-space-30{
    padding-bottom: 30px !important;
}
.page-title h6 {
    color: #C41D8E;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.81px;
    letter-spacing: 0.48153945803642273px;
    text-align: center;
    text-transform: uppercase;
    margin: 0px 0px 10px;
}

.page-title h2 {
    font-size: 40px;
    font-weight: 700;
    /* line-height: 60px; */
    line-height: 140%;
    text-align: center;
    color: #2A5DAA;
    margin: 0px;
}

.page-title h2 span {
    color: #C41D8E;
}

.blue-bg .page-title h2 span {
    color: #FF84D7;
}

@media screen and (max-width:580px) {
    .page-title h6 {
        font-size: 14px;
        line-height: 20px;
    }

    .page-title h2 {
        font-size: 24px;
        line-height: 33px;
    }
}

.top-offering-sec {
    display: flex;
    align-items: flex-end;
    border: 1px solid #CDCDCD;
    height: 320px;
    border-radius: 30px;
    position: relative;
    overflow: hidden;
}

.top-offering-txt {
    padding: 15px 15px 15px 60px;
    width: 35%;
}

.top-offering-sec .card-img-top {
    max-width: 32px;
    margin-bottom: 12px;
}

.offering-tag {
    background: linear-gradient(247.36deg, #C41D8E 18.77%, #D476B6 55.85%, #C41D8E 92.92%);
    font-size: 24px;
    font-weight: 700;
    line-height: 46.47px;
    letter-spacing: 1px;
    text-align: center;
    position: absolute;
    top: 37px;
    left: -64px;
    transform: rotate(-40deg);
    color: #fff;
    padding: 5px 60px;
}

.dp-main {
    width: 55%;
    margin-left: 10%;
    background: #1F3D6D;
    display: flex;
    position: relative;
    height: 100%;
}

.dp-circle {
    width: 321px;
    height: 321px;
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0px;
    border: 2px solid #FFFFFF;
    padding: 30px;
    position: absolute;
    left: -140px;
    top: 0;
    background: #203d6d;
    z-index: 1;
}

.dp-circle h2 {
    font-size: 34px;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: 0.48153945803642273px;
    text-align: center;
    color: #fff;
    margin: 0px;

}

.dp-circle p {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.48153945803642273px;
    text-align: center;
    color: #fff;
    margin: 10px 0px 0px;
}

.dp-content {
    padding: 20px 20px 10px 200px;
    width: 100%;
}

.dp-content ul {
    list-style: none;
}

.dp-content ul li {
    font-size: 16px;
    font-weight: 600;
    line-height: 37px;
    letter-spacing: 0.48153945803642273px;
    text-align: left;
    color: #fff;
}

.dp-content ul li img {
    margin-right: 8px;
}

.top-offering-txt p {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.48153945803642273px;
    text-align: left;
    color: #7C7C7C;
}

.top-offering-txt p span {
    font-size: 20px;
    color: #1F3D6D;

}

@media screen and (max-width:580px) {
    .top-offering-sec {
        flex-direction: column;
        height: auto;
    }

    .offering-tag {
        font-size: 14px;
        line-height: 27px;
        top: 17px;
        left: -60px;
        transform: rotate(-33deg);
    }

    .top-offering-txt,
    .dp-main {
        width: 100%;
    }

    .top-offering-txt {
        padding: 15px;
        text-align: center;
    }

    .top-offering-txt h2 {
        color: #1F3D6D;
        padding-top: 30px;
    }

    .top-offering-txt p {
        color: #1F3D6D;
        text-align: center;
        line-height: 24px;
    }

    .dp-content {
        padding: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }

    .dp-main {
        flex-direction: column;
        margin-top: 140px;
        margin-left: 0;
    }

    .dp-circle {
        position: relative;
        left: 0;
        margin: -140px auto 0px;
    }

    .dp-circle .circle-mob-txt {
        font-size: 15px;
        font-weight: 400;
        line-height: 17.6px;
        letter-spacing: 0.3026275634765625px;
        text-align: center;
        color: #DADADA;
    }

    .dp-circle .circle-mob-txt span {
        font-size: 18px;
        color: #fff;
    }

    .dp-content ul {
        margin-bottom: 20px;
    }

    .dp-content ul li {
        font-size: 13px;
        line-height: 18.7px;
    }

}

.featured-cards .card {
    text-align: center;
    box-shadow: 0px 4px 14.4px 0px #0000001F !important;
    border-radius: 13px;
    height: 100%;
    padding: 20px;
    margin-left: 0px !important;
    margin-left: 0px !important;
}

.featured-cards .card>img {
    width: 100%;
    height: 200px;
    object-fit: contain;
}

.featured-cards .card-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: 0.48153945803642273px;
    color: #1F3D6D;

}

.featured-cards .card-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.48153945803642273px;
    color: #7C7C7C;

}

@media screen and (max-width:580px) {
    .featured-cards .card>img {
        height: 110px;
    }

    .featured-cards .card-title {
        font-size: 13px;
        line-height: 120%;
    }

    .featured-cards .card-text {
        font-size: 12px;
        line-height: 120%;
    }

    .featured-cards .row {
        margin-left: -6px;
        margin-right: -6px;
    }

    .featured-cards .row>* {
        padding-left: 6px;
        padding-right: 6px;
    }

    .card-detail-txt h2 {
        font-size: 12px;
        padding: 0px 40px;
        line-height: 18px;
    }
}

/* .featured-cards .card-body{
    padding: 20px;
} */
.card-detail-txt {
    text-align: center;
}

.card-detail-txt h2 {
    font-size: 20px;
    font-weight: 600;
    line-height: 19.81px;
    letter-spacing: 0.48153945803642273px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #1F3D6D;
    margin-bottom: 20px;
}

.feature-image-card-details .card {
    text-align: left;
    box-shadow: unset !important;
    padding-right: 40px;
    margin-right: 0px !important;
}

.hiring-banner .top-offering-sec {
    background: linear-gradient(272.09deg, #DE42AC 12.1%, #8A1464 93.55%);
    padding: 30px 50px;
    flex-direction: column;
    align-items: start;
    height: auto;
    position: relative;
    min-height: 240px;
}

.hiring-banner .bg-img::before {
    content: '';
    position: absolute;
    top: 0px;
    left: 13%;
    background-image: url(../../Assets/images/hiring-image.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    width: 340px;
    height: 100%;
}

.hiring-banner .top-offering-sec h5 {
    font-size: 32px;
    font-weight: 700;
    line-height: 47px;
    text-align: left;
    color: #ffffff;
    position: relative;
    z-index: 1;
}

.hiring-banner .dp-content {
    padding: 0px;
    display: flex;
    justify-content: flex-end;
}

.hiring-banner p {
    font-size: 24px;
    font-weight: 400;
    line-height: 100%;
    text-align: left;
    color: #EAEAEA;
}

@media screen and (max-width:580px) {
    .hiring-banner .top-offering-sec {
        padding: 15px;
        min-height: unset;
        border-radius: 15px;
    }

    .hiring-banner .top-offering-sec h5 {
        font-size: 14px;
        line-height: 20px;
        margin: 0px 0px 20px 0px;
        width: 100%;
        text-align: center;
    }

    .hiring-banner .bg-img::before {
        left: 20px;
        background-image: url(../../Assets/images/hiring-image-mob.svg);
        background-repeat: no-repeat;
        background-size: 100%;
        width: 140px;
        height: 135px;
    }

    .hiring-banner .dp-content {
        margin-top: 0px;
    }

    .hiring-banner .dp-content ul {
        margin-bottom: 0px;
        padding-left: 45%;
    }

    .hiring-banner .dp-content ul li {
        font-size: 10.18px;
        line-height: 12px;
        display: flex;
        align-items: flex-start;
        margin-bottom: 2px;
    }

    .feature-image-card-details .card {
        padding-right: 20px;
        margin-right: 0px !important;
    }

    .feature-image-card-details .card .mb-4:last-child {
        margin-bottom: 0px !important;
    }

}

.horizontal-card .card img {
    max-width: 100px;
    height: auto;
    margin: auto;
}

.horizontal-card .card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media screen and (max-width:580px) {
    .horizontal-card .card img {
        height: 95px;
        object-fit: contain;
    }

}

.card-animation {
    transition: 0.5s all ease;
}

.card-animation:hover {
    transform: translateY(-10px);
    transition: 0.5s all ease;
}

.card-btn-col .site-btn {
    width: fit-content;
    margin: 0px auto;
}

.page-title p {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.48153945803642273px;
    text-align: center;
    color: #CBCBCB;
}

.client-logo-carousel .page-title p {
    color: #7C7C7C;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;

}

.client-logo-carousel .slick-list {
    overflow: hidden;
}

.team-sec .card {
    border-radius: 6px !important;
}

.team-sec .card .object-fit-cover {
    object-fit: cover;
    margin-bottom: 20px;
}

.team-sec .card .card-title {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.48153945803642273px;
    text-align: center;
    color: #7C7C7C;
}

.team-sec .card .card-text {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.48153945803642273px;
    text-align: center;
    color: #A0A0A0;
}


.logo-carousel .slick-track {
    display: flex;
}

.logo-outer {
    width: 290px !important;
    height: 90px;
    display: flex !important;
    box-shadow: 0px 4px 16px 0px #0000001F;
    margin: 20px 25px;
}

.logo-outer>img {
    max-width: 100%;
    height: 100%;
    margin: auto;
    object-fit: contain;
}

.logo-outer-award {
    width: 290px !important;
    height: 150px;
    display: flex !important;
    box-shadow: 0px 4px 16px 0px #0000001F;
    margin: 20px 25px;
}

.logo-outer-award>img {
    max-width: 90%;
    height: 90%;
    margin: auto;
}

@media screen and (max-width:580px) {
    .logo-outer {
        width: 130px !important;
        height: 75px;
        margin: 15px 10px;
    }

    .logo-outer-award {
        width: 130px !important;
        height: 85px;
        margin: 15px 10px;
    }

    .featured-cards.team-sec .card {
        width: 100% !important;
        padding: 12px;
    }

    .featured-cards.team-sec .card-body {
        padding: 0px;
    }
}

.testimonial-carousel {
    position: relative;
}

.testimonial-carousel .slick-slider {
    padding: 0px 100px;
}

.lead-tstmnl-slide {
    display: inline-flex !important;
    align-items: center;
}

.lead-tstmnl-img {
    width: 180px;
    margin-right: 20px;
}

.lead-tstmnl-img>img {
    width: 180px;
    height: 180px;
    border-radius: 100%;
    object-fit: cover;
}

.lead-tstmnl-text-cont {
    flex: 1;
}

.tstmnl-name {
    font-size: 24px;
    font-weight: 600;
    line-height: 26px;
    text-align: left;
    color: #515151;
    margin: 0px;
}

.lead-tstmnl-comment {
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    text-align: left;
    color: #515151;
    margin: 20px 0px;
}

.lead-tstmnl-text-cont h6 {
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    text-align: left;
    color: #C41D8E;
}

.testimonial-carousel .slick-arrow {
    width: 54px;
    height: 54px;
    border-radius: 100%;
    background: #fff;
    box-shadow: 0px 6.75px 6.75px 0px #BFD7FE;
    display: flex;
    position: absolute;
    top: calc(50% - 27px);
    left: -27px;
    z-index: 2;
}

.testimonial-carousel .slick-arrow img {
    max-width: 27px;
    margin: auto;
}

.testimonial-carousel .slick-next {
    left: auto;
    right: -27px;
}

.animated-box {
    position: relative;
    border-radius: 0%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease;
    padding: 30px;
}

.animated-box::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 32px;
    padding: 2px;
    background: linear-gradient(96.5deg, #12519B -0.79%, #D043AC 53.89%, #12519B 108.58%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    transition: 0.5s ease;
}

@media screen and (max-width:580px) {
    .testimonial-carousel .slick-slider {
        padding: 0px;
    }

    .lead-tstmnl-slide {
        flex-direction: column;
        align-items: flex-start;
    }

    .lead-tstmnl-img {
        width: 80px;
        margin: 0px 0px 20px 0px;
    }

    .lead-tstmnl-img>img {
        width: 80px;
        height: 80px;
    }

    .tstmnl-name,
    .lead-tstmnl-text-cont h6 {
        font-size: 14px;
        line-height: 120%;
    }

    .lead-tstmnl-comment {
        font-size: 12px;
        line-height: 120%;
    }

    .testimonial-carousel .slick-arrow {
        width: 24px;
        height: 24px;
        left: -7px;
    }

    .testimonial-carousel .slick-next {
        right: -7px;
        left: auto;
    }

    .testimonial-carousel .slick-arrow img {
        max-width: 12px;
    }
}


body .phone-bg {
    padding-right: 40% !important;
}

.phone-bg::before {
    content: '';
    position: absolute;
    top: 0px;
    right: 0px;
    background-image: url(../../Assets/images/phone.svg);
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100%;
    width: 340px;
    height: 100%;
}

.mn-app-logo img {
    max-width: 100%;
}

@media screen and (max-width:580px) {
    .phone-bg::before {
        width: 190px;
    }

    body .phone-bg {
        padding: 20px !important;
        margin-bottom: 0px;
    }

    .hiring-banner p {
        font-size: 12px;
    }

    .mn-app-logo {
        margin-top: 20px;
    }

    .mn-app-logo img {
        max-width: 25%;
    }

    .page-title .w-50 {
        width: 90% !important;
        font-size: 14px !important;
        line-height: 19px !important;
    }

    .blue-bg .w-50 {
        width: 90% !important;
    }
}

/* Frequently-section */
.faq-sec .accordion-item {
    border: none;
    border-bottom: 1px solid #eee;
    border-radius: 0px !important;
    background: transparent;
}

.faq-sec .accordion .accordion-item .accordion-button {
    background: transparent;
    font-size: 16px;
    font-weight: 500;
    line-height: 42.24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #515151;
    padding-bottom: 15px;
}

.faq-sec .accordion-button:not(.collapsed) {
    color: #515151;
    box-shadow: none;
}

.faq-sec .accordion-body {
    color: #515151;
    border: none;
    padding-top: 0px;
}

.faq-sec .accordion-button:focus {
    box-shadow: unset;
}

/* Icon adjustments */
.faq-sec .icon-plus-minus {
    width: 24px;
    height: 24px;
    border: 2px solid #2A5DAA;
    border-radius: 35%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #515151;
}

.faq-sec .accordion-button:not(.collapsed) .icon-plus-minus::before {
    content: "-";
    color: #2A5DAA;
}

.faq-sec .accordion-button.collapsed .icon-plus-minus::before {
    content: "+";
    color: #2A5DAA;

}

.faq-sec .icon-plus-minus::before {
    font-weight: bold;
}

.faq-sec .accordion-button::after {
    display: none !important;
}

.faq-sec .accordion-button-icon {
    width: 75%;
    text-align: end;
}


/* Responsive adjustments */
@media (max-width: 768px) {
    .faq-sec .accordion-button {
        font-size: 14px;
        line-height: 36px;
        padding: 10px 15px;
    }

    .faq-sec .icon-plus-minus {
        width: 20px;
        height: 20px;
        font-size: 14px;
    }

    .faq-sec .accordion-body {
        font-size: 14px;
    }

    .faq-sec .accordion .accordion-item .accordion-button {
        font-size: 11px;
        padding-bottom: 10px;
    }
}

@media (max-width: 480px) {
    .faq-sec .accordion-button {
        font-size: 12px;
        padding: 8px 12px;
    }

    .faq-sec .icon-plus-minus {
        width: 18px;
        height: 18px;
        font-size: 12px;
    }

    .faq-sec .accordion-body {
        font-size: 12px;
    }
}


@media screen and (min-width:1280px) and (max-width:1366px) {
    .home-banner {
        padding: 65px 0px;
    }

    .hm-bnr-title {
        font-size: 40px;
        line-height: 130%;
    }

    .page-title h2 {
        font-size: 36px;
        line-height: 130%;
    }

    .hm-bnr-title b {
        line-height: 175%;
    }

    .offering-tag {
        line-height: 180%;
        padding: 5px 60px;
        font-size: 18px;
    }

    body .phone-bg {
        padding-right: 30% !important;
    }

}