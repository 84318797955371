.auth-button {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  background: #fff;
  border: 1px solid #000;
  border-radius: 10px;
  box-sizing: border-box;
}

.auth-button p {
  color: #000;
  margin: 0px;
  font-weight: 600;
}

.auth-button img {
  width: 24px;
}

.auth-button-outer {
  margin-top: 26px;
}

.form-devider {
  position: relative;
}

.form-devider p {
  margin: 0px;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  background: #fff !important;
  padding: 10px;
  transform: translate(-33%, -52%);
  z-index: 9;
}

.other-auth-options.btns {
  padding: 10px 50px;
}

p.otp-description {
  margin: 28px 0px 0px;
  text-align: center;
}

span.close-login-model {
  position: absolute;
  background: #cbdefd;
  height: 26px;
  width: 26px;
  text-align: center;
  padding: 1px;
  border-radius: 50%;
}