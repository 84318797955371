.submission-modal-content {
  font-family: 'Montserrat';
}

.submission-modal-form .form-check {
  font-size: 14px;
  line-height: 1.5;
}

.submission-modal-form .form-check-input {
  width: 1.4em;
  height: 1.4em;
}

.submission-modal-form .form-check-label {
  opacity: 1 !important;
}

.submission-modal-form .form-check-input[disabled] {
  background-color: #adb5bd !important;
  border-color: #adb5bd !important;
}

.submission-modal-btn {
  width: 100%;
  padding: 15px;
  border: 0;
  color: #c41d8e;
  font-size: 16px;
  text-align: right;
  font-weight: 500;
}