.quiz-top-banner-sec {
  position: relative;
}

.quiz-top-banner-sec .banner-img {
  max-height: 450px;
}

.quiz-top-banner-sec .banner-title-img {
  position: absolute;
  max-width: 350px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* select option  */
.quiz-top-banner-sec .select-quiz-user {
  position: absolute;
  bottom: 0%;
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
  width: 300px;
  left: 50%;
}

.quiz-top-banner-sec .select-quiz-user::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  position: absolute;
  top: 22px;
  right: 62px;
}

.quiz-top-banner-sec .form-select.select-users-option {
  margin: 0 auto;
}

/*  */
.question-sec .quiz-question-slider {
  width: 70%;
  margin: 0 auto;
}

.question-sec .quiz-question-slider-inner {
  width: 70%;
  margin: 0 auto;
  padding: 25px 0px;
}

.question-sec .ques-slider-img {
  height: 300px;
  margin: 0 auto;
}

.question-sec .card.answers-img-type-card label {
  display: flex;
  justify-content: space-between;
}

.question-sec .card.answers-img-type-card label img.text-ans-img {
  width: 100%;
  max-width: 190px;
}

/* video question css*/
.question-video-player {
  height: 300px;
  margin: 0 auto;
  position: relative;
}

.question-video-player div {
  width: 100% !important;
  height: 100% !important;
  background-color: #000;
  opacity: 0.9;
}

.question-video-player img.player-icon {
  height: 80px;
  z-index: 1;
}

.question-sec .card.answers-type-card.answer-video-card label .answer-video-player {
  width: 100px;
  height: 100px;
  background-color: #000;
  opacity: 0.9;
}

.question-sec .card.answer-video-card label {
  display: flex;
  justify-content: space-between;
}

.answer-video-player div {
  width: 100% !important;
  height: 100% !important;
}

.question-sec label .answer-video-player img.answer-player-icon {
  width: 40px;
  height: 40px;
  z-index: 1;
}

/* video question css end*/
/*  common  */
.correct-content {
  padding: 5rem 0rem 4rem 0rem;
  text-align: center;
}

/* correct  */
.correct-ans {
  background-color: #ffeaf5;
}

.correct-ans .correct-content p {
  font-weight: 600;
  color: #c41d8e;
}

.correct-content img {
  width: 18px;
  margin-right: 10px;
}

.quiz-button-pnk-comn.btn:hover {
  color: #fff !important;
}

.quiz-button-out-link-pnk-comn.btn:hover {
  color: #c41d8e !important;
}

/* incorrect  */
.incorrect-ans {
  background-color: #ededed;
}

.incorrect-ans .correct-content p {
  font-weight: 600;
  color: #505050;
}

/* answer hint  */
.answer-hint-wrapper {
  background-color: #ffeadb;
}

.answer-hint-wrapper .correct-content p {
  font-weight: 600;
  color: #ff7009;
}

.answer-hint-wrapper .correct-content .hint-answer-content p {
  color: #505050;
}

/* skip modal  */
.skip-modal-content {
  text-align: center;
  padding: 1rem 3rem 3rem 3rem;
}

.skip-modal-content h5 {
  font-weight: 600;
  line-height: 28px;
  padding: 20px 46px;
}

/* quiz-time-out-modal-content  */
.quiz-time-out-modal-content {
  text-align: center;
  padding: 1rem 3rem 3rem 3rem;
}

.quiz-time-out-modal-content h5 {
  font-weight: 600;
  line-height: 28px;
  padding: 20px 65px;
}

/*  */
.question-header {
  /* background-color: #f5f5f5; */
  padding: 15px 10px;
}

.question-header .question-time {
  text-align: end;
}

.question-header .question-time img {
  width: 30px;
}

.question-header .question-time span {
  margin-left: 10px;
  font-weight: 600;
}

.question-header .question {
  display: flex;
  align-items: baseline;
}

.question-header .question h5 {
  font-weight: 600;
  padding: 16px 0px;
  padding-right: 20px;
}

.question-header .question h5.sr-list {
  padding: 0px;
  padding-right: 6px;
}

.question-attempt-count p {
  font-weight: 400;
  text-align: end;
  padding-bottom: 6px;
}

.question-attempt-count p span {
  font-weight: 600;
}

.answers-type-outer p {
  margin-bottom: 26px;
  margin-top: 20px;
}

.answers-type-card.card {
  border: none !important;
  background: #ffffff;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15);
  margin-bottom: 16px !important;
}

.answers-type-card.card .btn {
  padding: 12px 20px;
}

.answers-type-card.card .label-btn {
  padding: 12px 20px;
}

.answers-type-card.card .btn-check:focus,
.answers-type-card.card .btn {
  box-shadow: none;
}

.answers-type-card.card .btn-check:checked+.btn {
  color: #fff;
  background-color: #c41d8e;
  border-color: #c41d8e;
}

.answers-type-card.card .btn-check:checked+.label-btn.selected {
  color: #fff;
  background-color: #c41d8e;
  border-color: #c41d8e;
}

.answers-type-card label {
  font-weight: 600;
  text-align: start;
}

.answers-type-content .response-sec {
  display: flex;
  justify-content: end;
}

.answers-type-content .response-sec a.clear-response,
.answers-type-content .response-sec a.make-later {
  padding-bottom: 20px;
  color: #c41d8e;
  text-decoration: none;
  padding-left: 16px;
}

/* question images  */
/* slider css  */
.question-sec .slick-next,
.question-sec .slick-prev {
  height: unset;
  width: unset;
}

.question-sec .slick-prev:before,
.question-sec .slick-next::before {
  display: none;
}

.question-sec .slick-prev {
  left: -80px;
}

.question-sec .slick-next {
  right: -80px;
}

.question-sec .arrow-icon-img {
  padding: 20px 0px;
  height: 150px;
  width: 60px;
}

/* slider css end  */
/* desk */
.component-question .question-sec {
  /* display: flex; */
  position: relative;
  /* height: 520px; */
}

/* .component-question .question-sec .question-header {
  width: 50%;
} */

.component-question .question-sec .question-header .container {
  height: 100%;
  overflow: auto;
}

/* .component-question .question-sec .question-header::before {
  content: " ";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 2px;
  height: 90%;
  margin: auto 0px;
  background-color: #d9d9d9;
  transform: translate(-50%, -50%);
} */

.component-question .question-sec .question-answers-content {
  /* width: 50%; */
  padding: 0px 10px;
}

.component-question .question-sec .question-answers-content .container {
  height: -webkit-fill-available;
  overflow: auto;
}

.start-quiz-content-sec .quiz-hint-sec {
  position: absolute;
  right: 0;
  transform: translate(-50%, 0%);
  z-index: 11;
}

.component-question .question-sec .question-answers-content .container::-webkit-scrollbar,
.component-question .question-sec .question-header .container::-webkit-scrollbar {
  width: 6px;
}

.component-question .question-sec .question-answers-content .container::-webkit-scrollbar-thumb,
.component-question .question-sec .question-header .container::-webkit-scrollbar-thumb {
  background-color: #d9d9d9 !important;
}

.component-question .question-sec .question-answers-content .container::-webkit-scrollbar-track,
.component-question .question-sec .question-header .container::-webkit-scrollbar-track {
  background-color: transparent !important;
}

.hint-answer-content .answers-type-card.card {
  width: 52%;
  margin-left: auto;
  margin-right: auto;
}

.card.answers-type-card div.incorrect div {
  color: #B20000;
}

.card.answers-type-card div.correct div {
  color: #08B60B;
}

.card.answers-type-card.selected-incorrect {
  background: #FFECEC;
  border: 1px solid #B20000;
  border-radius: 0.25rem;

}

.card.answers-type-card .selected-correct {
  background: #08B60B;
  border: 1px solid #08B60B;
  border-radius: 0.25rem 0.25rem 0 0;
  color: #fff;
}

@media screen and (max-width: 1360px) {
  .question-sec .ques-slider-img {
    height: 240px;
  }

  /*  */
  .question-video-player {
    height: 240px;
  }
}

@media screen and (max-width: 1260px) {
  .question-sec .arrow-icon-img {
    height: 120px;
    width: 46px;
  }

  .question-sec .slick-next {
    right: -60px;
  }

  .question-sec .slick-prev {
    left: -60px;
  }

  .question-sec .ques-slider-img {
    height: 200px;
  }

  /*  */
  .question-video-player {
    height: 200px;
  }

  .question-video-player img.player-icon {
    height: 50px;
  }
}

@media screen and (max-width: 1024px) {
  .question-sec .slick-next {
    right: -60px;
  }

  .question-sec .slick-prev {
    left: -60px;
  }

  .question-sec .ques-slider-img {
    height: 160px;
  }

  /*  */
  .question-video-player {
    height: 160px;
  }

  .question-video-player img.player-icon {
    height: 50px;
  }
}

/*  */
@media screen and (max-width: 768px) {
  .quiz-top-banner-sec .banner-title-img {
    max-width: 280px;
  }

  .start-quiz-content-sec .quiz-hint-sec {
    -webkit-transform: unset;
    position: unset;
  }

  /*  */
  .component-question .question-sec {
    display: unset;
    height: auto;
  }

  .component-question .question-sec .question-header {
    background-color: #f5f5f5;
    padding: 15px 0px;
    width: 100%;
  }

  .component-question .question-sec .question-header::before {
    display: none;
  }

  .component-question .question-sec .question-answers-content {
    width: 100%;
  }

  .response-sec a.make-later {
    display: none;
  }

  .question-sec .quiz-question-slider {
    width: 100%;
  }

  .question-sec .ques-slider-img {
    height: 200px;
    width: 100%;
  }

  .question-sec .card.answers-img-type-card label {
    display: flex;
    justify-content: space-between;
  }

  .question-sec .card.answers-img-type-card label img.text-ans-img {
    width: 100%;
    max-width: 180px;
  }

  /* video question css*/
  .question-video-player {
    height: 200px;
    position: relative;
  }

  .question-sec .card.answers-type-card.answer-video-card label .answer-video-player {
    width: 100px;
    height: 100px;
    background-color: #000;
    opacity: 0.9;
  }

  /* video question css end*/
  /* common */
  .correct-content {
    padding: 2.5rem 0rem 3rem 0rem;
  }

  /*  */
  .hint-answer-content .answers-type-card.card {
    width: 100%;
    margin-left: unset;
    margin-right: unset;
  }
}

@media screen and (max-width: 620px) {
  .quiz-content-outer {
    margin-top: 4rem;
  }

  .question-header .question-time img {
    width: 24px;
  }

  /* slider */
  .question-sec .arrow-icon-img {
    height: 100px;
    width: 40px;
  }

  .question-sec .slick-prev {
    left: -50px;
  }

  .question-sec .slick-next {
    right: -50px;
  }

  .question-sec .card.answers-img-type-card label img.text-ans-img {
    width: 100%;
    max-width: 150px;
  }

  .question-video-player img.player-icon {
    height: 50px;
  }

  .question-sec .card.answers-type-card.answer-video-card label .answer-video-player {
    width: 80px;
    height: 80px;
  }

  .question-sec label .answer-video-player img.answer-player-icon {
    width: 30px;
    height: 30px;
  }

  /* skip modal */
  .skip-modal-content h5 {
    padding: 20px 38px;
  }

  .skip-modal-content {
    padding: 1rem 2rem 2rem 2rem;
  }

  /*time out  modal */
  .quiz-time-out-modal-content {
    text-align: center;
    padding: 1rem 2rem 2rem 2rem;
  }

  /* select option */
  .quiz-top-banner-sec .form-select.select-users-option {
    padding: 10px 12px !important;
    font-size: 14px;
    width: 80%;
  }

  .quiz-top-banner-sec .select-quiz-user::after {
    top: 50%;
  }
}

@media screen and (max-width: 476px) {
  .start-quiz-content-sec .quiz-detail-header .quiz-detail-sec {
    padding-top: 4rem;
  }

  .start-quiz-content-sec .quiz-progress-bar {
    width: 100%;
  }

  .start-quiz-content-sec .quiz-progress-bar span {
    min-width: 110px;
  }

  .start-quiz-content-sec .quiz-hint-sec .hint-icon {
    width: 24px;
  }

  .start-quiz-content-sec .hint-text-sec {
    right: 14px;
    top: 38px;
  }

  .question-header .question h5 {
    padding-right: 6px;
    line-height: 22px;
    font-size: 16px;
  }

  .question-header .question-time img {
    width: 20px;
  }

  .question-sec .ques-slider-img {
    height: auto;
    min-height: 120px;
    max-height: 300px;
    /* width: 100%; */
  }

  .question-video-player {
    height: 170px;
    min-height: 120px;
    max-height: 300px;
  }

  .question-sec .card.answers-type-card.answer-video-card label .answer-video-player {
    width: 60px;
    height: 60px;
  }

  .question-sec label .answer-video-player img.answer-player-icon {
    width: 28px;
    height: 28px;
  }

  .question-video-player img.player-icon {
    height: 40px;
  }

  .question-sec .card.answers-img-type-card label img.text-ans-img {
    width: 100%;
    max-width: 120px;
  }

  .question-sec .arrow-icon-img {
    height: 100px;
    width: 40px;
  }

  .question-sec .slick-next {
    right: -50px;
  }

  .question-sec .slick-prev {
    left: -50px;
  }

  /* skip-modal */
  .skip-modal-content {
    padding: 1rem 1rem 2rem 1rem;
  }

  /*quiz time out modal */
  .quiz-time-out-modal-content {
    padding: 1rem 1rem 2rem 1rem;
  }

  /* select option */
  .quiz-top-banner-sec .form-select.select-users-option {
    padding: 10px 12px !important;
    font-size: 14px;
    width: 75%;
  }

  .quiz-top-banner-sec .select-quiz-user::after {
    top: 50%;
  }
}

@media screen and (max-width: 420px) {

  /* video player */
  .question-video-player {
    height: 150px;
  }

  /* skip-modal */
  .skip-modal-content h5 {
    line-height: 24px;
    padding: 16px 0px;
  }

  .skip-modal-content {
    padding: 0rem 0rem 1rem 0rem;
  }

  /*quiz time out modal */
  .quiz-time-out-modal-content {
    padding: 0rem 0rem 1rem 0rem;
  }

  .quiz-time-out-modal-content h5 {
    line-height: 24px;
    padding: 12px 12px;
  }

  .quiz-top-banner-sec .form-select.select-users-option {
    padding: 8px 8px !important;
    font-size: 14px;
    width: 75%;
  }

  .quiz-top-banner-sec .select-quiz-user::after {
    top: 16px;
  }
}

@media screen and (max-width: 320px) {
  .question-video-player {
    height: 100px;
  }
}