.context-narchome {
  float: left;
  width: 100%;
}

.context-narchome .wrapper {
  float: left;
  width: 100%;
  padding-left: calc((100% - 1140px) / 2);
  padding-right: calc((100% - 1140px) / 2);
}

@media (max-width: 1024px) {
  .context-narchome .wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 768px) {
  .context-narchome .wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.context-narchome .wrapper .narc20-21banner {
  width: 100%;
  float: left;
  position: relative;
  overflow: unset;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

@media (max-width: 1122px) {
  .context-narchome .wrapper .narc20-21banner {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 480px) {
  .context-narchome .wrapper .narc20-21banner {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.context-narchome .wrapper .narc20-21banner .content {
  float: left;
  width: 45%;
}

@media (max-width: 1024px) {
  .context-narchome .wrapper .narc20-21banner .content {
    width: 45%;
  }
}

@media (max-width: 480px) {
  .context-narchome .wrapper .narc20-21banner .content {
    width: 100%;
  }
}

.context-narchome .wrapper .narc20-21banner .content .graphic {
  float: left;
  width: 100%;
  height: 91px;
}

@media (max-width: 480px) {
  .context-narchome .wrapper .narc20-21banner .content .graphic {
    width: 50%;
    height: 31px;
  }
}

.context-narchome .wrapper .narc20-21banner .content .graphic img {
  float: left;
  width: 100%;
}

.context-narchome .wrapper .narc20-21banner .content .block {
  float: left;
  width: 100%;
  margin-top: 50px;
  padding-left: 100px;
  padding-bottom: 60px;
}

@media (max-width: 1024px) {
  .context-narchome .wrapper .narc20-21banner .content .block {
    margin-top: 50px;
  }
}

@media (max-width: 768px) {
  .context-narchome .wrapper .narc20-21banner .content .block {
    margin-top: 0;
  }
}

@media (max-width: 480px) {
  .context-narchome .wrapper .narc20-21banner .content .block {
    padding: 20px;
    margin-top: 30px;
    text-align: center;
  }
}

.context-narchome .wrapper .narc20-21banner .content .block h1 {
  float: left;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  color: #1c2b3e;
}

@media (max-width: 768px) {
  .context-narchome .wrapper .narc20-21banner .content .block h1 {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .context-narchome .wrapper .narc20-21banner .content .block h1 {
    text-align: center;
  }
}

.context-narchome .wrapper .narc20-21banner .content .block .text {
  float: left;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #1c2b3e;
}

@media (max-width: 480px) {
  .context-narchome .wrapper .narc20-21banner .content .block .text {
    text-align: center;
  }
}

.context-narchome .wrapper .narc20-21banner .content .block button {
  float: left;
  width: 180px;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  color: #fff;
  border: solid 1px #c41d8e;
  background-color: #c41d8e;
  border-radius: 25px;
  line-height: 40px;
  margin-top: 20px;
  cursor: pointer;
  font-weight: 500;
  cursor: pointer;
}

.context-narchome .wrapper .narc20-21banner .content .block button:hover {
  background-color: #a90b76;
  color: #fff;
}

@media (max-width: 480px) {
  .context-narchome .wrapper .narc20-21banner .content .block button {
    width: 150px;
    float: none;
  }
}

.context-narchome .wrapper .narc20-21banner .content .block button.fill {
  color: #c41d8e;
  border: solid 1px #c41d8e;
  background-color: transparent;
  margin-left: 10px;
}

.context-narchome .wrapper .narc20-21banner .content .block button.fill:hover {
  color: #fff;
  border: solid 1px #a90b76;
  background-color: #a90b76;
}

.context-narchome .wrapper .narc20-21banner .imgBlock {
  float: right;
  width: 50%;
  margin-top: 20px;
  margin-bottom: 0px;
}

.context-narchome .wrapper .narc20-21banner .imgBlock img {
  float: left;
  width: 100%;
  height: 397px;
}

@media (max-width: 1112px) {
  .context-narchome .wrapper .narc20-21banner .imgBlock {
    margin-top: -3px;
  }
}

@media (max-width: 480px) {
  .context-narchome .wrapper .narc20-21banner .imgBlock {
    width: 100%;
    padding: 20px;
    margin-top: 0;
    margin-bottom: 0px;
  }

  .context-narchome .wrapper .narc20-21banner .imgBlock img {
    height: 202px;
  }
}

.context-narchome .wrapper .narc20-21banner .narc-navbar {
  position: absolute;
  width: 100%;
  padding: 0 10%;
  bottom: -5px;
  left: 0;
  text-align: center;
  z-index: 1;
}

@media (max-width: 1112px) {
  .context-narchome .wrapper .narc20-21banner .narc-navbar {
    width: calc(100% - 83px);
    bottom: -20%;
    padding: 0;
    left: 0;
    transform: translate(6%, -27px);
  }
}

@media (max-width: 480px) {
  .context-narchome .wrapper .narc20-21banner .narc-navbar {
    width: calc(100% - 35px);
    bottom: -3%;
    transform: unset;
    padding: 0;
    left: 20px;
  }
}

.context-narchome .wrapper .narc20-21banner .narc-navbar .menu-slot {
  width: 100%;
  float: left;
  background-color: #285caa;
  padding: 11px 38px;
  opacity: 0.9;
  border-radius: 9.5px;
  text-align: center;
  box-shadow: -8px 11px 2px 0 #6c63ff;
}

@media (max-width: 480px) {
  .context-narchome .wrapper .narc20-21banner .narc-navbar .menu-slot {
    width: 100%;
    padding: 11px 6px;
    text-align: center;
  }
}

.context-narchome .wrapper .narc20-21banner .narc-navbar .menu-slot ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.context-narchome .wrapper .narc20-21banner .narc-navbar .menu-slot ul .menubar {
  float: none;
  display: inline-block;
  padding: 10px 40px;
  border-radius: 19px;
  background-color: #fff;
  margin-right: 51px;
  color: #c00096;
  font-family: Montserrat;
  font-size: 14px;
  line-height: 1.2;
  font-weight: 500;
}

.context-narchome .wrapper .narc20-21banner .narc-navbar .menu-slot ul .menubar a {
  color: #c00096;
}

@media (max-width: 1112px) {
  .context-narchome .wrapper .narc20-21banner .narc-navbar .menu-slot ul .menubar {
    padding: 11px 33px;
    margin-right: 20px;
  }
}

@media (max-width: 768px) {
  .context-narchome .wrapper .narc20-21banner .narc-navbar .menu-slot ul .menubar {
    padding: 11px 25px;
    margin-right: 20px;
  }
}

@media (max-width: 480px) {
  .context-narchome .wrapper .narc20-21banner .narc-navbar .menu-slot ul .menubar {
    float: none;
    display: inline-block;
    padding: 7px 7px;
    margin-right: 6px;
    font-size: 10px;
  }
}

@media (max-width: 320px) {
  .context-narchome .wrapper .narc20-21banner .narc-navbar .menu-slot ul .menubar {
    padding: 7px 5px;
    margin-right: 6px;
    font-size: 9px;
  }
}

.context-narchome .wrapper .narc20-21banner .narc-navbar .menu-slot ul .menubar:last-child {
  margin-right: 0;
}

.context-narchome .participate-details {
  width: 100%;
  float: left;
  background-position: center bottom;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #fff;
  margin-bottom: 0;
  padding-left: calc((100% - 1140px) / 2);
  padding-right: calc((100% - 1140px) / 2);
}

@media (max-width: 1112px) {
  .context-narchome .participate-details {
    padding-left: 40px;
    padding-right: 40px;
    background-size: cover;
  }
}

@media (max-width: 480px) {
  .context-narchome .participate-details {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.context-narchome .participate-details .participate-info {
  width: 100%;
  float: left;
  margin-top: 50px;
}

@media (max-width: 1112px) {
  .context-narchome .participate-details .participate-info {
    margin-top: 30px;
  }
}

@media (max-width: 480px) {
  .context-narchome .participate-details .participate-info {
    margin-top: 50px;
  }
}

.context-narchome .participate-details .participate-info .head {
  width: 100%;
  float: left;
  margin-top: 56px;
  position: relative;
}

@media (max-width: 480px) {
  .context-narchome .participate-details .participate-info .head {
    margin-top: 12px;
  }
}

.context-narchome .participate-details .participate-info .head::before {
  content: "";
  position: absolute;
  width: 19%;
  height: 0;
  bottom: -15px;
  left: 42%;
  border-bottom: 3px solid #285caa;
  border-top: 3px solid #285caa;
  border-radius: 25px;
  background-color: #285caa;
}

@media (max-width: 480px) {
  .context-narchome .participate-details .participate-info .head::before {
    width: 30%;
    bottom: -15px;
    left: 37%;
  }
}

.context-narchome .participate-details .participate-info .head .title {
  width: 100%;
  float: left;
  font-family: Montserrat;
  font-size: 30px;
  line-height: 1.2;
  text-align: center;
  color: #393939;
  font-weight: bold;
}

@media (max-width: 1112px) {
  .context-narchome .participate-details .participate-info .head .title {
    font-size: 28px;
  }
}

@media (max-width: 480px) {
  .context-narchome .participate-details .participate-info .head .title {
    font-size: 16px;
  }
}

.context-narchome .participate-details .participate-info .registration-process {
  width: 100%;
  float: left;
  margin-top: 80px;
}

@media (max-width: 480px) {
  .context-narchome .participate-details .participate-info .registration-process {
    margin-top: 40px;
  }
}

.context-narchome .participate-details .participate-info .registration-process .registration-steps {
  width: 470px;
  float: left;
  background-color: #fff;
  margin: 0 262.8px 14.7px 0;
  padding: 20px 11px;
  border-radius: 30px;
  position: relative;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

@media (max-width: 1112px) {
  .context-narchome .participate-details .participate-info .registration-process .registration-steps {
    padding: 15px 9px;
  }
}

@media (max-width: 1024px) {
  .context-narchome .participate-details .participate-info .registration-process .registration-steps {
    width: 68%;
  }
}

@media (max-width: 480px) {
  .context-narchome .participate-details .participate-info .registration-process .registration-steps {
    width: calc(100% - 75px);
    padding: 5px 9px;
    margin: 0 0px 14.7px 0;
  }
}

.context-narchome .participate-details .participate-info .registration-process .registration-steps.age {
  margin: 0 262.8px 14.7px 135px;
}

@media (max-width: 1112px) {
  .context-narchome .participate-details .participate-info .registration-process .registration-steps.age {
    margin: 0 0px 14px 61px;
  }
}

@media (max-width: 480px) {
  .context-narchome .participate-details .participate-info .registration-process .registration-steps.age {
    margin: 0 0px 14px 20px;
  }
}

.context-narchome .participate-details .participate-info .registration-process .registration-steps.age::after {
  content: "";
  height: 84px;
  width: 66px;
  border-bottom: 1px solid #285caa;
  border-left: 1px solid #285caa;
  border-radius: 0px 0 0 22px;
  position: absolute;
  left: -68px;
  bottom: 30px;
}

@media (max-width: 1112px) {
  .context-narchome .participate-details .participate-info .registration-process .registration-steps.age::after {
    height: 75px;
    width: 29px;
    left: -33px;
  }
}

@media (max-width: 1024px) {
  .context-narchome .participate-details .participate-info .registration-process .registration-steps.age::after {
    height: 70px;
  }
}

@media (max-width: 480px) {
  .context-narchome .participate-details .participate-info .registration-process .registration-steps.age::after {
    height: 41px;
    width: 12px;
    left: -13px;
    bottom: 37px;
  }
}

@media (max-width: 320px) {
  .context-narchome .participate-details .participate-info .registration-process .registration-steps.age::after {
    height: 56px;
  }
}

.context-narchome .participate-details .participate-info .registration-process .registration-steps.activity {
  margin: 0 262.8px 14.7px 275px;
}

@media (max-width: 1112px) {
  .context-narchome .participate-details .participate-info .registration-process .registration-steps.activity {
    margin: 0 0px 14px 115px;
  }
}

@media (max-width: 480px) {
  .context-narchome .participate-details .participate-info .registration-process .registration-steps.activity {
    margin: 0 0px 14.7px 37px;
  }
}

.context-narchome .participate-details .participate-info .registration-process .registration-steps.activity::after {
  content: "";
  height: 80px;
  width: 66px;
  border-bottom: 1px solid #285caa;
  border-left: 1px solid #285caa;
  border-radius: 0px 0 0 22px;
  position: absolute;
  left: -68px;
  bottom: 30px;
}

@media (max-width: 1112px) {
  .context-narchome .participate-details .participate-info .registration-process .registration-steps.activity::after {
    height: 55px;
    width: 21px;
    left: -23px;
  }
}

@media (max-width: 1024px) {
  .context-narchome .participate-details .participate-info .registration-process .registration-steps.activity::after {
    height: 70px;
  }
}

@media (max-width: 480px) {
  .context-narchome .participate-details .participate-info .registration-process .registration-steps.activity::after {
    height: 35px;
    width: 11px;
    left: -10px;
    bottom: 39px;
  }
}

@media (max-width: 360px) {
  .context-narchome .participate-details .participate-info .registration-process .registration-steps.activity::after {
    height: 41px;
    width: 11px;
    left: -10px;
    bottom: 39px;
  }
}

.context-narchome .participate-details .participate-info .registration-process .registration-steps.instructions {
  margin: 0 262.8px 14.7px 421px;
}

@media (max-width: 1112px) {
  .context-narchome .participate-details .participate-info .registration-process .registration-steps.instructions {
    margin: 0 0px 14px 161px;
  }
}

@media (max-width: 480px) {
  .context-narchome .participate-details .participate-info .registration-process .registration-steps.instructions {
    margin: 0 0px 14.7px 52px;
  }
}

.context-narchome .participate-details .participate-info .registration-process .registration-steps.instructions::after {
  content: "";
  height: 81px;
  width: 66px;
  border-bottom: 1px solid #285caa;
  border-left: 1px solid #285caa;
  border-radius: 0px 0 0 22px;
  position: absolute;
  left: -68px;
  bottom: 30px;
}

@media (max-width: 1112px) {
  .context-narchome .participate-details .participate-info .registration-process .registration-steps.instructions::after {
    height: 75px;
    width: 23px;
    left: -24px;
  }
}

@media (max-width: 1024px) {
  .context-narchome .participate-details .participate-info .registration-process .registration-steps.instructions::after {
    height: 70px;
  }
}

@media (max-width: 480px) {
  .context-narchome .participate-details .participate-info .registration-process .registration-steps.instructions::after {
    height: 37px;
    width: 10px;
    left: -10px;
    bottom: 44px;
  }
}

@media (max-width: 360px) {
  .context-narchome .participate-details .participate-info .registration-process .registration-steps.instructions::after {
    height: 49px;
    width: 10px;
    left: -10px;
    bottom: 44px;
  }
}

@media (max-width: 320px) {
  .context-narchome .participate-details .participate-info .registration-process .registration-steps.instructions::after {
    height: 54px;
  }
}

.context-narchome .participate-details .participate-info .registration-process .registration-steps.submission {
  margin: 0 262.8px 14.7px 586px;
}

@media (max-width: 1112px) {
  .context-narchome .participate-details .participate-info .registration-process .registration-steps.submission {
    margin: 0 0px 14px 215px;
  }
}

@media (max-width: 480px) {
  .context-narchome .participate-details .participate-info .registration-process .registration-steps.submission {
    margin: 0 0px 14.7px 72px;
  }
}

.context-narchome .participate-details .participate-info .registration-process .registration-steps.submission::after {
  content: "";
  height: 81px;
  width: 66px;
  border-bottom: 1px solid #285caa;
  border-left: 1px solid #285caa;
  border-radius: 0px 0 0 22px;
  position: absolute;
  left: -68px;
  bottom: 30px;
}

@media (max-width: 1112px) {
  .context-narchome .participate-details .participate-info .registration-process .registration-steps.submission::after {
    height: 54px;
    width: 22px;
    left: -24px;
  }
}

@media (max-width: 1024px) {
  .context-narchome .participate-details .participate-info .registration-process .registration-steps.submission::after {
    height: 70px;
  }
}

@media (max-width: 480px) {
  .context-narchome .participate-details .participate-info .registration-process .registration-steps.submission::after {
    height: 41px;
    width: 12px;
    left: -12px;
    bottom: 33px;
  }
}

@media (max-width: 360px) {
  .context-narchome .participate-details .participate-info .registration-process .registration-steps.submission::after {
    height: 47px;
    width: 12px;
    left: -12px;
    bottom: 33px;
  }
}

.context-narchome .participate-details .participate-info .registration-process .registration-steps .process-box {
  width: 100%;
  float: left;
}

.context-narchome .participate-details .participate-info .registration-process .registration-steps .process-box .img-plot {
  float: left;
  margin-right: 12px;
  width: 56px;
  height: 56px;
  margin: 0 8px 0 0;
  padding: 2px;
  background-color: #f5f9ff;
  border-radius: 50%;
  text-align: center;
}

@media (max-width: 480px) {
  .context-narchome .participate-details .participate-info .registration-process .registration-steps .process-box .img-plot {
    width: 40px;
    height: 40px;
    margin-right: 9px;
    margin: 2px 8px 0 0;
  }
}

.context-narchome .participate-details .participate-info .registration-process .registration-steps .process-box .img-plot img {
  display: inline-block;
  width: 100%;
  margin-top: 0px;
  float: none;
}

.context-narchome .participate-details .participate-info .registration-process .registration-steps .process-box .regst-details {
  width: calc(100% - 89px);
  float: left;
}

@media (max-width: 480px) {
  .context-narchome .participate-details .participate-info .registration-process .registration-steps .process-box .regst-details {
    width: calc(100% - 62px);
  }
}

.context-narchome .participate-details .participate-info .registration-process .registration-steps .process-box .regst-details .rest-title {
  width: 100%;
  float: left;
  font-family: Montserrat;
  font-size: 15px;
  line-height: 1.2;
  text-align: left;
  color: #285caa;
  font-weight: 500;
}

@media (max-width: 480px) {
  .context-narchome .participate-details .participate-info .registration-process .registration-steps .process-box .regst-details .rest-title {
    font-size: 12px;
  }
}

.context-narchome .participate-details .participate-info .registration-process .registration-steps .process-box .regst-details .regst-subtitle {
  width: 100%;
  float: left;
  font-family: Montserrat;
  font-size: 15px;
  line-height: 19px;
  text-align: left;
  color: #393939;
  font-weight: 500;
  margin-top: 3px;
}

@media (max-width: 480px) {
  .context-narchome .participate-details .participate-info .registration-process .registration-steps .process-box .regst-details .regst-subtitle {
    font-size: 11px;
    line-height: 15px;
  }
}

.context-narchome .participate-details .participate-info .registration-process .registration-steps .path {
  width: 36.3px;
  height: 44.1px;
  margin: 58.5px 0 29.4px 29.3px;
  border: solid 0.7px #43386b;
}

.context-narchome .activities {
  float: left;
  width: 100%;
  margin-top: 60px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #fff;
  padding-left: calc((100% - 1140px) / 2);
  padding-right: calc((100% - 1140px) / 2);
}

.context-narchome .activities .head {
  float: left;
  width: 100%;
  text-align: center;
  position: relative;
}

.context-narchome .activities .head::before {
  content: "";
  position: absolute;
  width: 20%;
  height: 5px;
  background-color: #285caa;
  border-radius: 25px;
  left: 40%;
  bottom: -15px;
}

@media (max-width: 480px) {
  .context-narchome .activities .head::before {
    width: 45%;
    left: 28%;
  }
}

.context-narchome .activities .head .title {
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  font-weight: bold;
  color: #393939;
  margin-bottom: 12px;
}

@media (max-width: 768px) {
  .context-narchome .activities .head .title {
    font-size: 24px;
  }
}

@media (max-width: 480px) {
  .context-narchome .activities .head .title {
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 8px;
  }
}

.context-narchome .activities .head .text {
  float: left;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #393939;
}

.context-narchome .activities .paticipent {
  float: left;
  width: 100%;
  text-align: center;
  position: relative;
  margin-top: 70px;
}

@media (max-width: 480px) {
  .context-narchome .activities .paticipent {
    margin-top: 45px;
    text-align: center;
  }
}

.context-narchome .activities .paticipent .title {
  float: left;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #393939;
  margin-top: 10px;
  white-space: pre-line;
}

@media (max-width: 480px) {
  .context-narchome .activities .paticipent .title {
    width: 100%;
    font-size: 16px;
    line-height: 20px;
    position: relative;
    bottom: -39px;
    margin-top: 0;
  }
}

@media (max-width: 320px) {
  .context-narchome .activities .paticipent .title {
    font-size: 14px;
    line-height: 20px;
  }
}

.context-narchome .activities .paticipent .ui.search.dropdown {
  float: right;
  width: 115px;
  min-width: auto;
  border-color: #c41d8e;
  border-radius: 30px;
  background-color: transparent;
}

@media (max-width: 738px) {
  .context-narchome .activities .paticipent .ui.search.dropdown {
    float: unset;
    margin-top: 15px;
    width: 115px;
  }
}

@media (max-width: 480px) {
  .context-narchome .activities .paticipent .ui.search.dropdown {
    float: none;
    display: inline-block;
    margin-top: 15px;
    width: 115px;
    position: relative;
    top: -73px;
  }
}

.context-narchome .activities .paticipent .ui.search.selection.dropdown>input.search {
  display: none;
  cursor: default;
}

.context-narchome .activities .paticipent .ui.search.dropdown>.text {
  color: #c41d8e;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
}

.context-narchome .activities .paticipent .ui.dropdown .menu>.item {
  color: #c41d8e;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
}

.context-narchome .activities .activitiesslide {
  float: left;
  width: 100%;
  margin-top: 50px;
}

.context-narchome .activities .activitiesslide .ui.secondary.pointing.menu {
  box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
  border: solid 0.5px #efefef;
  padding-left: 30px;
  padding-right: 30px;
  background-color: #fff;
  padding-top: 15px;
}

@media (max-width: 1024px) {
  .context-narchome .activities .activitiesslide .ui.secondary.pointing.menu {
    overflow-y: hidden;
    overflow-x: scroll;
  }

  .context-narchome .activities .activitiesslide .ui.secondary.pointing.menu::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  .context-narchome .activities .activitiesslide .ui.secondary.pointing.menu::-webkit-scrollbar-track {
    background: #fff;
  }

  .context-narchome .activities .activitiesslide .ui.secondary.pointing.menu::-webkit-scrollbar-thumb {
    background-color: #fff;
  }
}

.context-narchome .activities .activitiesslide .ui.secondary.pointing.menu .active.item {
  border-color: #c00096;
  border-bottom-width: 4px;
  font-weight: 500;
  color: #c00096;
}

.context-narchome .activities .activitiesslide .ui.secondary.pointing.menu .active.item:hover {
  border-color: #c00096;
}

.context-narchome .activities .activitiesslide .ui.secondary.pointing.menu .item {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #121212;
  font-weight: 500;
  padding: 0;
  margin-right: 40px;
  border-bottom: solid 4px transparent;
  width: 179px;
  text-align: center;
  flex-wrap: nowrap;
  align-self: flex-start;
  min-height: 60px;
  line-height: 21px;
  display: inline-block;
}

@media (max-width: 480px) {
  .context-narchome .activities .activitiesslide .ui.secondary.pointing.menu .item {
    margin-right: 20px;
  }
}

.context-narchome .activities .activitiesslide .ui.segment {
  box-shadow: unset;
  padding: 0;
  border: 0;
}

.context-narchome .activities .activitiesslide .block {
  float: left;
  width: 100%;
  margin-top: 50px;
}

.context-narchome .activities .activitiesslide .block .subject {
  float: left;
  width: 100%;
  border-radius: 15px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  margin-right: 30px;
  margin-bottom: 40px;
}

.context-narchome .activities .activitiesslide .block .subject:nth-child(3n + 3) {
  margin-right: 0;
}

@media (max-width: 768px) {
  .context-narchome .activities .activitiesslide .block .subject {
    width: calc((100% - 30px) / 2);
  }

  .context-narchome .activities .activitiesslide .block .subject:nth-child(3n + 3) {
    margin-right: 30px;
  }

  .context-narchome .activities .activitiesslide .block .subject:nth-child(2n + 2) {
    margin-right: 0;
  }
}

@media (max-width: 480px) {
  .context-narchome .activities .activitiesslide .block .subject {
    width: calc((100% - 15px) / 2);
    margin-right: 15px;
    min-height: 0;
  }

  .context-narchome .activities .activitiesslide .block .subject:nth-child(3n + 3) {
    margin-right: 15px;
  }

  .context-narchome .activities .activitiesslide .block .subject:nth-child(2n + 2) {
    margin-right: 0;
  }
}

.context-narchome .activities .activitiesslide .block .subject .img {
  float: left;
  width: 100%;
  border-radius: 15px 15px 0 0;
}

.context-narchome .activities .activitiesslide .block .subject .img img {
  float: left;
  width: 100%;
  border-radius: 15px 15px 0 0;
}

.context-narchome .activities .activitiesslide .block .subject .content {
  float: left;
  width: 100%;
  padding: 20px 10px;
}

.context-narchome .activities .activitiesslide .block .subject .content .heads {
  float: left;
  width: 63%;
}

@media (max-width: 480px) {
  .context-narchome .activities .activitiesslide .block .subject .content .heads {
    width: 100%;
    margin-bottom: 5px;
  }
}

.context-narchome .activities .activitiesslide .block .subject .content .heads .title {
  float: left;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #393939;
  text-decoration: none;
}

@media (max-width: 480px) {
  .context-narchome .activities .activitiesslide .block .subject .content .heads .title {
    font-size: 16px;
  }
}

@media (max-width: 320px) {
  .context-narchome .activities .activitiesslide .block .subject .content .heads .title {
    font-size: 13px;
  }
}

.context-narchome .activities .activitiesslide .block .subject .content .heads .title:hover {
  color: #c00096;
  text-decoration: underline;
  cursor: pointer;
}

.context-narchome .activities .activitiesslide .block .subject .content .status {
  float: right;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  padding: 2px 10px;
}

@media (max-width: 480px) {
  .context-narchome .activities .activitiesslide .block .subject .content .status {
    float: left;
  }
}

.context-narchome .activities .activitiesslide .block .subject .content .status.ongoing {
  background-color: #ffe9fa;
  color: #c00096;
}

.context-narchome .activities .activitiesslide .block .subject .content .status.grey-bg {
  background-color: #969696;
  color: #fff;
  cursor: auto;
}

.context-narchome .activities .activitiesslide .block .subject .content .status.not-released {
  background-color: #969696;
  border: solid 1px #969696;
  color: #fff;
}

.context-narchome .activities {
  margin-top: 100px;
}

@media (max-width: 480px) {
  .context-narchome .activities {
    margin-top: 50px;
  }
}

.context-narchome .narc-btn-plot {
  float: left;
  width: 100%;
  text-align: center;
  margin-bottom: 50px;
}

.context-narchome .narc-btn-plot button {
  display: inline-block;
  width: auto;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  color: #fff;
  border: solid 1px #c41d8e;
  background-color: #c00096;
  border-radius: 25px;
  line-height: 35px;
  margin-top: 20px;
  padding: 4px 34px;
  cursor: pointer;
  font-weight: 500;
}

.context-narchome .narc-btn-plot button:hover {
  background-color: #a90b76;
  border-color: #a90b76;
}

.context-narchome .testimonials {
  float: left;
  width: 100%;
  margin-top: 0;
  margin-bottom: 100px;
  padding-left: calc((100% - 1140px) / 2);
  padding-right: calc((100% - 1140px) / 2);
}

@media (max-width: 768px) {
  .context-narchome .testimonials {
    margin-top: 50px;
    padding: 0 15px;
    margin-bottom: 40px;
  }
}

@media (max-width: 480px) {
  .context-narchome .testimonials {
    margin-top: 60px;
    margin-bottom: 50px;
    padding: 0px;
  }
}

.context-narchome .testimonials .head {
  float: left;
  width: 100%;
  text-align: center;
  position: relative;
}

.context-narchome .testimonials .head::before {
  content: "";
  position: absolute;
  width: 20%;
  height: 5px;
  background-color: #285caa;
  border-radius: 25px;
  left: 40%;
  bottom: -20px;
}

@media (max-width: 768px) {
  .context-narchome .testimonials .head::before {
    width: 30%;
    left: 35%;
  }
}

.context-narchome .testimonials .head .title {
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  font-weight: bold;
  color: #393939;
}

@media (max-width: 768px) {
  .context-narchome .testimonials .head .title {
    font-size: 24px;
  }
}

@media (max-width: 480px) {
  .context-narchome .testimonials .head .title {
    font-size: 20px;
    line-height: 22px;
  }
}

.context-narchome .testimonials .head .subText {
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #393939;
  position: relative;
  margin-top: 5px;
}

@media (max-width: 480px) {
  .context-narchome .testimonials .head .subText {
    font-size: 16px;
  }
}

.context-narchome .testimonials .block {
  float: left;
  width: 100%;
  margin-top: 50px;
  padding: 0;
}

.context-narchome .testimonials .block .slick-list {
  padding: 0 0 0 3px;
}

.context-narchome .testimonials .block .slick-slider {
  padding: 0;
}

@media (max-width: 768px) {
  .context-narchome .testimonials .block .slick-slider {
    padding: 0;
  }
}

.context-narchome .testimonials .block .slick-slide {
  padding-right: 20px;
}

@media (max-width: 480px) {
  .context-narchome .testimonials .block .slick-slide {
    padding: 10px;
  }
}

.context-narchome .testimonials .block .slick-slide div {
  float: left;
  width: 100%;
}

.context-narchome .testimonials .block .slick-next,
.context-narchome .testimonials .block .slick-prev {
  z-index: 1;
}

.context-narchome .testimonials .block .slick-prev {
  left: -14px;
  font-size: 25px;
  color: #000;
  width: 30px;
  height: 30px;
  line-height: 47px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
  z-index: 3;
}

@media (max-width: 768px) {
  .context-narchome .testimonials .block .slick-prev {
    left: -4px;
    top: 50%;
    width: 30px;
    height: 30px;
    border-radius: 0;
  }
}

.context-narchome .testimonials .block .slick-prev .icon {
  position: relative;
  top: -9px;
  left: 1px;
  font-size: 23px;
}

@media (max-width: 768px) {
  .context-narchome .testimonials .block .slick-prev .icon {
    top: -8px;
    left: -1px;
    font-size: 23px;
  }
}

.context-narchome .testimonials .block .slick-prev .icon:before {
  color: #c00096;
}

.context-narchome .testimonials .block .slick-next {
  right: 0;
  font-size: 25px;
  color: #000;
  width: 30px;
  height: 30px;
  line-height: 47px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  z-index: 3;
}

@media (max-width: 768px) {
  .context-narchome .testimonials .block .slick-next {
    right: -8px;
    top: 50%;
    width: 30px;
    height: 30px;
    border-radius: 0;
  }
}

.context-narchome .testimonials .block .slick-next .icon {
  position: relative;
  top: -9px;
  right: -1px;
  font-size: 23px;
}

@media (max-width: 768px) {
  .context-narchome .testimonials .block .slick-next .icon {
    top: -8px;
    right: -1px;
    font-size: 23px;
  }
}

.context-narchome .testimonials .block .slick-next .icon:before {
  color: #c00096;
}

.context-narchome .testimonials .block .slick-next:before,
.context-narchome .testimonials .block .slick-prev:before {
  font-size: 30px;
  color: #393939;
  opacity: 1;
  display: none;
}

.context-narchome .testimonials .block .slick-dots {
  display: none !important;
}

@media (max-width: 768px) {
  .context-narchome .testimonials .block .slick-dots {
    display: none !important;
  }
}

.context-narchome .testimonials .block .slick-dots li {
  width: 8px;
  height: 8px;
}

.context-narchome .testimonials .block .slick-dots li button {
  border: solid 2px #285caa;
  border-radius: 100%;
  width: 8px;
  height: 8px;
}

.context-narchome .testimonials .block .slick-dots li button::before {
  font-size: 14px;
  top: -2px;
  opacity: 0;
  color: #285caa;
  width: 8px;
  height: 8px;
}

.context-narchome .testimonials .block .slick-dots li.slick-active button:before {
  opacity: 1;
}

.context-narchome .testimonials .block .slick-track {
  display: flex !important;
}

.context-narchome .testimonials .block .slick-slide {
  height: inherit !important;
}

.context-narchome .testimonials .block .slick-slide>div {
  display: flex;
  width: 100%;
  height: 100%;
}

.context-narchome .testimonials .block .testimonial {
  float: left;
  width: 100%;
  padding: 20px;
  background-color: #fff;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  margin-top: 10px;
  margin-bottom: 5px;
  border-radius: 15px;
}

.context-narchome .testimonials .block .testimonial.videoPost {
  position: relative;
  padding: 0;
}

.context-narchome .testimonials .block .testimonial.videoPost .artPlot {
  display: none;
}

.context-narchome .testimonials .block .testimonial.videoPost .content {
  display: none;
}

.context-narchome .testimonials .block .testimonial.videoPost .video {
  display: block;
}

.context-narchome .testimonials .block .testimonial .video {
  float: left;
  display: none;
  width: 100%;
  height: 180px;
  border-radius: 15px;
  z-index: -1;
}

.context-narchome .testimonials .block .testimonial .artPlot {
  float: left;
  width: 80px;
}

.context-narchome .testimonials .block .testimonial .artPlot .img {
  float: left;
  width: 80px;
  height: 120px;
  border: solid 1px #285caa;
  border-radius: 15px;
}

.context-narchome .testimonials .block .testimonial .artPlot .img img {
  float: left;
  width: 80px;
  height: 120px;
  border-radius: 15px;
  margin-left: 5px;
  margin-top: -5px;
  margin-bottom: 5px;
  object-fit: cover;
  object-position: center top;
}

.context-narchome .testimonials .block .testimonial .text {
  float: left;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #393939;
  padding-top: 8px;
  text-align: left;
}

.context-narchome .testimonials .block .testimonial .content {
  float: left;
  width: calc(100% - 80px);
  padding-left: 30px;
  padding-right: 15px;
}

.context-narchome .testimonials .block .testimonial .content .testimonialText {
  float: left;
  width: 100%;
  min-height: 100px;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  color: #393939;
  display: flex;
  align-items: center;
  text-align: center;
}

.context-narchome .testimonials .block .testimonial .content .testimonialauthor {
  float: right;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  color: #777;
  margin-top: 5px;
}

.context-narchome .testimonials {
  margin-top: 50px;
}

@media (max-width: 480px) {
  .context-narchome .testimonials {
    padding: 0 15px;
    margin-top: 20px;
  }
}

.context-narchome .schoolCover {
  float: left;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #fff;
  padding-left: calc((100% - 1140px) / 2);
  padding-right: calc((100% - 1140px) / 2);
}

@media (max-width: 1024px) {
  .context-narchome .schoolCover {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 480px) {
  .context-narchome .schoolCover {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.context-narchome .schoolCover .leadSchool {
  float: left;
  width: 100%;
  margin-top: 100px;
}

@media (max-width: 480px) {
  .context-narchome .schoolCover .leadSchool {
    margin-top: 50px;
  }
}

.context-narchome .schoolCover .leadSchool .head {
  float: left;
  width: 100%;
  text-align: center;
  position: relative;
}

.context-narchome .schoolCover .leadSchool .head::before {
  content: "";
  position: absolute;
  width: 20%;
  height: 5px;
  background-color: #285caa;
  border-radius: 25px;
  left: 40%;
  bottom: -20px;
}

@media (max-width: 768px) {
  .context-narchome .schoolCover .leadSchool .head::before {
    width: 30%;
    left: 35%;
  }
}

.context-narchome .schoolCover .leadSchool .head .title {
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  font-weight: bold;
  color: #393939;
}

@media (max-width: 768px) {
  .context-narchome .schoolCover .leadSchool .head .title {
    font-size: 24px;
  }
}

@media (max-width: 480px) {
  .context-narchome .schoolCover .leadSchool .head .title {
    font-size: 20px;
    line-height: 22px;
  }

  .context-narchome .schoolCover .leadSchool .head .title::before {
    bottom: -35px;
  }
}

.context-narchome .schoolCover .leadSchool .head .subText {
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #393939;
  position: relative;
  margin-top: 5px;
}

@media (max-width: 480px) {
  .context-narchome .schoolCover .leadSchool .head .subText {
    font-size: 16px;
  }
}

.context-narchome .schoolCover .leadSchool .block {
  float: left;
  width: 100%;
  margin-top: 50px;
}

.context-narchome .schoolCover .leadSchool .block .slick-list {
  padding: 0 0 0 3px;
}

.context-narchome .schoolCover .leadSchool .block .slick-slider {
  padding: 0 0%;
}

@media (max-width: 768px) {
  .context-narchome .schoolCover .leadSchool .block .slick-slider {
    padding: 0;
  }
}

@media (max-width: 480px) {
  .context-narchome .schoolCover .leadSchool .block .slick-slider .slick-list {
    padding-left: 18%;
  }
}

.context-narchome .schoolCover .leadSchool .block .slick-slide {
  padding-right: 10px;
}

@media (max-width: 480px) {
  .context-narchome .schoolCover .leadSchool .block .slick-slide .slick-list {
    padding: 0 20% 0 0;
  }
}

.context-narchome .schoolCover .leadSchool .block .slick-next,
.context-narchome .schoolCover .leadSchool .block .slick-prev {
  z-index: 1;
}

.context-narchome .schoolCover .leadSchool .block .slick-prev {
  left: -14px;
  top: 45%;
  font-size: 25px;
  color: #000;
  width: 30px;
  height: 30px;
  line-height: 37px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
}

@media (max-width: 480px) {
  .context-narchome .schoolCover .leadSchool .block .slick-prev {
    left: 8px;
  }
}

.context-narchome .schoolCover .leadSchool .block .slick-prev .icon {
  position: relative;
  top: -4px;
  left: -1px;
}

.context-narchome .schoolCover .leadSchool .block .slick-prev .icon:before {
  color: #c00096;
}

.context-narchome .schoolCover .leadSchool .block .slick-next {
  right: -11px;
  top: 45%;
  font-size: 25px;
  color: #000;
  width: 30px;
  height: 30px;
  line-height: 37px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}

@media (max-width: 480px) {
  .context-narchome .schoolCover .leadSchool .block .slick-next {
    right: 0px;
  }
}

.context-narchome .schoolCover .leadSchool .block .slick-next .icon {
  position: relative;
  top: -4px;
  right: -1px;
}

.context-narchome .schoolCover .leadSchool .block .slick-next .icon:before {
  color: #c00096;
}

.context-narchome .schoolCover .leadSchool .block .slick-next:before,
.context-narchome .schoolCover .leadSchool .block .slick-prev:before {
  font-size: 30px;
  color: #393939;
  opacity: 1;
  display: none;
}

.context-narchome .schoolCover .leadSchool .block .slick-dots {
  display: none !important;
}

@media (max-width: 480px) {
  .context-narchome .schoolCover .leadSchool .block .slick-dots {
    display: none !important;
  }
}

.context-narchome .schoolCover .leadSchool .block .slick-dots li button {
  border: solid 2px #285caa;
  border-radius: 100%;
}

.context-narchome .schoolCover .leadSchool .block .slick-dots li button::before {
  font-size: 21px;
  top: 2px;
  opacity: 0;
  color: #285caa;
}

.context-narchome .schoolCover .leadSchool .block .slick-dots li.slick-active button:before {
  opacity: 1;
}

.context-narchome .schoolCover .leadSchool .block .slick-track {
  display: flex !important;
}

.context-narchome .schoolCover .leadSchool .block .slick-slide {
  height: inherit !important;
}

.context-narchome .schoolCover .leadSchool .block .slick-slide>div {
  display: flex;
  width: 100%;
  height: 100%;
}

.context-narchome .schoolCover .leadSchool .block .schoolPost {
  float: left;
  width: 100%;
  padding: 0;
  background-color: #fff;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  margin-top: 10px;
  margin-bottom: 5px;
  border-radius: 15px;
  overflow: hidden;
}

.context-narchome .schoolCover .leadSchool .block .schoolPost .imgblock {
  float: left;
  width: 100%;
  position: relative;
  padding: 2px;
}

.context-narchome .schoolCover .leadSchool .block .schoolPost .imgblock .text {
  width: auto;
  position: absolute;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #c00096;
  padding: 5px 10px;
  border-radius: 20px;
  border: solid 1px #c00096;
  top: 10px;
  left: 10px;
  background-color: #fff;
}

.context-narchome .schoolCover .leadSchool .block .schoolPost .imgblock .img {
  float: left;
  width: 100%;
}

.context-narchome .schoolCover .leadSchool .block .schoolPost .imgblock .img img {
  float: left;
  width: 100%;
}

.context-narchome .schoolCover .leadSchool .block .schoolPost .content {
  float: left;
  width: 100%;
  padding: 15px;
}

.context-narchome .schoolCover .leadSchool .block .schoolPost .content .title {
  float: left;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: #393939;
  min-height: 34px;
}

.context-narchome .schoolCover .leadSchool .block .schoolPost .content .title:hover {
  color: #c00096;
  text-decoration: underline;
}

.context-narchome .schoolCover .leadSchool .block .schoolPost .content .location {
  float: left;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  color: #777;
  margin-top: 5px;
  width: 100%;
}

.context-narchome .schoolCover .national {
  float: left;
  width: 100%;
  margin-top: 95px;
}

.context-narchome .schoolCover .national .head {
  float: left;
  width: 100%;
  text-align: center;
  position: relative;
}

.context-narchome .schoolCover .national .head::before {
  content: "";
  position: absolute;
  width: 20%;
  height: 5px;
  background-color: #285caa;
  border-radius: 25px;
  left: 40%;
  bottom: -20px;
}

@media (max-width: 768px) {
  .context-narchome .schoolCover .national .head::before {
    width: 30%;
    left: 35%;
  }
}

.context-narchome .schoolCover .national .head .title {
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  font-weight: bold;
  color: #393939;
}

@media (max-width: 768px) {
  .context-narchome .schoolCover .national .head .title {
    font-size: 24px;
  }
}

@media (max-width: 480px) {
  .context-narchome .schoolCover .national .head .title {
    font-size: 20px;
    line-height: 22px;
  }

  .context-narchome .schoolCover .national .head .title::before {
    bottom: -35px;
  }
}

.context-narchome .schoolCover .national .head .subText {
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #393939;
  position: relative;
  margin-top: 5px;
}

@media (max-width: 480px) {
  .context-narchome .schoolCover .national .head .subText {
    font-size: 16px;
  }
}

.context-narchome .schoolCover .national .block {
  float: left;
  width: 100%;
  margin-top: 50px;
}

.context-narchome .schoolCover .national .block .slick-slider {
  padding: 0 0%;
}

@media (max-width: 768px) {
  .context-narchome .schoolCover .national .block .slick-slider {
    padding: 0;
  }
}

@media (max-width: 480px) {
  .context-narchome .schoolCover .national .block .slick-slider .slick-list {
    padding-left: 18%;
  }
}

.context-narchome .schoolCover .national .block .slick-slide {
  padding-right: 10px;
}

@media (max-width: 480px) {
  .context-narchome .schoolCover .national .block .slick-slide .slick-list {
    padding: 0 20% 0 0;
  }
}

.context-narchome .schoolCover .national .block .slick-next,
.context-narchome .schoolCover .national .block .slick-prev {
  z-index: 1;
}

.context-narchome .schoolCover .national .block .slick-prev {
  left: -14px;
  top: 45%;
  font-size: 25px;
  color: #000;
  width: 30px;
  height: 30px;
  line-height: 37px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
}

@media (max-width: 480px) {
  .context-narchome .schoolCover .national .block .slick-prev {
    left: 8px;
  }
}

.context-narchome .schoolCover .national .block .slick-prev .icon {
  position: relative;
  top: -4px;
  left: -1px;
}

.context-narchome .schoolCover .national .block .slick-prev .icon:before {
  color: #c00096;
}

.context-narchome .schoolCover .national .block .slick-next {
  right: -11px;
  top: 45%;
  font-size: 25px;
  color: #000;
  width: 30px;
  height: 30px;
  line-height: 37px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}

@media (max-width: 480px) {
  .context-narchome .schoolCover .national .block .slick-next {
    right: 0px;
  }
}

.context-narchome .schoolCover .national .block .slick-next .icon {
  position: relative;
  top: -4px;
  right: -1px;
}

.context-narchome .schoolCover .national .block .slick-next .icon:before {
  color: #c00096;
}

.context-narchome .schoolCover .national .block .slick-next:before,
.context-narchome .schoolCover .national .block .slick-prev:before {
  font-size: 30px;
  color: #393939;
  opacity: 1;
  display: none;
}

.context-narchome .schoolCover .national .block .slick-dots {
  display: none !important;
}

@media (max-width: 480px) {
  .context-narchome .schoolCover .national .block .slick-dots {
    display: none !important;
  }
}

.context-narchome .schoolCover .national .block .slick-dots li button {
  border: solid 2px #285caa;
  border-radius: 100%;
}

.context-narchome .schoolCover .national .block .slick-dots li button::before {
  font-size: 21px;
  top: 2px;
  opacity: 0;
  color: #285caa;
}

.context-narchome .schoolCover .national .block .slick-dots li.slick-active button:before {
  opacity: 1;
}

.context-narchome .schoolCover .national .block .schoolPost {
  float: left;
  width: 100%;
  padding: 0;
  background-color: #fff;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  margin-top: 10px;
  margin-bottom: 5px;
  border-radius: 15px;
  overflow: hidden;
}

.context-narchome .schoolCover .national .block .schoolPost .imgblock {
  float: left;
  width: 100%;
  position: relative;
  padding: 2px;
}

.context-narchome .schoolCover .national .block .schoolPost .imgblock .text {
  width: auto;
  position: absolute;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #c00096;
  padding: 5px 10px;
  border-radius: 20px;
  border: solid 1px #c00096;
  top: 10px;
  left: 10px;
  background-color: #fff;
}

.context-narchome .schoolCover .national .block .schoolPost .imgblock .img {
  float: left;
  width: 100%;
}

.context-narchome .schoolCover .national .block .schoolPost .imgblock .img img {
  float: left;
  width: 100%;
}

.context-narchome .schoolCover .national .block .schoolPost .content {
  float: left;
  width: 100%;
  padding: 15px;
}

.context-narchome .schoolCover .national .block .schoolPost .content .title {
  float: left;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: #393939;
  min-height: 34px;
}

.context-narchome .schoolCover .national .block .schoolPost .content .title:hover {
  color: #c00096;
  text-decoration: underline;
}

.context-narchome .schoolCover .national .block .schoolPost .content .location {
  float: left;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  color: #777;
  margin-top: 5px;
  width: 100%;
}

/*# sourceMappingURL=narc21-22.css.map */