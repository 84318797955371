/* Profile Page */

.profile-box {
  background: linear-gradient(to bottom, #C3DAFF, #FFFFFF);
  border-bottom: 1px solid #E1E1E1;
}

.profile-box button {
  font-weight: 600;
  border: 2px solid #C41D8E;
}

.post-title {
  font-weight: 600;
}

.post-date {
  color: #747373;
}

.post-grade {
  font-size: 18px;
  font-weight: 600;
}

.post-comment {
  font-size: 18px;
  font-weight: 600;
}

.dot {
  height: 5px;
  width: 5px;
  background-color: #000;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
}

.btn-outline-theme {
  color: #C41D8E;
  border-color: #C41D8E;
}

.btn-outline-theme:hover {
  color: #fff;
  background-color: #C41D8E;
  border-color: #C41D8E;
}

.btn-text-theme {
  color: #C41D8E;
  background-color: transparent;
}

.post-item {
  border-bottom: 2px solid #E1E1E1;
}

.post-item:last-child {
  border-bottom: none;
}

.post-container {
  max-height: 1000px;
  overflow-y: scroll;
}

.btn-theme {
  color: #fff !important;
  background-color: #C41D8E !important;
  border-color: #C41D8E !important;
}

.btn-theme-primary {
  background-color: #285caa;
  border-color: #285caa;
  color: #fff;
}

.btn-theme-primary:hover {
  background-color: #285caa;
  border-color: #285caa;
  color: #fff
}

.btn-neon {
  color: #fff;
  background-color: #22e600 !important;
  border-color: #22e600 !important;
}


.form .form-pagination ul li {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  border: solid 1px #c41d8e;
  margin-right: 15px;
}

.form .form-pagination ul li.active {
  background-color: #c41d8e;
}

.nsquiz-summary .title {
  font-size: 16px;
  font-weight: 700;
  font-family: 'Montserrat';
}

.kidex-text-warning {
  color: #FFC803;
}

.kidex-text-success {
  color: #3EC41D;
}

.kidex-text-danger {
  color: #FF6969;
}

.theme-text-color {
  color: #c41d8e;
}

.notiflix-confirm .notiflix-confirm-content .notiflix-confirm-buttons a {
  text-decoration: none;
}

.notiflix-report-button {
  text-decoration: none;
}

.fs-15 {
  font-size: 15px;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.hero-icon {
  width: 22px;
  height: 22px;
}

.how-it-work-img {
  width: 35% !important;
  height: auto;
  /* Maintain aspect ratio */
}

.navbar.navbar-header {
  background: #fff;
}