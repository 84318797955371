.logo-img {
  max-width: 300px;
  position: relative;
  margin: 20px 0px;
}

.pr-found-banner-section {
  background-image: url(../../Assets/banner/banner.jpg);
  background-repeat: no-repeat;
  background-position: top right;
  background-size: cover;
  position: relative;
  z-index: 1;
  padding: 4% 0px;
}

.pr-found-banner-content {
  padding-right: 50%;
  padding-bottom: 70px;
  position: relative;
}

.pr-found-banner-content h1 {
  font-size: 40px;
  font-weight: 600;
  line-height: 120%;
  text-align: left;
  color: #648115;
  margin: 0px 0px 20px;
}

.pr-found-banner-content p {
  font-size: 32px;
  font-weight: 600;
  line-height: 130%;
  text-align: left;
  color: #8FB623;
}

.pr-found-banner-section .col-md-6>img {
  max-width: 100%;
}

.pr-found-body-content {
  padding: 40px 0px;
}

.pr-found-main-title {
  padding-bottom: 20px !important;
  font-weight: 700 !important;
}

.pr-found-main-title::before {
  background-color: #A8CD3E !important;
}

.pr-found-title {
  font-size: 28px;
  font-weight: 700;
  line-height: 120%;
  text-align: center;
  margin: 0px 0px 40px;
}

.pr-found-body-content p {
  font-size: 18px;
  font-weight: 400;
  line-height: 145%;
  text-align: left;

}

.pr-found-body-content h2 {
  position: relative;
  text-align: center;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.pr-found-body-content h2::after {
  content: "";
  width: 150px;
  height: 6px;
  background-color: #A8CD3E;
  position: absolute;
  bottom: 0px;
  left: 50%;
  border-radius: 26px;
  transform: translate(-50%, -50%);
}

/* .bg-bubble1 {
  background: url(../../Assets/images/bubble1.png) bottom center no-repeat;
  background-size: contain;
}

.bubble2,
.bubble3,
.bubble4 {
  position: relative;
}

.bubble2:after,
.bubble2:before,
.bubble3:before,
.bubble4:after {
  content: '';
  position: absolute;
}

.bubble2:after {
  top: 0px;
  right: 0px;
  background: url(../../Assets/images/bubble2-left.png) center center no-repeat;
  background-size: contain;
  width: 200px;
  height: 200px;
}

.bubble2:before {
  bottom: 0px;
  left: 0px;
  background: url(../../Assets/images/bubble2-right.png) center center no-repeat;
  background-size: contain;
  width: 323px;
  height: 236px;
}

.bubble3:before {
  top: 50%;
  left: 0px;
  transform: translate(-10%, -50%);
  background: url(../../Assets/images/bubble3.png) center center no-repeat;
  background-size: contain;
  width: 471px;
  height: 389px;
}

.bubble4:after {
  bottom: 0px;
  right: 0px;
  background: url(../../Assets/images/bubble4.png) center center no-repeat;
  background-size: contain;
  width: 457px;
  height: 251px;
} */

.pr-found-slider .swiper-button-next,
.pr-found-slider .swiper-button-prev {
  color: #A8CD3E !important;
  box-shadow: 2px 9px 1px rgb(168 205 62 /25%)
}

.pr-found-slider .competitions-item img.chmpn-slider-img {
  max-height: max-content;
}

@media screen and (max-width:767px) {
  .pr-found-banner-section {
    background-image: url(../../Assets/banner/mob-banner.png);
  }

  .logo-img {
    max-width: 125px;
    margin: 10px 0px;
  }

  .pr-found-banner-content {
    padding-right: 13%;
    padding-bottom: 20px;
    background-size: 50%;
  }

  .pr-found-banner-content h1 {
    font-size: 17px;
    margin-bottom: 6px;
    padding-right: 50%;
  }

  .pr-found-banner-content p {
    font-size: 12px;
    padding-right: 50%;
  }

  .pr-found-title {
    font-size: 20px;
    margin-bottom: 0px;
  }

  .pr-found-body-content {
    padding: 20px 0px;
  }

  .pr-found-body-content img {
    border-radius: 20px;
  }

  .pr-found-body-content p {
    font-size: 12px;
    margin: 20px 0px 0px;
    text-align: left;
  }

  /* .bg-bubble1 {
    background: url(../../Assets/images/bubble1.png) 0px 20px no-repeat;
    background-size: contain;
  }

  .bubble2 {
    overflow: hidden;
  }

  .bubble2:after {
    display: none;
  }

  .bubble2:before {
    bottom: 10px;
    left: auto;
    right: -50px;
    background-size: 50%;
    width: 250px;
    height: 100px;
    transform: rotate(-115deg);
  }

  .bubble3:before {
    top: auto;
    bottom: 0;
    left: 0px;
    transform: translate(-20%, -10%);
    background: url(../../Assets/images/bubble3-mob.png) center center no-repeat;
    background-size: contain;
    width: 231px;
    height: 239px;
  }

  .bubble4:after {
    top: auto;
    bottom: 0;
    right: 0px;
    background: url(../../Assets/images/bubble4-mob.png) center center no-repeat;
    background-size: contain;
    width: 231px;
    height: 239px;
  } */
}

@media screen and (min-width:768px) and (max-width:1023px) {
  .pr-found-banner-content {
    padding-right: 50%;
    padding-bottom: 20px;
  }

  .logo-img {
    margin: 0px 0px 10px;
  }

  .pr-found-banner-content h1 {
    font-size: 28px;
    margin: 0px 0px 10px;
  }

  .pr-found-banner-content p {
    padding-top: 10px;
    font-size: 18px;
  }
}

@media screen and (min-width:1024px) and (max-width:1199px) {
  .pr-found-banner-content {
    padding-right: 13%;
    padding-bottom: 20px;
    background-size: 50%;
  }

  .pr-found-banner-content p {
    padding-right: 40%;
    padding-top: 40px;
  }

  .pr-found-banner-content h1 {
    padding-right: 40%;
  }
}