body {
  font-family: "Montserrat", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#Master-naukari .navbar {
  box-shadow: 1px 1px 5px rgb(43 92 170 / 20%);
}

#home-page.navbar {
  box-shadow: none;
}

/* #Master-naukari .navbar .navbar-brand {
  height: 3rem !important;
} */

#Master-naukari .navbar .navbar-brand img {
  height: 100% !important;
  width: 100% !important;
}

#Master-naukari .navbar a.nav-link {
  color: black !important;
  font-size: 15px;
  font-weight: 500;
}

#Master-naukari .navbar ul.navbar-nav {
  justify-content: space-evenly;
}

#Master-naukari .navbar-header .navbar-toggler {
  border: none;
  z-index: 1111;
  padding: 0px;
}

#Master-naukari .navbar-header .navbar-toggler:focus {
  box-shadow: none;
}

#Master-naukari div#mynavbar {
  height: 100%;
  padding: 15px;
}

#Master-naukari .dropdown .dropdown-menu {
  border: none !important;
  font-size: 13px !important;
  font-weight: 500;
  border-radius: 1px;
  box-shadow: 0px 0px 10px #ddd;
}

#Master-naukari .dropdown .dropdown-item {
  font-weight: 500;
  font-size: 16px;
}

.navbar-dark .navbar-toggler-icon {
  background-image: none;
}

#Master-naukari .navbar .contact {
  font-size: 14px;
  font-weight: 500;
}

#Master-naukari .carousel-indicators .active {
  height: 8px !important;
  width: 15px !important;
}

#Master-naukari .carousel-indicators [data-bs-target] {
  width: 10px !important;
  height: 10px !important;
}

#Master-naukari .c-loop {
  background-image: url(../../../public/images/wave.png);
  background-size: 100% 62%;
  background-repeat: no-repeat;
}

#Master-naukari .c-loop .heading {
  padding-top: 4.5rem;
  padding-bottom: 2rem;
}

#Master-naukari .c-detail h6 {
  font-size: 15px;
  line-height: 1.8;
}

#Master-naukari .c-detail p {
  color: #585858;
}

#Master-naukari .group {
  height: 350px;
  width: 70%;
  margin-top: 6rem;
  margin-bottom: 7rem;
  position: relative;
}

#Master-naukari .group img {
  width: 100%;
  height: 100%;
}

#Master-naukari .img-text h6 {
  font-size: 14px;
  color: #fff;
  font-weight: 600;
}

#Master-naukari .img-text .hdng-1 {
  position: absolute;
  right: 16%;
  top: 12%;
}

#Master-naukari .img-text .hdng-2 {
  position: absolute;
  right: 37%;
  top: 28%;
  transform: rotate(-41deg);
}

#Master-naukari .img-text .hdng-3 {
  position: absolute;
  left: 35%;
  top: 73%;
  transform: rotate(-35deg);
}

#Master-naukari .img-text .hdng-4 {
  position: absolute;
  left: 8%;
  top: 72%;
  transform: rotate(40deg);
}

#Master-naukari .img-text .hdng-5 {
  position: absolute;
  left: 14%;
  top: 12%;
  transform: rotate(0deg);
}

#Master-naukari .img-text .hdng-6 {
  position: absolute;
  left: 36%;
  top: 27%;
  transform: rotate(44deg);
}

#Master-naukari .img-text .hdng-7 {
  position: absolute;
  right: 34%;
  top: 68%;
  transform: rotate(44deg);
}

#Master-naukari .img-text .hdng-8 {
  position: absolute;
  right: 8%;
  top: 74%;
  transform: rotate(-42deg);
}

#Master-naukari .training-sec {
  background: linear-gradient(161.97deg, #ebf3ff 5.55%, #ffffff 74.72%);
}

#Master-naukari .training-sec .hdng {
  padding: 4.5rem 8rem 2rem;
}

#Master-naukari .training-sec p {
  color: #585858;
}

#Master-naukari .training-sec h6 {
  font-size: 15px;
  line-height: 1.8;
  padding-bottom: 7rem;
}

#Master-naukari .team .hdng {
  padding-top: 5rem;
}

#Master-naukari .cards {
  background-image: url(../../../public/images/card-bg.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding: 60px 30px;
}

#Master-naukari .Interview {
  background-image: url(../../../public/images/wave-2.png);
  background-size: 100% 100%;
}

#Master-naukari .Interview .hdng {
  padding: 4.5rem 10rem 2rem;
}

#Master-naukari .Interview h6 {
  font-size: 15px;
  line-height: 1.8;
}

#Master-naukari .Interview p {
  color: #585858;
}

#Master-naukari .carousel-inner {
  padding: 1em;
}

#Master-naukari .card {
  margin: 0 0.5em;
  box-shadow: 2px 6px 8px 0 rgba(22, 22, 26, 0.18);
  border: none;
}

#Master-naukari .crousel-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  background-color: #0009;
  display: flex;
  align-items: center;
}

#Master-naukari .crousel-overlay-content {
  padding: 0 8rem;
  width: 60%;
}

#Master-naukari .crousel-overlay h3 {
  color: #fff !important;
}

#Master-naukari .slider-logo {
  position: absolute;
  right: 42px;
  bottom: 20px;
}

#Master-naukari .slider-logo img {
  height: auto;
  width: 100%;
}

#Master-naukari .carousel-item {
  width: 100%;
  max-height: 450px;
}

#Master-naukari .carousel-item img {
  width: 100%;
  height: auto;
}

#Master-naukari .carousel-caption.d-none.d-md-block {
  text-align: left;
  left: 9%;
  top: 37%;
  font-weight: 900;
}

#Master-naukari .carousel-control-prev,
.carousel-control-next {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
}

#Master-naukari .footer {
  background-color: #e9f2ff;
  float: left;
  width: 100%;
}

#Master-naukari .footer .right-sec .map {
  width: 100%;
  height: 200px;
}

#Master-naukari .footer .right-sec .map img {
  width: 100% !important;
  height: 100% !important;
}

#Master-naukari .footer li {
  list-style-type: none;
}

#Master-naukari .footer .part-1 .logo {
  height: 110px;
}

#Master-naukari .footer h6 {
  font-size: 15px;
}

#Master-naukari .footer .part-2 {
  margin-top: 4rem;
}

#Master-naukari .footer .part-2 li {
  font-size: 15px;
}

#Master-naukari .footer-contact-us a {
  text-decoration: none;
  color: #000;
  cursor: pointer;
}

#Master-naukari .footer .right-sec a {
  color: #000;
  font-size: 15px;
}

#Master-naukari .top-slider .carousel-inner {
  padding: 0px;
}

#Master-naukari .slide-icon img {
  height: 34px;
  background: #fff;
  padding: 8px;
  width: 34px;
  border-radius: 50%;
}

#Master-naukari .top-slider .carousel-control-prev {
  left: 50px;
  border: none !important;
  outline: none;
}

#Master-naukari .top-slider .carousel-control-next {
  right: 50px;
}

#Master-naukari .carousel-indicators {
  bottom: -40px !important;
}

#Master-naukari .carousel-indicators .active {
  opacity: 1;
  width: 22px !important;
  background-color: #594dc9 !important;
  border-radius: 12px !important;
}

#Master-naukari .carousel-indicators [data-bs-target] {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border-top: none;
  border-bottom: none;
  background-color: #dfdfdf;
}

#Master-naukari .cards-inner-sec {
  padding: 0px 36px;
}

#Master-naukari .employe-card {
  height: 380px;
  padding: 18px;
  border-radius: 2rem;
  border: none;
  box-shadow: 6px 6px 10px 2px #8888;
}

#Master-naukari .employe-card {
  margin: 0px 40px;
}

#Master-naukari .employe-card-inr {
  border-radius: 14px;
  border: none;
  height: 100%;
  padding: 6px;
  box-shadow: 6px 6px 10px 2px #8888;
  position: relative;
}

#Master-naukari .content-card {
  border: none;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  background: linear-gradient(90deg, #ffffff 0.04%, #dfe0e0 100.05%);
}

#Master-naukari .content-card p {
  font-size: 16px;
  color: #585858;
  font-weight: 600;
}

#Master-naukari .rounded-card {
  z-index: 1111;
  left: 52%;
  top: 12px;
  transform: translate(-50%, -50%);
  position: absolute;
  height: 70px;
  width: 80%;
  border-radius: 50%;
  background: radial-gradient(50.04% 49.94% at 50.01% 49.92%,
      #b0b0b0 0%,
      #c8c8c8 25.91%,
      #f0f0f0 74.67%,
      #ffffff 100%);
}

#Master-naukari .header-outer {
  position: absolute;
  z-index: 2222;
  left: 50%;
  top: 12px;
  transform: translate(-50%, -50%);
}

#Master-naukari .header {
  height: 60px;
  width: 176px;
  padding: 6px 20px;
  text-align: center;
  position: relative;
  border-radius: 51% 51% 47% 53% / 0% 0% 17% 18%;
}

#Master-naukari .header::after {
  content: " ";
  position: absolute;
  left: -20px;
  top: 0px;
  width: 0;
  height: 0;
  border-width: 0 0px 18px 20px;
  border-style: solid;
}

#Master-naukari .header::before {
  content: " ";
  position: absolute;
  right: -20px;
  top: 0px;
  width: 0;
  height: 0;
  border-width: 0 20px 18px 0px;
  border-style: solid;
}

#Master-naukari .circle-card {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  padding: 10px;
  position: absolute;
  bottom: -50px;
  left: 50%;
  transform: translate(-50%, 0%);
  box-shadow: 6px 6px 10px 2px #8888;
}

#Master-naukari .circle-card-inr {
  background-color: #ffff;
  border-radius: 50%;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#Master-naukari .circle-card-icon {
  width: 100%;
  height: 100%;
  padding: 18px;
}

.place-card.place-card-medium {
  display: none !important;
}

#Master-naukari .card-header-first {
  background: linear-gradient(105.08deg, #ffb3ae -29.85%, #ffe1a6 151.12%);
}

#Master-naukari .card-header-first::after {
  border-color: transparent transparent #ffba33 transparent;
}

#Master-naukari .card-header-first::before {
  border-color: transparent transparent #ffdc97 transparent;
}

#Master-naukari .employe-card-inr-first {
  background: linear-gradient(90deg, #ffb3ae 0%, #ffdb5e 100%);
}

#Master-naukari .circle-card-first {
  background: linear-gradient(105.08deg, #ffb3ae -29.85%, #ffe1a6 151.12%);
}

.card-header-second {
  background: linear-gradient(105.08deg, #ea74e5 -29.86%, #0029a6 151.11%);
}

#Master-naukari .card-header-second::after {
  border-color: transparent transparent #8b30c7 transparent;
}

#Master-naukari .card-header-second::before {
  border-color: transparent transparent #8b30c7 transparent;
}

#Master-naukari .employe-card-inr-second {
  background: linear-gradient(131.39deg, #ea74e5 7.52%, #0029a6 116.33%);
}

#Master-naukari .circle-card-second {
  background: linear-gradient(131.39deg, #ea74e5 7.52%, #0029a6 116.33%);
}

.card-header-third {
  background: linear-gradient(105.08deg, #9feeff -29.87%, #6e34ea 151.1%);
}

#Master-naukari .card-header-third::after {
  border-color: transparent transparent #6e34ea transparent;
}

#Master-naukari .card-header-third::before {
  border-color: transparent transparent #6e34ea transparent;
}

#Master-naukari .employe-card-inr-third {
  background: linear-gradient(105.08deg, #9feeff -29.87%, #6e34ea 151.1%);
}

#Master-naukari .circle-card-third {
  background: linear-gradient(105.08deg, #9feeff -29.87%, #6e34ea 151.1%);
}

#Master-naukari .team .card {
  background: #ffffff;
  box-shadow: 4px 4px 15px rgba(43, 92, 170, 0.2);
  border-radius: 30px;
}

#Master-naukari .team .card-img-top {
  height: 250px;
  width: 100%;
  padding: 20px;
  border-radius: 30px;
}

#Master-naukari .team .card-img-top img {
  height: 100px;
  width: 100%;
}

#Master-naukari .team .owl-dots {
  display: none;
}

#Master-naukari .team .slider {
  margin-bottom: 5rem;
}

.owl-stage-outer {
  padding: 30px 0px;
}

#Master-naukari .team p.card-text {
  font-size: 13px;
}

#Master-naukari .team .owl-prev,
#Master-naukari .team .owl-next {
  position: absolute;
  top: 50%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: #fff;
  text-align: center;
  background: 0 0;
  border: 0;
  transition: opacity 0.15s ease;
}

#Master-naukari .team .owl-prev {
  left: 0px;
}

#Master-naukari .team .owl-next {
  right: 0px;
}

#Master-naukari .team .owl-prev img,
#Master-naukari .team .owl-next img {
  height: 34px;
  background: #fff;
  padding: 8px;
  width: 34px;
  border-radius: 50%;
  box-shadow: 0px 2px 9px 1px rgb(196 29 142 / 25%);
}

#Master-naukari .team .card-body {
  padding-top: 0;
}

#Master-naukari .employe-card-inr {
  padding: 6px 2px;
}

.post-slider .owl-stage-outer {
  padding: 60px 0px;
}

#Master-naukari .post-slider .owl-prev,
#Master-naukari .post-slider .owl-next {
  position: absolute;
  top: 50%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: #fff;
  text-align: center;
  background: 0 0;
  border: 0;
  transition: opacity 0.15s ease;
}

#Master-naukari .post-slider .owl-prev {
  left: 0px;
}

#Master-naukari .post-slider .owl-next {
  right: 0px;
}

#Master-naukari .post-slider .owl-prev img,
#Master-naukari .post-slider .owl-next img {
  height: 34px;
  background: #fff;
  padding: 8px;
  width: 34px;
  border-radius: 50%;
  box-shadow: 0px 2px 9px 1px rgb(196 29 142 / 25%);
}

#Master-naukari .post-slider .owl-dots {
  display: none;
}

.bar1,
.bar2,
.bar3 {
  width: 22px;
  height: 4px;
  background-color: #ccc;
  margin: 4px 0;
  transition: 0.4s;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-10px, 3px);
  transform: rotate(-45deg) translate(-10px, 3px);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -2px);
  transform: rotate(45deg) translate(-8px, -2px);
}

#Master-naukari .terms-conditions {
  border-top: 1px solid #ddd;
  text-align: end;
}

#Master-naukari .terms-conditions .container {
  display: flex;
}

#Master-naukari .terms-conditions .container p.terms-con-link {
  display: flex;
  align-items: center;
  justify-content: end;
}

#Master-naukari .terms-conditions .container p {
  margin: 0px;
  padding: 10px 0px;
  width: inherit;
  text-align: end;
  font-size: 12px;
}

#Master-naukari .terms-conditions .container p.copy-right-content span {
  font-weight: 700;
  font-size: 18px;
  margin-right: 4px;
}

#Master-naukari .terms-conditions .container p.copy-right-content {
  display: flex;
  align-items: center;
  justify-content: end;
}

#Master-naukari .terms-conditions p a {
  text-decoration: none;
  padding: 0px 10px;
  color: #000;
  font-size: 12px;
}

#Master-naukari .terms-conditions p a:first-child {
  border-right: 1px solid #000;
  padding-left: 0px;
}

#Master-naukari .terms-condition {
  height: 156px;
  display: flex;
  align-items: end;
}

/* responsive */

@media screen and (min-width: 320px) and (max-width: 425px) {
  #Master-naukari .navbar {
    box-shadow: none;
  }

  #Master-naukari .navbar-header .navbar-toggler:focus {
    z-index: 1111;
  }

  #Master-naukari div#mynavbar {
    height: 100%;
    background: #fff;
    padding: 15px;
    position: absolute;
    right: 0px;
    height: max-content;
    top: 50px;
    width: 165px;
  }

  #Master-naukari div#mynavbar ul li a {
    font-weight: 600;
    font-size: 14px;
  }

  #Master-naukari div#mynavbar .dropdown .dropdown-item {
    padding: 0px 0px 6px 0px !important;
  }

  #Master-naukari .navbar-header {
    width: 100%;
    position: absolute;
    z-index: 1111;
  }

  #Master-naukari .dropdown .dropdown-menu {
    background: none;
  }

  #Master-naukari .crousel-overlay-content {
    padding: 0 !important;
    margin: 0 auto;
  }

  #Master-naukari .top-slider .carousel-control-next {
    right: 26px;
  }

  #Master-naukari .top-slider .carousel-control-prev {
    left: 26px;
  }

  #Master-naukari .group {
    max-height: 170px !important;
  }

  #Master-naukari .crousel-overlay h3 {
    font-size: 18px;
  }

  #Master-naukari .navbar-toggler-icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  #Master-naukari .navbar .contact {
    padding: 0.5rem 0;
  }

  #Master-naukari .slider-logo {
    right: 11px;
    bottom: 12px;
    height: 45px;
  }

  #Master-naukari .slider-logo img {
    width: 100%;
    height: 100%;
  }

  #Master-naukari .carousel-item {
    height: 300px;
  }

  #Master-naukari .carousel-item img {
    height: 100% !important;
    object-fit: cover;
    object-position: 50% 100%;
  }

  #Master-naukari .c-loop .heading {
    padding-top: 2.8rem;
    padding-bottom: 2.5rem;
  }

  #Master-naukari .c-loop .heading h3 {
    font-size: 19px;
  }

  #Master-naukari .c-detail p {
    font-size: 14px;
    padding: 0px 10px;
  }

  #Master-naukari .c-detail h6 {
    font-size: 13px;
    padding: 0px 20px;
    text-align: justify;
  }

  #Master-naukari .group {
    max-height: 250px;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  #Master-naukari .group img {
    height: 100%;
    width: 100%;
  }

  #Master-naukari .img-text h6 {
    font-size: 10px;
  }

  #Master-naukari .training-sec .hdng {
    padding: 2rem 0 0.5rem;
  }

  #Master-naukari .training-sec .hdng h3 {
    font-size: 19px;
  }

  #Master-naukari .training-sec p {
    font-size: 14px;
    padding-bottom: 0 !important;
  }

  #Master-naukari .training-sec h6 {
    font-size: 12px;
    padding-bottom: 0 !important;
    text-align: justify;
    padding: 0px 20px;
  }

  #Master-naukari .cards-inner-sec {
    padding-top: 5rem !important;
  }

  #Master-naukari .header.card-header-second h5 {
    font-size: 18px;
  }

  #Master-naukari .cards {
    background-image: unset;
    padding: 0;
  }

  #Master-naukari .employe-card {
    height: 350px;
    margin: 0px 22px;
  }

  #Master-naukari .training-sec p {
    font-size: 13px;
    padding: 0px 10px;
  }

  #Master-naukari .Interview .hdng {
    padding: 5.5rem 0 0.5rem;
  }

  #Master-naukari .Interview .hdng h3 {
    font-size: 19px;
  }

  #Master-naukari .Interview p {
    font-size: 14px;
    padding-bottom: 0 !important;
  }

  #Master-naukari .Interview h6 {
    font-size: 12px;
    padding-bottom: 0 !important;
    text-align: justify;
    padding: 0px 20px;
  }

  #Master-naukari .team .hdng {
    padding-top: 2rem;
    margin-bottom: 0rem !important;
  }

  #Master-naukari .team .slider {
    margin-bottom: 2rem;
  }

  #Master-naukari .footer .left-sec {
    margin-top: 2rem !important;
  }

  #Master-naukari .footer .part-2 {
    margin-top: 2rem;
  }

  #Master-naukari .footer .right-sec {
    margin-top: 1.5rem !important;
  }

  #Master-naukari .footer .right-sec ul {
    padding-left: 0;
  }
}

@media screen and (min-width: 426px) and (max-width: 575px) {

  /* #Master-naukari .navbar {
    box-shadow: none;
  } */
  #Master-naukari .navbar-header .navbar-toggler:focus {
    z-index: 1111;
  }

  #Master-naukari div#mynavbar {
    height: 100%;
    background: #fff;
    padding: 15px;
    position: absolute;
    right: 0px;
    height: max-content;
    top: 50px;
    width: 165px;
    /*  */
    box-shadow: 4px 4px 15px rgb(43 92 170 / 20%);
  }

  #Master-naukari div#mynavbar ul li a {
    font-weight: 600;
    font-size: 14px;
  }

  #Master-naukari div#mynavbar .dropdown .dropdown-item {
    padding: 0px 0px 6px 0px !important;
  }

  #Master-naukari .navbar-header {
    width: 100%;
    position: absolute;
    z-index: 1111;
  }

  #Master-naukari .dropdown .dropdown-menu {
    background: none;
  }

  #Master-naukari .carousel-item {
    height: 300px;
  }

  #Master-naukari .carousel-item img {
    height: 100% !important;
    object-fit: cover;
    object-position: 50% 100%;
  }

  #Master-naukari .crousel-overlay-content {
    padding: 0 !important;
    margin: 0 auto;
  }

  #Master-naukari .crousel-overlay h3 {
    font-size: 18px !important;
  }

  #Master-naukari .top-slider .carousel-control-prev {
    left: 30px;
  }

  #Master-naukari .top-slider .carousel-control-next {
    right: 30px;
  }

  #Master-naukari .c-detail h6 {
    padding: 0px 20px;
    text-align: justify;
  }

  #Master-naukari .c-detail p {
    font-size: 14px;
    padding: 0px 12px;
  }

  #Master-naukari .training-sec h6 {
    text-align: justify;
    padding: 0px 20px;
  }

  #Master-naukari .Interview h6 {
    font-size: 12px;
    text-align: justify;
    padding: 0px 20px;
  }

  #Master-naukari .crousel-overlay-content {
    padding: 0 !important;
    padding-left: 0px !important;
  }

  #Master-naukari .group {
    max-height: 170px !important;
  }

  #Master-naukari .crousel-overlay h3 {
    font-size: 12px;
  }

  #Master-naukari .navbar-toggler-icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  #Master-naukari .navbar .contact {
    padding: 0.5rem 0;
  }

  #Master-naukari .slider-logo {
    right: 11px;
    bottom: 12px;
    height: 45px;
  }

  #Master-naukari .slider-logo img {
    width: 100%;
    height: 100%;
  }

  #Master-naukari .c-loop .heading {
    padding-top: 2.8rem;
    padding-bottom: 2.5rem;
  }

  #Master-naukari .c-loop .heading h3 {
    font-size: 19px;
  }

  #Master-naukari .c-detail h6 {
    font-size: 13px;
  }

  #Master-naukari .group {
    max-height: 250px;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  #Master-naukari .group img {
    height: 100%;
    width: 100%;
  }

  #Master-naukari .img-text h6 {
    font-size: 10px;
  }

  #Master-naukari .training-sec .hdng {
    padding: 2rem 0 0.5rem;
  }

  #Master-naukari .training-sec .hdng h3 {
    font-size: 19px;
  }

  #Master-naukari .training-sec p {
    font-size: 14px;
    padding-bottom: 0 !important;
  }

  #Master-naukari .training-sec h6 {
    font-size: 12px;
    padding-bottom: 0 !important;
    padding: 0px 20px;
  }

  #Master-naukari .cards-inner-sec {
    padding-top: 5rem !important;
  }

  #Master-naukari .header.card-header-second h5 {
    font-size: 18px;
  }

  #Master-naukari .cards {
    background-image: unset;
    padding: 0;
  }

  #Master-naukari .training-sec p {
    font-size: 13px;
  }

  #Master-naukari .Interview .hdng {
    padding: 5.5rem 0 0.5rem;
  }

  #Master-naukari .Interview .hdng h3 {
    font-size: 19px;
  }

  #Master-naukari .Interview p {
    font-size: 14px;
    padding-bottom: 0 !important;
  }

  #Master-naukari .team .hdng {
    margin-bottom: 0rem !important;
  }

  #Master-naukari .team .slider {
    margin-bottom: 2rem;
  }

  #Master-naukari .footer .left-sec {
    margin-top: 2rem !important;
  }

  #Master-naukari .footer .part-2 {
    margin-top: 2rem;
  }

  #Master-naukari .footer .right-sec {
    margin-top: 1.5rem !important;
  }

  #Master-naukari .footer .right-sec ul {
    padding-left: 0;
  }

  #Master-naukari .cards-inner-sec {
    padding: 0px 60px;
  }
}

@media screen and (min-width: 426px) and (max-width: 480px) {
  #Master-naukari .employe-card {
    margin: 0 auto;
    width: 300px;
  }

  #Master-naukari .post-slider .owl-next {
    right: 30px;
  }

  #Master-naukari .post-slider .owl-prev {
    left: 30px;
  }

  #Master-naukari .team .card {
    width: 320px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 481px) and (max-width: 780px) {
  #Master-naukari .employe-card {
    margin: 0 auto;
    width: 320px;
  }

  #Master-naukari .post-slider .owl-next,
  #Master-naukari .team .owl-next {
    right: 45px;
  }

  #Master-naukari .post-slider .owl-prev,
  #Master-naukari .team .owl-prev {
    left: 45px;
  }

  #Master-naukari .team .card {
    width: 350px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 781px) and (max-width: 992px) {
  #Master-naukari .employe-card {
    margin: 0px 16px;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  #Master-naukari div#mynavbar {
    padding: 14px;
  }

  #Master-naukari .carousel-item {
    height: 300px;
  }

  #Master-naukari .carousel-item img {
    height: 100% !important;
    object-fit: cover;
    object-position: 50% 100%;
  }

  #Master-naukari .crousel-overlay-content {
    padding: 0 !important;
    margin: 0 auto;
  }

  #Master-naukari .navbar a.nav-link {
    font-size: 13px;
  }

  #Master-naukari .navbar .contact {
    font-size: 13px;
  }

  #Master-naukari .slide-icon img {
    height: 30px;
    width: 30px;
  }

  #Master-naukari .top-slider .carousel-control-prev {
    left: 30px;
  }

  #Master-naukari .top-slider .carousel-control-next {
    right: 30px;
  }

  #Master-naukari .crousel-overlay-content {
    padding: 0 !important;
  }

  #Master-naukari .crousel-overlay h3 {
    font-size: 20px;
  }

  #Master-naukari .crousel-overlay h3 {
    font-size: 20px;
    padding-left: 68px;
  }

  #Master-naukari .slider-logo {
    right: 16px !important;
    bottom: 12px !important;
    width: 100px;
  }

  #Master-naukari .c-loop .heading {
    padding-top: 2.8rem;
    padding-bottom: 0.6rem;
  }

  #Master-naukari .c-loop .heading h3 {
    font-size: 21px;
  }

  #Master-naukari .c-detail p {
    font-size: 15px;
  }

  #Master-naukari .c-detail h6 {
    font-size: 14px;
  }

  #Master-naukari .group {
    height: 200px;
    width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  #Master-naukari .c-loop {
    background-size: 100% 74%;
  }

  #Master-naukari .img-text h6 {
    font-size: 10px;
  }

  #Master-naukari .training-sec .hdng {
    padding: 2rem 0 0.5rem;
  }

  #Master-naukari .training-sec .hdng h3 {
    font-size: 21px;
  }

  #Master-naukari .training-sec p {
    font-size: 15px;
    padding-bottom: 0 !important;
  }

  #Master-naukari .training-sec h6 {
    font-size: 14px;
    padding-bottom: 0 !important;
    padding: 0px 20px;
  }

  #Master-naukari .cards-inner-sec {
    padding-top: 5rem !important;
    padding: 0px 100px;
  }

  #Master-naukari .header.card-header-second h5 {
    font-size: 18px;
  }

  #Master-naukari .cards {
    background-image: unset;
    padding: 0;
  }

  #Master-naukari .employe-card {
    height: 350px;
  }

  #Master-naukari .training-sec p {
    font-size: 13px;
  }

  #Master-naukari .Interview .hdng {
    padding: 5.5rem 0 0.5rem;
  }

  #Master-naukari .Interview .hdng h3 {
    font-size: 21px;
  }

  #Master-naukari .Interview p {
    font-size: 15px;
    padding-bottom: 0 !important;
  }

  #Master-naukari .Interview h6 {
    font-size: 14px;
    padding-bottom: 0 !important;
    padding: 0px 20px;
  }

  #Master-naukari .team .hdng {
    margin-bottom: 0rem !important;
  }

  #Master-naukari .team .slider {
    margin-bottom: 2rem;
  }

  #Master-naukari .footer .left-sec {
    margin-top: 2rem !important;
  }

  #Master-naukari .footer .part-2 {
    margin-top: 2rem;
  }

  #Master-naukari .footer .right-sec {
    margin-top: 1.5rem !important;
  }

  #Master-naukari .footer .right-sec ul {
    padding-left: 0;
  }

  #Master-naukari .footer .right-sec .map {
    height: 170px;
  }

  #Master-naukari .team .card-img-top {
    height: 200px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  #Master-naukari .crousel-overlay-content {
    padding: 0 0 0 6rem;
  }

  #Master-naukari .slider-logo {
    right: 16px !important;
    bottom: 6px !important;
    width: 100px;
  }

  #Master-naukari .group {
    height: 250px;
    width: 90%;
    margin-top: 3rem;
    margin-bottom: 4rem;
  }

  #Master-naukari .c-loop {
    background-size: 100% 74%;
  }

  #Master-naukari .training-sec .hdng {
    padding: 3.5rem 0rem 1rem;
  }

  #Master-naukari .Interview .hdng {
    padding: 3.5rem 0rem 1.5rem;
  }

  #Master-naukari .cards-inner-sec {
    padding: 56px 150px;
  }

  #Master-naukari .training-sec h6 {
    padding-bottom: 0rem;
  }

  #Master-naukari .cards {
    background-image: unset;
    padding: 0 30px;
  }

  #Master-naukari .cards-inner-sec {
    padding: 70px 20px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1024px) {
  #Master-naukari .group {
    height: 250px;
    margin-top: 4rem;
    margin-bottom: 5rem;
  }

  #Master-naukari .cards-inner-sec {
    padding: 0px 14px;
  }

  #Master-naukari .header {
    width: 150px;
    padding: 6px 10px;
  }

  #Master-naukari .training-sec .hdng {
    padding: 4.5rem 0rem 2rem;
  }

  #Master-naukari .training-sec h6 {
    padding-bottom: 1rem;
  }

  #Master-naukari .content-card p {
    font-size: 15px;
  }

  #Master-naukari .Interview .hdng {
    padding: 4.5rem 0rem 1.5rem;
  }

  .header h3 {
    font-size: 18px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1060px) {
  #Master-naukari .employe-card {
    margin: 0px 70px;
  }
}

@media screen and (min-width: 1061px) and (max-width: 1260px) {
  #Master-naukari .employe-card {
    margin: 0px 8px;
  }
}

@media screen and (min-width: 320px) and (max-width: 1260px) {
  #Master-naukari .t-n-c-block {
    margin-left: 8px;
    margin-right: 8px;
  }
}

@media screen and (min-width: 320px) and (max-width: 560px) {
  #Master-naukari .terms-conditions .container {
    display: block;
    padding: 10px 0px;
  }

  #Master-naukari .terms-conditions .container p.copy-right-content {
    justify-content: center;
  }

  #Master-naukari .terms-conditions .container p.terms-con-link {
    justify-content: center;
  }

  #Master-naukari .terms-conditions .container p {
    padding: 4px 0px;
  }
}