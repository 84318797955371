.start-quiz-content-sec .quiz-detail-header .quiz-detail-sec {
  display: flex;
  justify-content: space-between;
  padding-top: 5rem;
  padding-bottom: 2rem;
}
.start-quiz-content-sec .quiz-detail-header .quiz-detail-sec .quiz-title h5 {
  font-weight: 600;
  color: #282828;
}
.start-quiz-content-sec .quiz-progress-bar-sec {
  background-color: #f2f7ff;
  padding: 20px;
  margin-bottom: 20px;
}
.start-quiz-content-sec .quiz-progress-bar-sec h6 span {
  font-weight: 600;
}
.start-quiz-content-sec .quiz-detail-sec .quiz-title p {
  color: #282828;
}
.start-quiz-content-sec .quiz-progress-bar {
  display: flex;
  justify-content: space-between;
  padding-top: 14px;
  width: 80%;
  margin: auto;
}
.start-quiz-content-sec .quiz-progress-bar .progress {
  width: 80%;
  border-radius: 10px;
}
.start-quiz-content-sec .quiz-progress-bar .progress .progress-bar {
  background-color: #c41d8e;
  border-radius: 10px;
}
.start-quiz-content-sec .quiz-progress-bar span {
  padding-left: 12px;
  color: #282828;
  font-weight: 500;
}
.start-quiz-content-sec .quiz-hint-sec .container {
  display: flex;
  align-items: baseline;
  justify-content: end;
  position: relative;
}
.start-quiz-content-sec .quiz-hint-sec p {
  color: #282828;
  font-weight: 600;
  padding: 0px 14px;
}
.start-quiz-content-sec .quiz-hint-sec .hint-icon {
  width: 36px;
}
.start-quiz-content-sec .hint-text-sec {
  width: 200px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 4px;
  position: absolute;
  right: 0px;
  top: 50px;
  z-index: 11;
}
.start-quiz-content-sec .hint-text-sec span {
  font-size: 12px;
}
.start-quiz-content-sec .question-btn {
  padding: 26px 0px 45px 0px;
  display: flex;
  justify-content: center;
}
.start-quiz-content-sec .question-btn a {
  margin: 0px 10px;
}
.start-quiz-content-sec .question-btn a.disable-btn {
  background-color: #d3d3d3;
}
/* quiz summary */
.quiz-summary-content {
  padding: 3rem 0rem;
}
.quiz-summary-content .attempt-summary-heading h3 {
  font-weight: 600;
  color: #c41d8e;
  padding: 3rem 0rem;
}
.quiz-summary-content .attempt-summary-item-card {
  background: #ffffff;
  box-shadow: 0px 3px 8px rgb(0 0 0 / 15%);
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem;
}
.quiz-summary-content .attempt-summary-item-card p {
  margin: 0px;
  font-weight: 600;
  color: #282828;
}
.quiz-summary-content .attempt-summary-item-card span {
  font-weight: 600;
  color: #282828;
  min-width: 60px;
  text-align: end;
}
.quiz-summary-content .score-title h4 {
  color: #c41d8e;
  font-weight: 600;
  margin-top: 2rem;
  margin-bottom: 3rem;
}
/* attempt status color*/
.quiz-summary-content .attempt-summary-item-card span.attempt-satus-yellow {
  color: #ffc803;
}
.quiz-summary-content .attempt-summary-item-card span.attempt-satus-green {
  color: #3ec41d;
}
.quiz-summary-content .attempt-summary-item-card span.attempt-satus-red {
  color: #ff6969;
}
/*  */
@media screen and (max-width: 540px) {
  .quiz-header-btns .nav-tabs .nav-link {
    margin: 0px 10px;
  }
  .start-quiz-content-sec .quiz-progress-bar {
    width: 90%;
  }
  .start-quiz-content-sec .quiz-progress-bar span {
    min-width: 110px;
  }
  .start-quiz-content-sec .quiz-hint-sec .hint-icon {
    width: 30px;
  }
  .question-header .question-time img {
    width: 24px;
  }
  .question-header .question h5 {
    padding-right: 6px;
    line-height: 24px;
    font-size: 18px;
  }
}
@media screen and (max-width: 476px) {
  .quiz-summary-content {
    padding: 0rem 0rem;
  }
  .quiz-summary-content .attempt-summary-heading h3 {
    padding: 2rem 0rem;
    margin-top: 1rem;
  }
  .quiz-summary-content .score-title h4 {
    margin-bottom: 1rem;
  }
  .card-bottom-btn button.quiz-button-pnk-comn,
  .card-bottom-btn button.quiz-button-out-link-pnk-comn {
    margin-top: 1rem;
  }
  .card-bottom-btn {
    margin-bottom: 2rem;
  }
  .quiz-header-btns .nav-tabs .nav-link {
    margin: 10px 12px;
    padding: 10px 34px;
  }
}
@media screen and (max-width: 420px) {
  .quiz-header-btns .nav-tabs .nav-link {
    padding: 10px 18px;
    margin: 10px 4px;
  }
}
@media screen and (max-width: 320px) {
  .quiz-header-btns .nav-tabs .nav-link {
    margin: 10px 5px;
    padding: 10px 16px;
  }
}
