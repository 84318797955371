.champion-landing-bnr-sec {
  position: relative;
}

.champion-landing-bnr-sec-height {
  /* height: 493px; */
}

.champion-landing-slider {
  width: 100%;
  float: left;
}

.champion-landing-bnr-sec img.cmp-lndng-bnr-img {
  /* max-height: 470px; */
  width: 100%;
}

.champion-landing-bnr-sec img.cmp-lndng-bnr-img-mb {
  display: none;
}

.champion-landing-bnr-sec a.lndng-google-pay {
  padding: 0px;
  width: auto;
  max-width: 190px;
}

.champion-landing-bnr-sec a.lndng-google-pay img {
  width: 100%;
}

/* .champion-landing-slider .slick-arrow {
  display: none !important;
} */

.champion-landing-slider .slick-prev {
  padding: 0px;
  width: 40px;
  left: 40px;
  height: 40px;
  background: #ffffff;
  box-shadow: 4px 4px 17px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  text-align: center;
  display: flex !important;
  align-items: center;
  justify-content: center;
  z-index: 11;
  display: none !important;
}

.champion-landing-slider .slick-next {
  padding: 0px;
  width: 44px;
  height: 44px;
  right: 40px;
  background: #ffffff;
  box-shadow: 4px 4px 17px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  text-align: center;
  display: flex !important;
  align-items: center;
  justify-content: center;
  z-index: 11;
}

.champion-landing-slider .chmp-arrowIcon {
  width: 16px;
}

.champion-landing-slider .slick-prev:before,
.champion-landing-slider .slick-next:before {
  display: none;
}

.champion-landing-bnr-sec .slider-overly-box .chmpn-button-sec select.form-select.chmpn-custom-selecter {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAPCAYAAAD+pA/bAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACCSURBVHgBtZCBCYAgEEX/CG1QozRCG3QbOaojXCdowYXpefbgQETfQ4EMM28yC5wkR3LpzcAPJwZJd2Vi9oSyufEbcyTLNWt5UvREKnK+v1wWVDnQjHzISR80R7rlIxGz3BIZlvdE3PKOiF9ujBA8NCKEGVQihJmoCOEPRHzI7JY7F17KNbQxXzV2AAAAAElFTkSuQmCC);
  background-position: 94% 54%;
}

/*  */
.slider-overly-box {
  position: absolute;
  width: 100%;
  bottom: 15%;
  left: 0%;
}

.champion-landing-slider .slider-overly-box .chmpn-button-sec {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: start;
}

/* common css start*/
.champion-landing-content .chmpn-container {
  display: flow-root;
  width: 100%;
  padding-left: calc((100% - 1140px) / 2);
  padding-right: calc((100% - 1140px) / 2);
}

.champion-landing-content .btn:focus {
  box-shadow: none;
}

.champion-landing-content .btn.chmpn-pnk-btn {
  padding: 8px 36px;
  border: none;
  background-color: #c41d8e;
  color: #ffff;
  font-weight: 600;
  border-radius: 26px;
}

.champion-landing-content .btn.chmpn-white-btn {
  padding: 8px 50px;
  border: none;
  background-color: #ffff;
  color: #c41d8e;
  font-weight: 600;
  border-radius: 26px;
  margin-left: 10px;
}

.champion-landing-content .chmpn-lndg-con .chmpn-button-sec .btn.chmpn-white-btn {
  margin-left: 20px;
}

/* heading css */
.competitions-content {
  padding-top: 2rem;
}

.competitions-content .chmpn-main-hdng {
  position: relative;
}

.competitions-content .chmpn-main-hdng select.chmpn-custom-selecter.form-select {
  position: absolute;
  left: 0px;
  transform: translate(-50%, -50%);
  top: 50%;
}

.competitions-content .chmpn-main-hdng h3,
.competitions-content .chmpn-main-hdng h1 {
  font-size: 30px;
  font-weight: 800;
  color: #000;
  position: relative;
  padding-top: 20px;
  padding-bottom: 36px;
}

.competitions-content .chmpn-main-hdng h3::before,
.competitions-content .chmpn-main-hdng h1::before {
  content: "";
  width: 200px;
  height: 6px;
  background-color: #2b5caa;
  position: absolute;
  bottom: 0px;
  left: 50%;
  border-radius: 26px;
  transform: translate(-50%, -50%);
}

/* select btn*/
.chmpn-register-select-btn select.chmpn-custom-selecter {
  width: max-content;
  text-align: center;
  border-radius: 26px;
  border: 1px solid #c41d8e;
  color: #c41d8e;
  font-weight: 600;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAPCAYAAAD+pA/bAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAD3SURBVHgBpZDLDYJAEIZn8ZWYaCxAzLZhvGgHdqCWgDbgzYMPShArIUESysBoAcDZIGayYAjCMqtfwmNnZr8/uwApHt9xe7AdwJ+gA13ZWsOXOzLNOO74rV4/8EanBfwI7m32+j66XP1oYo3Z3OStOPHzgw0Gy/F9fQEFUB4nYOVr3Wab4wnC9PmAgyonKZMjUfCMtNnNCF+QGMUmNaRKziBZzUIjZFnB0Y9LDdi5OCi7Lpl88thY4j+HSghF/hVADaHKSwPqQvBLlYt6BVUhZVTJRU8CJUQmF/0aZCF1cjFDoCyEIhdzRPIhVLkyjn6YX4f7qcqeNxNnkqewBpsfAAAAAElFTkSuQmCC);
  position: relative;
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: 90% 54%;
}

/* slider css */
.competitions-content-slider .slick-arrow::before {
  display: none;
}

.competitions-content-slider .slick-list {
  padding: 20px 0px;
  padding-bottom: 30px;
}

.competitions-content-slider .slick-prev,
.competitions-content-slider .slick-next {
  padding: 0px;
  width: 40px;
  height: 40px;
  background: #ffffff;
  box-shadow: 4px 4px 17px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  text-align: center;
  display: flex !important;
  align-items: center;
  justify-content: center;
  z-index: 11;
}

.competitions-content-slider .slick-prev:hover,
.competitions-content-slider .slick-next:hover {
  background: #ffffff;
}

.competitions-content-slider .chmp-arrowIcon {
  width: 12px;
}

.competitions-content-slider .chmp-arrowIcon img {
  padding: 10px;
}

.competitions-content-slider {
  padding: 15px 50px;
}

.free-registration .competitions-content-slider .slick-list {
  padding: 20px 16%;
}

.competitions-content-slider .competitions-item {
  width: 100%;
  box-shadow: 0 2px 11px rgb(0 0 0 / 24%);
  border-radius: 16px;
  position: relative;
  height: calc(100% - 0px);
}

.competitions-content-slider .competitions-item img.chmpn-slider-img {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  min-height: 168px;
  max-height: 236px;
  width: 100%;
}

.competitions-content-slider .competitions-item .chmpn-slider-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 30px 20px;
  font-weight: 600;
  position: relative;
}

.competitions-content-slider .chmpn-slider-content a.explore-nav {
  color: #c41d8e;
  min-width: 100px;
}

.competitions-content-slider .chmpn-slider-content a {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  padding: 0px;
}

.competitions-content-slider .chmpn-slider-content a {
  color: #000000;
  font-weight: 600;
}

/*slider register btn */
/*  */
.chmpn-live-bdg {
  padding: 4px 20px;
  background-color: #ff0000;
  border-radius: 26px;
  font-weight: 600;
  color: #fff;
  position: absolute;
  text-transform: capitalize;
  top: 0px;
  left: 4rem;
  transform: translate(-50%, -50%);
}

/* chmp-accrodian */
.chmp-accrodian .accordion-button.btn-accordion-title h5 {
  color: #000000 !important;
  font-weight: 600;
  min-height: 46px;
  display: flex;
  align-items: center;
}

.chmp-accrodian .accordion-item {
  margin: 10px 0px;
  border: none;
  box-shadow: 0 0 5px rgb(0 0 0 / 30%);
}

.chmp-accrodian .accordion-button::after {
  /* background-image: url("../../skin/ChampionsNewImg/accordian-plus.png"); */
}

.chmp-accrodian .accordion-button:not(.collapsed)::after {
  /* background-image: url("../../skin/ChampionsNewImg/accordian-minus.png"); */
  height: 5px;
}

.chmp-accrodian .accordion-body {
  padding: 1rem 1.25rem;
  font-size: 16px;
  text-align: justify;
  line-height: 26px;
  font-weight: 500;
}

/*  */
.competitions-content .context_narchome .slick-arrow.slick-prev,
.competitions-content .context_narchome .slick-arrow.slick-next {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 58px;
}

/* common css end*/
.chmpn-sec-text {
  display: flex;
  align-items: center;
  color: #000000;
}

.chmpn-sec-text img.sec-grl-img {
  width: 100px;
  height: auto;
}

.chmpn-sec-text-con {
  width: -webkit-fill-available;
  text-align: center;
}

.chmpn-sec-text-con span {
  color: #285caa;
  font-weight: 600;
}

.chmp-pricipate-flow {
  padding-top: 3rem;
}

.event-sub-text {
  color: #285caa;
  font-weight: 600;
}

.cstm-tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.cstm-tooltip:hover .tooltiptext {
  visibility: visible;
}

.text-overlay {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  color: #2B5CAA;
  font-size: 40px;
  font-weight: 700;
  /* Change this to the color you need */
  padding: 20px;
  /* background-color: rgba(0, 0, 0, 0.5); */
  /* Optional: Adds a semi-transparent background */
  width: 50%;
}

@media (max-width: 1024px) {
  .text-overlay {
    top: 0;
    left: 0;
    transform: translate(0, 0);
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 25px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1024px) {
  .text-overlay {
    top: 0;
    left: 0;
    transform: translate(0, 0);
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 35px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .text-overlay {
    top: 0;
    left: 0;
    transform: translate(0, 0);
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 30px;
  }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .text-overlay {
    top: 0;
    left: 0;
    transform: translate(0, 0);
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 20px;
  }
}

/* responsive */
@media screen and (max-width: 1200px) {
  .champion-landing-bnr-sec .chmpn-lndg-con .chmpn-container {
    padding-left: 26px;
    padding-right: 26px;
  }

  .champion-landing-bnr-sec .chmpn-lndg-con {
    position: absolute;
    bottom: -10%;
  }

  .champion-landing-bnr-sec .chmpn-lndg-con .chmpn-bnr-title {
    padding-right: 6rem;
  }

  /*  */
  .competitions-content .chmpn-main-hdng h3,
  .competitions-content .chmpn-main-hdng h1 {
    padding-left: 40px;
    padding-right: 40px;
  }

  .chmp-accrodian .context_narchome .narcworks .sectionBlock .middleSection {
    width: 36%;
  }

  .chmpn-container .chmpn-sec-text {
    padding: 0px 20px;
  }

  /*  */
  .competitions-content {
    padding-top: 1rem;
  }

  .competitions-content .chmp-pricipate-flow {
    padding-top: 0px;
  }

  .competitions-content .context_narchome .narcworks {
    margin-top: 0px;
  }

  .competitions-content .narcLand .narcBenifit {
    padding: 0px;
    margin-top: 0px;
  }

  .competitions-content .context_narchome .testimonials {
    margin-top: 0px;
  }

  .competitions-content .context_narchome {
    padding: 0px 20px;
  }

  .competitions-content .chmp-accrodian {
    padding: 0px 20px;
  }
}

@media screen and (max-width: 1120px) {

  /*  */
  .champion-landing-content .context_narchome .narcworks .sectionBlock .rightSection .block:nth-child(3) {
    margin-left: 20px;
  }

  .chmp-accrodian .row {
    margin: 0px;
  }
}

@media screen and (max-width: 920px) {
  .champion-landing-bnr-sec .chmpn-lndg-con {
    position: absolute;
    bottom: -10%;
  }

  .champion-landing-bnr-sec .chmpn-lndg-con .chmpn-bnr-title {
    padding-right: 2rem;
    width: 72%;
  }

  .champion-landing-bnr-sec .slider-overly-box {
    padding: 0px;
  }

  .competitions-content-slider .competitions-item .chmpn-slider-content {
    padding: 30px 10px;
    font-size: 13px;
  }
}

@media screen and (max-width: 768px) {
  .champion-landing-bnr-sec {
    width: 100%;
  }

  .champion-landing-bnr-sec-height {
    height: 218px;
  }

  .champion-landing-bnr-sec img.cmp-lndng-bnr-img {
    display: none;
  }

  .champion-landing-bnr-sec img.cmp-lndng-bnr-img-mb {
    display: block;
  }

  .champion-landing-bnr-sec a.lndng-google-pay {
    width: 30%;
  }

  /*  */
  .champion-landing-content .btn.chmpn-pnk-btn {
    padding: 8px 30px;
  }

  .champion-landing-content .btn.chmpn-white-btn {
    padding: 8px 30px;
  }

  .competitions-content .chmpn-main-hdng h3,
  .competitions-content .chmpn-main-hdng h1 {
    font-size: 24px;
    padding-bottom: 30px;
  }

  .chmpn-container .reg-table-details .table-header p {
    font-size: 14px;
    min-width: 160px;
    padding: 6px 12px;
  }

  .chmpn-container .reg-table-details .table-body-con .table-body-data-row p {
    padding: 12px 12px;
    font-size: 12px;
    min-width: 180px;
  }

  .chmpn-container .reg-table-details .table-body-con .premium-data .premium-yes-icon {
    width: 80px;
  }
}

@media screen and (max-width: 680px) {
  .champion-landing-content .chmpn-select-dropbtn {
    min-width: 142px;
  }

  /* .champion-landing-content .experiential-cards .experientialcards {
    min-width: 142px;
  } */
  .champion-landing-content .btn.chmpn-pnk-btn {
    padding: 6px 30px 6px 16px;
  }

  .champion-landing-content .btn.chmpn-white-btn {
    padding: 6px 30px;
  }

  .experiential-cards-list .card-title {
    font-size: 22px !important;
  }

  .experiential-cards-list .card-banner-data {
    position: absolute;
    top: 27%;
    left: 6%;
  }

  .experiential-cards-list .age-bar {
    background: #fff;
    padding: 4px 18px;
    font-size: 18px !important;
    font-weight: 700;
    border-radius: 20px;
  }

  .experiential-cards-list .total-activities {
    font-size: 18px !important;
    padding: 8px 0px;
    font-weight: 700;
    color: #fff;
  }

  /*  */
  .chmpntxt-outer {
    margin-bottom: 0px;
  }

  .champion-landing-content .champion-landing-bnr-sec .chmpn-lndg-con .chmpn-bnr-text {
    width: 80%;
    margin-bottom: 0px;
    padding-right: 0px;
  }

  /*  */
  .competitions-content .chmpn-main-hdng select.chmpn-custom-selecter.form-select {
    position: unset;
    right: unset;
    margin: 20px auto;
    transform: unset;
  }

  .chmpn-container .chmpn-sec-text {
    padding: 0px;
  }

  .chmpn-sec-text img.sec-grl-img {
    display: none;
  }

  .chmpn-sec-text-con {
    text-align: justify;
  }

  .chmpn-sec-text-con p {
    margin: 0px;
    margin-bottom: 10px;
  }

  /* common css */
  .competitions-content .chmpn-main-hdng h3,
  .competitions-content .chmpn-main-hdng h1 {
    font-size: 22px;
    padding-bottom: 20px;
  }

  .competitions-content {
    padding: 0px 20px;
    padding-top: 1rem;
  }

  .competitions-content-slider {
    padding: 15px 12px;
  }

  .chmp-accrodian .accordion-button.btn-accordion-title h5 {
    min-height: 30px;
    font-size: 14px;
  }

  .chmp-accrodian .accordion-body {
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
  }

  .competitions-content .chmpn-main-hdng h3::before,
  .competitions-content .chmpn-main-hdng h1::before {
    width: 150px;
  }

  /*  */
  .competitions-content-slider .competitions-item img.chmpn-slider-img {
    height: auto;
  }
}

@media screen and (max-width: 640px) {
  .competitions-item .card-title {
    top: 5%;
  }
}

/* register */
.champion-register-banner img.chmp-register-bnnr {
  max-height: 640px;
  width: 100%;
  object-fit: cover;
  object-position: 100% 100%;
}

.paid-reg-detail-table-sec {
  width: 100%;
  overflow: auto;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.paid-reg-detail-table-sec::-webkit-scrollbar-thumb {
  height: 10px;
  background-color: #c41d8e !important;
  margin-top: 1px;
}

.paid-reg-detail-table-sec::-webkit-scrollbar {
  background-color: #ffe9f8;
}

.reg-table-details {
  border: 1px solid #c41d8e;
  border-radius: 8px;
  min-width: max-content;
  width: 100%;
  margin-bottom: 10px;
}

.reg-table-details .table-header {
  padding: 10px 0px;
  background-color: #c41d8e;
  display: flex;
  justify-content: space-around;
  border-radius: 7px;
}

.reg-table-details .table-header p {
  margin: 0px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  width: -webkit-fill-available;
  min-width: 180px;
  text-align: center;
  padding: 6px 20px;
}

.reg-table-details .table-header p:first-child {
  text-align: start;
}

.reg-table-details .table-body-con .table-body-data-row {
  display: flex;
  justify-content: space-around;
}

.reg-table-details .table-body-con .table-body-data-row p {
  margin: 0px;
  color: #c41d8e;
  width: -webkit-fill-available;
  min-width: 180px;
  text-align: center;
  padding: 18px 20px;
  font-weight: 500;
}

.reg-table-details .table-body-con .table-body-data-row p:first-child {
  text-align: start;
  color: #000000;
}

.reg-table-details .table-body-con .table-body-data-row p.premium-data {
  background-color: #ffe9f8;
  font-weight: 600;
}

.reg-table-details .table-body-con .premium-data .premium-yes-icon {
  width: 120px;
}

/* free registration slider */
.free-rgt-item-block {
  height: 320px;
  min-height: 100px;
  padding: 20px;
}

.free-rgt-item-block img.free-rgt-bnnr {
  width: 100%;
  height: 100%;
}

/*  */
@media screen and (max-width: 1260px) {
  .paid-reg-detail-table-outer {
    padding: 0px 20px;
  }

  .free-rgt-item-block {
    padding: 10px;
  }
}

@media screen and (max-width: 1024px) {
  .free-rgt-item-block {
    height: 280px;
  }
}

@media screen and (max-width: 960px) {
  .free-rgt-item-block {
    height: 250px;
  }
}

@media screen and (max-width: 768px) {
  .free-rgt-item-block {
    height: 240px;
  }
}

@media screen and (max-width: 720px) {
  .free-rgt-item-block {
    height: 220px;
  }
}

@media screen and (max-width: 640px) {
  .free-rgt-item-block {
    height: 200px;
    padding: 10px;
  }
}

@media screen and (max-width: 520px) {
  .free-rgt-item-block {
    height: 180px;
    padding: 6px;
  }

}

@media (max-width:480px) {
  .champion-landing-content .context_narchome .narcworks .sectionBlock .rightSection .block:nth-child(3) {
    margin-left: 0px;
  }

  .champ-detail .banner .banner-slot,
  .champ-detail .banner .banner-slot::after {
    position: unset !important;
  }

  .champ-detail .banner .banner-slot .content {
    top: 60% !important;
  }

  .champ-detail .banner .banner-slot .rule-content {
    top: 55% !important;
  }
}

@media screen and (max-width: 460px) {
  .champion-landing-content .context_narchome .narcworks .sectionBlock .rightSection .block:nth-child(3) {
    margin-left: 0px;
  }

  .free-rgt-item-block {
    height: 160px;
    padding: 6px;
  }

  .champ-detail .banner .banner-slot .rule-content {
    top: 45% !important;
  }
}

@media screen and (max-width: 420px) {
  .champion-landing-content .context_narchome .narcworks .sectionBlock .rightSection .block:nth-child(3) {
    margin-left: 0px;
  }

  .free-rgt-item-block {
    height: 130px;
  }
}

.paid-reg-detail-table-outer {
  padding: 0px 20px;
}

/* champion payment option */
.champion-payment-option-wrapper header {
  background-color: #ffff;
}

.champion-payment-option-con {
  background-color: #ffe9f8;
}

.champion-payment-option-con .payment-option-box {
  padding: 20px 36px;
  margin: 3rem 0rem;
}

.champion-payment-option-con .paymt-box-hdng {
  padding-bottom: 20px;
  border-bottom: 1px solid #cbcbcb;
}

.champion-payment-option-con .paymt-box-event-reg-pay {
  display: flex;
  justify-content: space-between;
}

.champion-payment-option-con .paymt-box-event-reg-pay p {
  color: #101010;
  padding: 22px 10px;
  padding-bottom: 6px;
  margin: 0px;
  font-weight: 600;
  font-size: 18px;
}

.champion-payment-option-con .paymt-box-event-reg-pay p.box-payment {
  font-weight: 800;
}

.champion-payment-option-con .preferred-paymt-card .chmpn-button-sec button.chmpn-pnk-btn {
  margin-top: 20px;
}

/*  */
.champion-payment-option-con .payment-option-box.payment-opt-card {
  margin-top: 4rem;
}

/*  */
.credit-debit-paymt-add-option {
  border-top: 1px dashed;
  border-color: #cbcbcb;
  margin-top: 20px;
  padding: 20px 0px;
  padding-bottom: 0px;
}

.champion-payment-option-con .payment-opt-card .credit-debit-add-pay-sec img.payment-opt-pay-icon {
  padding: 10px;
  cursor: pointer;
}

.credit-debit-add-pay-sec span {
  color: #c41d8e;
  font-weight: 600;
  font-size: 16px;
  padding-left: 12px;
}

/* more-paymt-outer */
.more-paymt-outer {
  margin-bottom: 3rem;
}

.more-paymt-outer .payment-opt-card .accordion .accordion-item {
  border: none;
}

.more-paymt-outer .payment-opt-card .accordion .accordion-item .accordion-header .accordion-button {
  padding: 0px;
}

.champion-payment-option-con .more-paymt-outer .payment-opt-card .accordion .accordion-item .accordion-body {
  padding: 0px;
}

.champion-payment-option-con .upi-pymt.accordion-item {
  padding: 20px 0px;
}

.champion-payment-option-con .credit-debit-paymt-add-option .credit-debit-add-pay-sec {
  display: flex;
  align-items: center;
}

.champion-payment-option-con .upi-pymt.accordion-item p.accordion-button {
  color: #c41d8e !important;
  font-size: 18px;
}

.champion-payment-option-con .payment-opt-card .wallet-pymt.accordion-item {
  padding: 20px 0px;
  border-top: 1px dashed;
  border-bottom: 1px dashed;
  border-color: #cbcbcb;
}

.champion-payment-option-con .payment-opt-card .netbanking-pymt.accordion-item {
  padding-top: 20px;
}

/* common css */
.champion-payment-option-con .payment-opt-card-outer {
  margin-top: 3rem;
}

.champion-payment-option-con .payment-opt-card {
  padding: 20px 26px;
  margin: 20px 0px;
}

.champion-payment-option-con p.payment-opt-hdng-main {
  font-weight: 600;
  font-size: 22px;
  color: #101010;
}

.champion-payment-option-con .payment-opt-card {
  border: none;
  border-radius: 10px;
  background-color: #ffff;
}

.champion-payment-option-con .payment-opt-card .form-check {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
}

.champion-payment-option-con .payment-opt-card .form-check label {
  align-items: center;
  display: flex;
  width: 100%;
  cursor: pointer;
}

.champion-payment-option-con .payment-opt-card .form-check label p {
  margin: 0px;
  color: #101010;
  font-weight: 600;
  font-size: 16px;
}

.champion-payment-option-con .payment-opt-card .form-check label span {
  color: #8a8a8a;
  font-weight: 500;
}

/*  */
.champion-payment-option-con .payment-opt-card div.imgIconOuter {
  padding: 10px;
  border-radius: 50%;
  background-color: #fff;
  min-width: 80px;
  max-width: 80px;
  height: 80px;
  box-shadow: 1px 1px 6px rgb(0 0 0 / 25%);
}

.champion-payment-option-con .payment-opt-card img.payment-opt-pay-icon {
  padding: 8px;
  width: 100%;
  height: 100%;
}

.champion-payment-option-con .payment-opt-card .pay-text-area {
  padding-left: 14px;
}

/*  */
.champion-payment-option-con .payment-opt-card .payment-opt-paymt {
  display: flex;
  justify-content: space-between;
}

.champion-payment-option-con .payment-opt-card .payment-opt-paymt p {
  margin: 0px 30px;
  font-weight: 500;
  font-size: 16px;
}

/*  */
.champion-payment-option-con .payment-opt-card .form-check {
  margin: 12px 0px;
}

.champion-payment-option-con .payment-opt-card .accordion-body .form-check {
  margin: 20px 0px;
}

.champion-payment-option-con .payment-opt-card input[type="radio"]:checked {
  background-color: #c41d8e !important;
  border-color: #c41d8e !important;
}

.champion-payment-option-con .payment-opt-card .form-check-input:focus {
  box-shadow: 0 0 0 0.25rem #c41d8f35;
  border-color: #c41d8e !important;
}

.champion-payment-option-con .payment-opt-card .accordion-button::after {
  transform: rotate(0deg) !important;
  /* background-image: url("../../../src/skin/ChampionsNewImg/pay-acc-down-arrow.png"); */
  background-size: 14px;
}

.champion-payment-option-con .payment-opt-card .accordion-button:not(.collapsed)::after {
  transform: rotate(0deg) !important;
  /* background-image: url("../../../src/skin/ChampionsNewImg/pay-acc-up-arrow.png"); */
}

/*  */
.blue-dot-img-mb {
  width: 7px;
  margin-left: 4px;
  margin-right: 4px;
}

.cstm-card-title {
  position: absolute;
  top: 0;
  top: 18px;
  text-align: center;
  display: flex;
  font-weight: bold;
  color: #fff;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 22px;
}

.experientialcards button.chmpn-pnk-btn {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAPCAYAAAD+pA/bAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACCSURBVHgBtZCBCYAgEEX/CG1QozRCG3QbOaojXCdowYXpefbgQETfQ4EMM28yC5wkR3LpzcAPJwZJd2Vi9oSyufEbcyTLNWt5UvREKnK+v1wWVDnQjHzISR80R7rlIxGz3BIZlvdE3PKOiF9ujBA8NCKEGVQihJmoCOEPRHzI7JY7F17KNbQxXzV2AAAAAElFTkSuQmCC);
  background-position: 90% 54%;
  background-repeat: no-repeat;
  background-size: 12px;
}

.experientialcards .dropdown-menu {
  transform: translate3d(0px, 60px, 0px) !important;
}

.experientialcards .slct-drop-menu.dropdown-menu li a.dropdown-item {
  color: #c41d8e;
  font-weight: 500;
  padding: 5px 8px;
}

.experientialcards .slct-drop-menu.dropdown-menu li a.dropdown-item:hover {
  background-color: #ffedf9;
}

.competitions-content-slider .competitions-item .experiential-cards {
  align-items: center;
}

.card-banner-data {
  position: absolute;
  top: 27%;
  left: 6%;
}

.age-bar {
  background: #fff;
  padding: 4px 18px;
  font-size: 20px;
  font-weight: 700;
  border-radius: 20px;
}

.total-activities {
  font-size: 20px;
  padding: 8px 0px;
  font-weight: 700;
  color: #fff;
}

.competitions-content-slider:hover .slick-list {
  overflow-x: scroll;
}

.chmp-post-slider-height-comp {
  height: 392px
}

.chmp-post-slider-height-exp {
  height: 413px
}

@media (max-width: 480px) {
  .chmp-post-slider-height-comp {
    height: 322px
  }

  .chmp-post-slider-height-exp {
    height: 341px;
  }
}

/* .chmp-post-slider:hover{
  overflow-x: scroll;
}
.champion-landing-conten .slick-list:hover {
  overflow-x: scroll;
} */
/*  */
/* resposive */
@media screen and (max-width: 1180px) {
  .champion-payment-option-con .chmpn-container {
    padding: 0px 26px;
  }
}

@media screen and (max-width: 640px) {
  .champion-payment-option-con .payment-option-box.payment-opt-card {
    margin-top: 3rem;
  }

  .champion-payment-option-con .payment-opt-card-outer {
    margin-top: 2rem;
  }

  .champion-payment-option-con p.payment-opt-hdng-main {
    font-size: 16px;
  }

  .champion-payment-option-con .payment-opt-card {
    padding: 24px 20px;
    margin: 16px 0px;
  }

  .champion-payment-option-con .paymt-box-event-reg-pay p {
    font-size: 14px;
  }

  .champion-payment-option-con .payment-opt-card div.imgIconOuter {
    min-width: 50px;
    max-width: 50px;
    height: 50px;
  }

  .champion-payment-option-con .payment-opt-card img.payment-opt-pay-icon {
    padding: 0px;
  }

  .champion-payment-option-con .payment-opt-card .credit-debit-add-pay-sec img.payment-opt-pay-icon {
    padding: 4px;
  }

  .champion-payment-option-con .payment-opt-card .form-check label p {
    font-size: 14px;
  }

  .champion-payment-option-con .payment-opt-card .payment-opt-paymt p {
    font-size: 14px;
  }

  .champion-payment-option-con .payment-opt-card .form-check label span {
    font-size: 12px;
  }

  /*payment search box */
  .select-anther-bnk-outer .select-anther-bnk-sec,
  .select-anther-bnk-outer .search-list-box {
    width: 100%;
  }

  .select-anther-bnk-outer .chmp-select-bnk-input {
    padding: 10px 8px;
  }

  .select-anther-bnk-outer .select-anther-bnk-sec .btn.chmpn-pnk-btn {
    padding: 10px 16px;
  }

  .select-anther-bnk-outer .search-list-box ul li {
    margin-bottom: 8px;
  }

  .select-anther-bnk-outer .search-list-box ul li .btn.bnk-list-item {
    padding: 10px 6px;
    font-size: 14px;
  }
}

@media screen and (max-width: 440px) {
  .champion-payment-option-con .preferred-paymt-card .chmpn-button-sec button.chmpn-pnk-btn {
    padding: 4px 60px;
  }

  .champion-payment-option-con .payment-opt-card .form-check {
    margin: 6px 0px;
  }

  .champion-payment-option-con .payment-opt-card .accordion-body .form-check {
    margin: 12px 0px;
  }

  .pay-text-area {
    width: 70%;
  }

  /*payment search box */
  .select-anther-bnk-outer .select-anther-bnk-sec,
  .select-anther-bnk-outer .search-list-box {
    width: 100%;
  }

  .select-anther-bnk-outer .chmp-select-bnk-input {
    padding: 8px 6px;
  }

  .select-anther-bnk-outer .select-anther-bnk-sec .btn.chmpn-pnk-btn {
    padding: 8px 16px;
  }

  .select-anther-bnk-outer .search-list-box ul li {
    margin-bottom: 6px;
  }

  .select-anther-bnk-outer .search-list-box ul li .btn.bnk-list-item {
    padding: 8px 4px;
    font-size: 14px;
  }
}

/*  */
.select-anther-bnk-outer .accordion .netbanking-pymt.accordion-item {
  padding-top: 0px;
}

.select-anther-bnk-outer .accordion-header .form-check {
  margin: 0px !important;
}

.select-anther-bnk-sec {
  width: 80%;
  margin: 0px auto;
  padding-top: 2rem;
}

.select-anther-bnk-sec p {
  font-weight: 600;
  font-size: 16px;
}

.chmp-select-bnk-input {
  padding: 12px 16px;
  border-radius: 0px;
}

.select-anther-bnk-search-box {
  margin-top: 6px;
  display: flex;
}

.chmp-select-bnk-input:focus {
  box-shadow: none;
  border-color: #c41d8e;
}

.select-anther-bnk-sec .btn.chmpn-pnk-btn {
  padding: 8px 40px;
  border-radius: 0px;
}

.search-list-box {
  width: 80%;
  margin: 20px auto;
  max-height: 320px;
  overflow: auto;
  padding: 10px 10px;
}

.search-list-box::-webkit-scrollbar {
  background-color: #fff;
}

.search-list-box::-webkit-scrollbar-thumb {
  background: #d1d1d1 !important;
}

.search-list-box ul li .btn.bnk-list-item {
  color: #101010;
  font-size: 16px;
  font-weight: 600;
  width: -webkit-fill-available;
  text-align: start;
  padding: 12px 16px;
  border: 1px solid transparent;
  cursor: pointer;
}

.search-list-box ul li .bnk-list-item:hover {
  box-shadow: 0px 0px 4px 1px #d1d1d1;
}

.search-list-box ul li .btn-check:focus,
.search-list-box ul li .btn-check:focus+.btn {
  box-shadow: none;
}

.search-list-box ul li input.btn-check:checked+label {
  border: 1px solid #c41d8e;
}

/* narc 2022 */
.narc-2022-crousel-cstm {
  display: flow-root;
}

.chmp-narc-2022 {
  display: flex;
  justify-content: space-between;
  margin-top: 5rem;
}

.chmp-narc-2022 p.free-prtcipnt-text {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #393939;
  margin-top: 10px;
  white-space: pre-line;
}

.narc-2022-crousel-cstm .competitions-content-slider {
  padding-left: 0px;
  padding-right: 0px;
  display: flow-root;
}

.narc-2022-crousel-cstm select.chmpn-custom-selecter {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAPCAYAAAD+pA/bAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAD3SURBVHgBpZDLDYJAEIZn8ZWYaCxAzLZhvGgHdqCWgDbgzYMPShArIUESysBoAcDZIGayYAjCMqtfwmNnZr8/uwApHt9xe7AdwJ+gA13ZWsOXOzLNOO74rV4/8EanBfwI7m32+j66XP1oYo3Z3OStOPHzgw0Gy/F9fQEFUB4nYOVr3Wab4wnC9PmAgyonKZMjUfCMtNnNCF+QGMUmNaRKziBZzUIjZFnB0Y9LDdi5OCi7Lpl88thY4j+HSghF/hVADaHKSwPqQvBLlYt6BVUhZVTJRU8CJUQmF/0aZCF1cjFDoCyEIhdzRPIhVLkyjn6YX4f7qcqeNxNnkqewBpsfAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: 90% 54%;
}

.narc-2022-crousel-cstm .btn.chmpn-pnk-btn {
  padding: 8px 50px;
  border: none;
  background-color: #c41d8e;
  color: #ffff;
  font-weight: 600;
  border-radius: 26px;
}

.narc-2022-crousel-cstm .competitions-content-slider .competitions-item a.chmp-post-nav {
  cursor: pointer;
  padding: 0px;
}

.champion-landing-conten .allbooking {
  margin-top: 3rem;
}

/*  */
.chmpn-select-dropbtn {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 0%;
  left: 50%;
  /* min-width: 168px; */
}

.chmpn-select-dropbtn button.chmpn-pnk-btn {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAPCAYAAAD+pA/bAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACCSURBVHgBtZCBCYAgEEX/CG1QozRCG3QbOaojXCdowYXpefbgQETfQ4EMM28yC5wkR3LpzcAPJwZJd2Vi9oSyufEbcyTLNWt5UvREKnK+v1wWVDnQjHzISR80R7rlIxGz3BIZlvdE3PKOiF9ujBA8NCKEGVQihJmoCOEPRHzI7JY7F17KNbQxXzV2AAAAAElFTkSuQmCC);
  background-position: 90% 54%;
  background-repeat: no-repeat;
  background-size: 12px;
}

.chmpn-select-dropbtn .slct-drop-menu.dropdown-menu li {
  margin: 0px;
}

.chmpn-select-dropbtn .slct-drop-menu.dropdown-menu li a.dropdown-item {
  color: #c41d8e;
  font-weight: 500;
  padding: 5px 8px;
}

.chmpn-select-dropbtn .slct-drop-menu.dropdown-menu li a.dropdown-item:hover {
  background-color: #ffedf9;
}

/*  */
@media screen and (max-width: 360px) {
  .champion-landing-slider.champion-landing-content .btn.chmpn-white-btn {
    padding: 5px 12px;
    font-size: 11px;
  }

  .champion-landing-slider.champion-landing-content .btn.chmpn-pnk-btn {
    padding: 5px 12px;
    font-size: 11px;
  }
}

@media screen and (min-width: 361px) and (max-width: 420px) {
  .champion-landing-slider.champion-landing-content .btn.chmpn-white-btn {
    font-size: 12px;
    padding: 5px 12px;
    font-size: 11px;
  }

  .champion-landing-slider.champion-landing-content .btn.chmpn-pnk-btn {
    padding: 5px 12px;
    font-size: 11px;
  }

  .champ-detail .banner .banner-slot .rule-content {
    top: 45% !important;
  }
}

/* / -----------------TEAM FORM------------------- / */
@media screen and (max-width: 360px) {
  .champion-landing-content .chmpn-team-register-bnnr {
    margin-bottom: 44rem;
  }

  .champion-landing-content .chmpn-individual-register-bnnr {
    margin-bottom: 31rem;
  }
}

@media screen and (max-width: 600px) {
  .chmpn-team-register-bnnr {
    margin-bottom: 42rem;
  }

  /* .chmpn-individual-register-bnnr {
    margin-bottom: 29rem;
  } */
  .mb-sm-29 {
    margin-bottom: 25rem;
  }

  .champion-register-banner .chmpn-landing-bnnr-block .team-register .formfram {
    position: absolute;
    top: 0px;
  }

  .champion-register-banner .chmpn-landing-bnnr-block .team-register .formfram .formSection {
    margin-top: 110px;
  }

  .paticipent.chmp-narc-2022 .free-prtcipnt-text {
    margin: 0px !important;
    font-size: 15px;
  }

  .paticipent.chmp-narc-2022 .chmpn-register-select-btn {
    margin-top: 10px !important;
  }

  .champion-landing-slider .slick-next {
    display: none !important;
  }
}

@media screen and (min-width: 601px) and (max-width: 768px) {
  .champion-landing-content .chmpn-team-register-bnnr {
    margin-bottom: 41rem;
  }

  .chmpn-individual-register-bnnr {
    margin-bottom: 24rem;
  }

  .champion-register-banner .chmpn-landing-bnnr-block .team-register .formfram {
    position: absolute;
    top: 0;
  }

  .champion-landing-slider .slick-next {
    display: none !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 900px) {
  .champion-landing-content .chmpn-team-register-bnnr {
    margin-bottom: 36rem;
  }

  .chmpn-individual-register-bnnr {
    margin-bottom: 13rem;
  }

  .champion-register-banner .chmpn-landing-bnnr-block .team-register .formfram {
    position: absolute;
    top: 10px;
  }

  .champion-landing-slider .slick-next {
    display: none !important;
  }
}

@media screen and (min-width: 901px) and (max-width: 1024px) {
  .champion-landing-content .chmpn-team-register-bnnr {
    margin-bottom: 34rem;
  }

  .champion-register-banner .chmpn-landing-bnnr-block .team-register .formfram {
    position: absolute;
    top: 30px;
  }

  .champion-landing-slider .slick-next {
    display: none !important;
  }
}

@media screen and (min-width: 901px) and (max-width: 1025px) {
  .chmpn-individual-register-bnnr {
    margin-bottom: 10rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .champion-landing-content .chmpn-team-register-bnnr {
    margin-bottom: 6.5rem;
  }

  .team-register .formfram .formSection {
    margin-left: 18px;
    margin-top: 4px;
  }
}

/* / -----------------INDIVIDUAL FORM------------------- / */

@media screen and (max-width:360px) {
  .chmpn-register-content .narcreg-formBanner .form-frame {
    position: absolute;
    top: 0px;
  }

  .chmpn-register-content .narcreg-formBanner .form-frame .formSection {
    margin-top: 80px;
    padding: 20px 10px;
  }

  .chmpn-register-content .narcreg-formBanner .form-frame .formSection .head .title {
    font-size: 22px;
  }
}

@media screen and (min-width:361px) and (max-width: 465px) {

  .chmpn-register-content .narcreg-formBanner .form-frame {
    position: absolute;
    top: 0px;
  }

  .chmpn-register-content .narcreg-formBanner .form-frame .formSection {
    margin-top: 80px;
    padding: 20px 10px;
  }

  .chmpn-register-content .narcreg-formBanner .form-frame .formSection .head .title {
    font-size: 22px;
  }
}

@media screen and (min-width: 466px) and (max-width: 600px) {
  .chmpn-register-content .narcreg-formBanner .form-frame {
    position: absolute;
  }
}

@media screen and (min-width: 601px) and (max-width: 768px) {
  .chmpn-register-content .narcreg-formBanner .form-frame {
    position: absolute;
  }
}

@media screen and (min-width: 769px) and (max-width: 840px) {
  .chmpn-register-content .narcreg-formBanner .form-frame {
    position: absolute;
  }
}

@media screen and (min-width: 841px) and (max-width: 1025px) {
  .chmpn-register-content .narcreg-formBanner .form-frame {
    position: absolute;
  }
}

@media screen and (min-width: 1026px) and (max-width: 1200px) {
  .chmpn-register-content .narcreg-formBanner .form-frame {
    position: absolute;
  }
}

/*  */
@media screen and (max-width: 768px) {
  .container-kidex.ns-quiz .heads-title .question-btn {
    display: none !important;
  }
}

@media screen and (max-width: 880px) {
  .container-kidex.ns-quiz .heads-title .question-btn {
    top: 100%;
    margin-top: 10px;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .npaquiz-reg-form .narcreg-formBanner .form-frame .formSection {
    position: absolute;
    bottom: 30px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .npaquiz-reg-form .narcreg-formBanner .bannerImg img {
    display: block;
  }
}

@media screen and (min-width: 1240px) and (max-width: 1460px) {
  .npaquiz-reg-form .narcreg-formBanner .form-frame .formSection {
    margin-top: 30px;
  }
}