.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#training .swiper-slide {
  background: transparent;
}

.swiper-button-next,
.swiper-button-prev {
  background-color: #fff;
  border-radius: 200px;
  right: 10px;
  height: 20px;
  width: 20px;
  padding: 18px;
  color: #000 !important;
  fill: black !important;
  stroke: black !important;
  box-shadow: 0px 2px 9px 1px rgb(196 29 142 / 25%);
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 16px;
  font-weight: bold;
}