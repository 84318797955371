/* Tablet styling (768px - 1023px) */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .how-it-work-img {
    width: 90% !important;
  }
}

/* Mobile styling (up to 767px) */
@media only screen and (max-width: 767px) {
  .how-it-work-img {
    width: 90% !important;
  }
}