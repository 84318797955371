.section-height{
    height: 100vh;
}
.side-vector{
    background: url(../../Assets/images/login-vector.svg) 0% 75% no-repeat;
    background-size: 40%;
}
.right-side-vector{
    background: linear-gradient(90deg, #A3C6FF 0%, #E3EEFF 100%);
    position: relative;
}
.right-side-vector:after{
    content:'';
    position: absolute;
    top:0px;
    right:0px;
    background-image: url(../../Assets/images/login-right-vector.svg); 
    background-position:top right;
    background-repeat: no-repeat;
    background-size: 35%;
    width: 100%;
    height: 100%;
}
.login-title{
    font-size: 20px;
    font-weight: 700;
    line-height: 35.21px;
    text-align: center;
    color: #000000;
}
.login-form label{
    font-size: 13px;
    font-weight: 500;
    line-height: 130%;
    text-align: left;
    color: #000000;
    display: block;
    margin-bottom: 2px;
}
.login-form .form-control{
    border: 0.35px solid #000000;
    font-family: Inter;
    font-weight: 300;
    line-height: 130%;
    text-align: left;
}

.login-slide{
    width: 200px;
}
.login-slider-title{
    font-size: 24px;
    font-weight: 600;
    line-height: 29.75px;
    text-align: center;
    color: #1D5BBE;
}
.login-slide-content{
    padding: 50px 20px 20px 20px;
    border-radius: 20px;
    margin-top: -40px;
    margin-bottom: -22px;
    background: url(../../Assets/images/hexa.svg)  top center no-repeat;
    background-size: cover;
    min-height: 240px;
    
}
.login-slide-content p{
    font-size: 14px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    color: #676767;
}
.login-slide-content p b{
    font-weight: 700;
    display: block;
}

.up-layer{
    position: relative;
    z-index: 1;
}