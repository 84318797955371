.multi-step-kidex-login #regForm {
  background-color: #ffffff;
  margin: 0px auto;
  font-family: Raleway;
  padding: 40px;
  border-radius: 10px;
}

.multi-step-kidex-login h1 {
  text-align: center;
}

.multi-step-kidex-login input {
  padding: 10px;
  width: 100%;
  font-size: 17px;
  font-family: Raleway;
  border: 1px solid #aaaaaa;
}

.multi-step-kidex-login input.invalid {
  background-color: #ffdddd;
}

.multi-step-kidex-login .tab {
  display: none;
}

/* .multi-step-kidex-login button {
  background-color: #4caf50;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  font-size: 17px;
  font-family: Raleway;
  cursor: pointer;
}

.multi-step-kidex-login button:hover {
  opacity: 0.8;
} */

.multi-step-kidex-login #prevBtn {
  background-color: #bbbbbb;
}

.multi-step-kidex-login .step {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbbbbb;
  border: none;
  border-radius: 50%;
  display: inline-block;
  opacity: 0.5;
}

.multi-step-kidex-login .step.active {
  opacity: 1;
}

.multi-step-kidex-login .step.finish {
  background-color: #4caf50;
}

.multi-step-kidex-login .all-steps {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.multi-step-kidex-login .thanks-message {
  display: none;
}

.multi-step-kidex-login .container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.multi-step-kidex-login .container input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.multi-step-kidex-login .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

.multi-step-kidex-login .container:hover input~.checkmark {
  background-color: #ccc;
}

.multi-step-kidex-login .container input:checked~.checkmark {
  background-color: #2196f3;
}

.multi-step-kidex-login .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.multi-step-kidex-login .container input:checked~.checkmark:after {
  display: block;
}

.multi-step-kidex-login .container .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.multiform-submit-button {
  background-color: #c41d8e !important;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff !important;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: none;
}

.multiform-submit-button:hover {
  background-color: #d136a0;
  color: #ffffff;
}

.multi-step {
  padding: 40px 50px;
}

.kidex-phone input,
.kidex-login input {
  height: 46px;
  font-family: "Montserrat";
}

.otp-input-section input[type="tel"] {
  width: 46px !important;
}

/* //// */
body {
  font-family: "Montserrat", sans-serif !important;
}

.banner-sec {
  height: 280px;
  width: 100%;
  /* background-image: url("./../../../skin/img/banner.png"); */
  position: relative;
}

.banner-title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.banner-title img {
  height: auto;
  max-width: 350px;
  max-height: 100px;
  width: 100%;
}

.select-users {
  position: absolute;
  bottom: 0%;
  transform: translate(-50%, 50%);
  width: 300px;
  left: 50%;
}

.form-select.select-users-option {
  text-align: center;
  font-weight: 600;
  padding: 10px 10px !important;
  border-radius: 62px !important;
  box-shadow: 0px 2px 2px #c7c4c4;
  border: none;
  background-position: right 3.75rem center !important;
  /* background-image: url("./../../../skin/img/drop-icon.png"); */
  background-image: unset;
  background-size: 14px;
  font-size: 19px;
  width: 90%;
}

.select-users::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  position: absolute;
  top: 22px;
  right: 62px;
}

.form-select.select-users-option:focus {
  outline: none;
  box-shadow: 0px 2px 2px #c7c4c4;
  border: none;
}

.tab-header {
  padding: 20px 0px;
  margin-top: 30px;
}

.tab-header .nav-tabs {
  border: none;
  border-bottom: 1px solid #ddd;
}

.tab-header ul li {
  position: relative;
  padding: 12px 0px;
  margin: 0px 20px;
}

.tab-header ul li button {
  color: #282828;
  padding: 0px;
}

.tab-header ul li button.active {
  color: #08316e !important;
  font-weight: 400;
}

.tab-header ul li button.active::before {
  /* border: 5px solid #08316e; */
  content: " ";
  height: 5px;
  width: 100%;
  position: absolute;
  bottom: -2px;
  background-color: #08316e;
  border-radius: 5px;
}

.tab-header ul li:first-child button {
  padding-left: 0px;
}

.tab-post-sec .post-banner {
  padding: 20px 0px;
}

.post-banner img {
  width: 100%;
  height: 350px;
  border-radius: 8px;
}

.tab-post-sec {
  border-bottom: 3px solid #ddd !important;
}

/* common css */
.post-banner img {
  width: 100%;
  border-radius: 8px;
  height: auto;
  object-fit: cover;
  max-height: 400px;
}

.box-outer-sec {
  padding: 20px 0px;
  border-top: 3px solid #dddd;
  border-bottom: 3px solid #ddd;
}

.sec-heading h5 {
  position: relative;
  width: max-content;
  padding-right: 20px;
}

.sec-heading h5::before {
  content: " ";
  height: 5px;
  width: -webkit-fill-available;
  position: absolute;
  bottom: -8px;
  background-color: #08316e;
  border-radius: 5px;
}

/* common css */

.multi-step-kidex-login .details-list-sec {
  padding: 35px 0px;
}

.multi-step-kidex-login .details-list-sec ul li::before {
  content: " ";
  height: 9px;
  width: 10px;
  border-radius: 50%;
  background-color: #cd00a0;
  margin-right: 20px;
}

.multi-step-kidex-login .multi-step-kidex-login .details-list-sec ul li {
  line-height: 36px;
  color: #333333;
  display: flex;
  align-items: baseline;
}

.multi-step-kidex-login .grades-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.multi-step-kidex-login .grades-icons img {
  max-height: 80px;
  height: auto;
  max-width: 80px;
}

.multi-step-kidex-login .grades-icons img:first-child {
  height: auto;
  max-height: 100px;
  width: 100px !important;
}

/* second tab */
.multi-step-kidex-login .post-card {
  background-color: #e6e7e8;
  border-radius: 8px;
  margin: 25px 0px;
}

.multi-step-kidex-login .multi-step-kidex-login .post-card-content {
  padding: 20px 20px;
}

.multi-step-kidex-login .post-card-content h6 {
  color: #282828;
  font-weight: 600;
}

@media only screen and (max-width: 420px) {
  .multi-step-kidex-login .tab-header ul li {
    margin: 0px auto;
  }

  .multi-step-kidex-login .grades-icons img:first-child {
    max-width: 16%;
  }

  .multi-step-kidex-login .grades-icons img {
    max-width: 16%;
  }
}

.multi-step-kidex-login .auth-button {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  background: #fff;
  border: 1px solid #000;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 10px;
}

.multi-step-kidex-login .auth-button p {
  color: #000;
  margin: 0px;
  font-weight: 600;
}

.multi-step-kidex-login .auth-button img {
  width: 24px;
}

.multi-step-kidex-login .auth-button-outer {
  margin-top: 26px;
}

.multi-step-kidex-login .form-devider {
  position: relative;
}

.multi-step-kidex-login .form-devider p {
  margin: 0px;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  background: #fff !important;
  padding: 10px;
  transform: translate(-33%, -52%);
  z-index: 9;
}

.multi-step-kidex-login .other-auth-options.btns {
  padding: 10px 50px;
}

.multi-step-kidex-login p.otp-description {
  margin: 28px 0px 0px;
  text-align: center;
}

@media (max-width: 480px) {
  .multi-step-kidex-login input {
    font-size: 14px !important;
    text-align: center;
  }

  .otp-input-section input[type="tel"] {
    margin: 0px 4px !important;
    width: 34px !important;
  }

  .otp-input-section div {
    width: 100% !important;
  }

  /* pROFILE */
  .banner-sec {
    height: 130px;
  }

  /* Champions */
  .list-activities .background-image img {
    height: 130px !important;
  }

  .select-users {
    top: -90px;
  }

  .list-activities .nav-tabs button {
    width: 50%;
    margin: 0px;
  }

  .allbooking .select-users {
    top: -170px;
  }

  .top-tags button {
    width: 100% !important;
    max-width: 100% !important;
    margin: 10px 0px !important;
  }

  .top-tags {
    display: block !important;
    margin: 0px !important;
    padding: 20px 0px !important;
  }

  .competetion-data {
    width: 94% !important;
  }

  .books-section .col-md-4 {
    margin-bottom: 22px;
  }

  .allbooking .formtop-banner img {
    height: 710px;
  }

  .allbooking .profile-name {
    margin-top: 60px;
  }

  .profile-name {
    z-index: 1 !important;
  }

  .background-image {
    z-index: -9999 !important;
  }

  .allbooking .narcreg-formBanner .form-frame .formSection {
    margin-top: 115px;
  }

  .all-bookings .booking-tab-btn {
    margin: 6px 0px !important;
  }

  .multi-step-kidex-login input {
    text-align: center;
  }

  .all-booking .tab-main2 {
    overflow-y: hidden;
    overflow-x: scroll;
    width: 100%;
  }

  .all-booking .tab-main2 div#nav-tab {
    width: 200vh;
  }

  .all-booking .container {
    width: 100%;
  }

  /* .activity-details .tab-header ul li button.active::before {
    left: 0;
  }
  .activity-details .tab-header ul li {
    padding: 12px 0px;
    margin: 0px;
    width: 50%;
  } */
  .activity-details .select-users {
    top: 90px;
  }

  .activity-details .sec-heading h5::before {
    content: " ";
    height: 5px;
    width: -webkit-fill-available;
    position: absolute;
    bottom: -14px;
    background-color: #08316e;
    border-radius: 5px;
  }

  .activity-details .details-list-sec {
    padding: 26px 0px;
  }

  .activity-details .grades-icons img:first-child {
    max-width: 50%;
    height: 50px;
  }

  .activity-details .activity-guidlines .nav-tabs {
    display: flex;
    justify-content: start !important;
  }

  .activity-guidlines .nav-tabs .nav-link {
    margin-bottom: 20px;
  }

  .activity-details .banner-title img {
    max-width: 200px;
  }
}

/*  */
@media (max-width: 768px) {
  .activity-details .content-sec .tab-header {
    overflow: auto;
  }

  .activity-details .content-sec .tab-header ul.nav {
    flex-wrap: unset;
    width: max-content;
  }
}