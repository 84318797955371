@import "https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cstm-mt-2 {
  margin-top: .5rem !important
}

:root {
  --white: #ffffff;
  --blue: #2B5CAA;
  --yellow: #FFC700;
  --yellow-light-bg: #FFF4CD;
  --green: #38B43D;
  --green-light-bg: #CFFFD1;
}

.quicksand {
  font-family: "Quicksand", sans-serif;
}

.inter {
  font-family: "Inter", sans-serif;
}

.nunito {
  font-family: "Nunito", sans-serif;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.site-btn {
  font-size: 15px;
  font-weight: 600;
  line-height: 130%;
  text-align: center;
  padding: 10px 25px;
  border-radius: 30px;
  background: transparent;
}

.site-btn:hover {
  text-decoration: none;
}

body .blue-btn {
  color: var(--white);
  border: 2px solid var(--blue);
  background: var(--blue);
}

body .border-btn {
  color: var(--blue);
  border: 2px solid var(--blue);
}

body .check-btn {
  background: var(--blue);
  background-image: url(../public/images/check.svg);
  background-position: 10px;
  background-repeat: no-repeat;
  background-size: 15px;
  padding-left: 30px;
  color: var(--white);
  border-color: transparent;

}

.status-btn {
  font-family: "Nunito", sans-serif;
  font-size: 14px;
  font-weight: 700;
  border-radius: 9px;
  line-height: 100%;
  text-align: center;
  padding: 10px 25px;
  border: 0px;
}

.not-reviewed {
  background: var(--yellow-light-bg);
  color: var(--yellow);
}

.reviewed {
  background: var(--green-light-bg);
  color: var(--green);
}

.title-18 {
  font-size: 18px;
  font-weight: 500;
  line-height: 100%;
  text-align: left;
  color: #414141;
  margin: 0px;
}

.title-16 {
  font-size: 16px !important;
}

@media screen and (max-width:580px) {
  .site-btn {
    font-size: 11px;
  }

  .title-18 {
    font-size: 12px;
  }

  .status-btn {
    font-size: 11px;
  }
}

/*  tabs */
.tabs-compititions .tab {
  border: 1px solid #DEDEDE;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 33.33px;
  text-align: center;
  padding: 6px 30px;
  color: #2B2B2B;
  border-radius: 6px;
  flex: 1;
  cursor: pointer;
}

.tabs-compititions .tab.active {
  background: #6895DC;
  color: var(--white);
}

@media screen and (max-width:580px) {
  .tabs-compititions .tab {
    font-size: 12px;
    padding: 7px 20px;
    line-height: normal;
  }

}

/* Tabs End */

/* Pagination Start */

.pagination {
  margin: 0px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
}

.pagination li a {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: inline-flex;
  justify-content: center;
  line-height: 40px;
  border: 1.6px solid #989898;
  font-family: "Nunito", sans-serif;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  color: #989898;
  margin: 0px 10px;
}

.pagination li.active a {
  color: #323232;
  border-color: #323232;
}

.pagination li.dots {
  margin: 0px 20px-;
}

.pagination li.page_prev a,
.pagination li.page_next a,
.pagination li.page_first a,
.pagination li.page_last a {
  border-color: var(--blue);
  color: var(--blue);
  line-height: 37px;
}

.pagination li.page_prev a,
.pagination li.page_next a {
  background: url(../public/images/arrow.png) center center no-repeat;
  color: transparent;
  background-size: 22px;
}

.pagination li.page_next a {
  transform: rotate(180deg);
}

@media screen and (max-width:580px) {
  .pagination li a {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 13px;
    margin: 0px 5px;
  }

  .pagination li.page_prev a,
  .pagination li.page_next a,
  .pagination li.page_first a,
  .pagination li.page_last a {
    line-height: 26px;
  }
}

/* Pagination end */


/* Modal */

.popup-title {
  font-family: "Nunito", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 49.1px;
  text-align: left;
  color: #000000;
  margin: 0px;
}

.modal-para {
  font-family: "Nunito", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 130%;
  text-align: left;
  color: #4B4747;

}

@media screen and (max-width:580px) {
  .popup-title {
    font-size: 16px;
  }

  .modal-para {
    font-size: 12px;
  }
}

/* Modal End */


/* switch CheckBox */

.form-checkbox {
  display: flex;
  align-items: center;
}

.form-check-label {
  font-size: 14px;
  font-weight: 500;
  line-height: 120%;
  text-align: left;
  color: #858585;
  padding-left: 10px;
}

.form-check-input {
  border: 2px solid #AEAEAE !important;
  accent-color: #AEAEAE !important;
  width: 24px;
  height: 24px;
  border-radius: 11px;
  margin-top: 0px;
}

.form-check-input:checked {
  background-color: var(--blue);
  border-color: var(--blue);
}

.form-check-input:focus {
  box-shadow: unset;
}



.switch-checkbox {
  display: flex;
  align-items: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-checkbox .switch-btn {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #EDEDED;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}

.switch-checkbox .switch-btn:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  border-radius: 50%;
  background-color: #979797;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch-checkbox input:checked+.switch-btn {
  background-color: #EAF4FF;
}

.switch-checkbox input:checked+.switch-btn:before {
  background-color: var(--blue);
}


.switch-checkbox input:checked+.switch-btn:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

@media screen and (max-width:580px) {
  .form-check-label {
    font-size: 12px;
  }

  .switch {
    width: 56px;
    height: 28px;
  }

  .switch-checkbox .switch-btn:before {
    height: 20px;
    width: 20px;
  }
}

/* Switch Checkbox End */



@media screen and (min-width:768px) {
  .object-fit-none {
    object-fit: none !important;
  }
}