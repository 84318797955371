.narc-submission-form {
  float: left;
  width: 100%;
  position: relative;
  /* background-image: url("../../skin/img/narc-submission-with-texts.png"); */
  background-color: #f7a919;
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat
}

@media(max-width: 1024px) {
  .narc-submission-form {
    /* background-image: url("../../skin/img/narc-submission-with-text-mobile.png"); */
    background-position: initial;
    background-color: unset
  }
}

.narc-submission-form .formfram {
  float: left;
  width: 100%;
  padding-left: calc((100% - 1140px) / 2);
  padding-right: calc((100% - 1140px) / 2)
}

@media(max-width: 1024px) {
  .narc-submission-form .formfram {
    padding-left: 20px;
    padding-right: 20px
  }
}

@media(max-width: 768px) {
  .narc-submission-form .formfram {
    padding-left: 15px;
    padding-right: 15px
  }
}

.narc-submission-form .formfram .formSection {
  width: 445px;
  float: left;
  min-height: 475px;
  background-color: #fff;
  border-radius: 15px;
  padding: 20px 40px 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  box-shadow: 3px 7px 14px 0 rgba(114, 113, 180, .3)
}

@media(max-width: 1440px) {
  .narc-submission-form .formfram .formSection {
    margin-top: 28px;
    margin-bottom: 10px
  }
}

@media(max-width: 1368px) {
  .narc-submission-form .formfram .formSection {
    margin-top: 16px;
    margin-bottom: 10px
  }
}

@media(max-width: 1280px) {
  .narc-submission-form .formfram .formSection {
    margin-top: 10px;
    margin-bottom: 10px
  }
}

@media(max-width: 1024px) {
  .narc-submission-form .formfram .formSection {
    margin-top: 65px
  }
}

@media(max-width: 1024px) {
  .narc-submission-form .formfram .formSection {
    float: left;
    width: 100%;
    padding: 40px 51px;
    position: relative;
    line-height: 21px;
    margin-top: 250px;
    z-index: 1
  }
}

@media(max-width: 480px) {
  .narc-submission-form .formfram .formSection {
    padding: 20px 15px;
    margin-top: 150px
  }
}

.narc-submission-form .formfram .formSection .head {
  float: left;
  width: 100%
}

.narc-submission-form .formfram .formSection .head.center .title {
  text-align: center;
  font-weight: 700;
  text-transform: capitalize;
  position: relative
}

.narc-submission-form .formfram .formSection .head.center .title:after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 36%;
  width: 102px;
  height: 3px;
  background-color: #000
}

.narc-submission-form .formfram .formSection .head.center .subtext {
  text-align: justify
}

.narc-submission-form .formfram .formSection .head.eligible {
  margin-top: 60px
}

.narc-submission-form .formfram .formSection .head.eligible .subtext {
  font-size: 18px;
  text-align: center
}

.narc-submission-form .formfram .formSection .head .icon {
  float: left;
  width: 80px;
  margin-left: calc((100% - 80px) / 2);
  margin-bottom: 20px
}

.narc-submission-form .formfram .formSection .head .icon img {
  float: left;
  width: 100%
}

.narc-submission-form .formfram .formSection .head .title {
  float: left;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
  font-weight: 700;
  color: #181818
}

@media(max-width: 480px) {
  .narc-submission-form .formfram .formSection .head .title {
    font-size: 18px
  }
}

.narc-submission-form .formfram .formSection .head .typeInfo {
  float: left;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #181818;
  margin-top: 20px
}

.narc-submission-form .formfram .formSection .head .subtext {
  float: left;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  color: #181818;
  margin-top: 15px
}

.narc-submission-form .formfram .formSection .form {
  float: left;
  width: 100%;
  margin-top: 25px;
  position: relative
}

@media(max-width: 1112px) {
  .narc-submission-form .formfram .formSection .form {
    margin-top: 30px
  }
}

@media(max-width: 480px) {
  .narc-submission-form .formfram .formSection .form {
    margin-top: 19px
  }
}

.narc-submission-form .formfram .formSection .form.stepOne .formGroup label .star {
  color: red
}

.narc-submission-form .formfram .formSection .form.stepOne .submitMe {
  margin-top: 18px
}

@media(max-width: 1280px) {
  .narc-submission-form .formfram .formSection .form.stepOne .submitMe {
    margin-top: 6px
  }
}

.narc-submission-form .formfram .formSection .form.stepOne .pagenation {
  margin-top: 15px
}

.narc-submission-form .formfram .formSection .form.stepTwo {
  position: relative;
  margin-top: 30px
}

.narc-submission-form .formfram .formSection .form.stepTwo .formGroup {
  position: relative
}

.narc-submission-form .formfram .formSection .form.stepTwo .formGroup label .star {
  color: red
}

.narc-submission-form .formfram .formSection .form.stepTwo .formGroup .input.file-input {
  display: block;
  float: left;
  width: 100%;
  border: solid 1px #707070;
  border-radius: 5px;
  border-style: dashed;
  text-align: center;
  padding: 10px 0
}

.narc-submission-form .formfram .formSection .form.stepTwo .formGroup .input.file-input input {
  margin: 0px;
  width: 364px;
  height: 40px;
  cursor: pointer;
  font-size: 1px;
  filter: alpha(opacity=1);
  -moz-opacity: 0;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0px
}

.narc-submission-form .formfram .formSection .form.stepTwo .formGroup .input.file-input .file-img {
  float: left;
  width: 13%;
  padding: 0 10px;
  text-align: center
}

.narc-submission-form .formfram .formSection .form.stepTwo .formGroup .input.file-input .file-img img {
  display: inline-block;
  width: 100%
}

.narc-submission-form .formfram .formSection .form.stepTwo .formGroup .input.file-input .input-label {
  float: left;
  width: 86%;
  display: flex;
  margin-top: 10px
}

.narc-submission-form .formfram .formSection .form.stepTwo .formGroup .input.file-input .input-label .text {
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #181818;
  text-align: center;
  flex: 1
}

.narc-submission-form .formfram .formSection .form.stepTwo .formGroup .input.file-input .input-label .text:first-child {
  display: none
}

.narc-submission-form .formfram .formSection .form.stepTwo .formGroup .input.file-input .input-label .text:nth-child(2) {
  flex: 1.9
}

.narc-submission-form .formfram .formSection .form.stepTwo .formGroup .input.file-input .input-label .text.or {
  font-weight: normal;
  flex: .53
}

.narc-submission-form .formfram .formSection .form.stepTwo .formGroup .input.file-input .input-label .text.browse {
  color: #2b5caa;
  flex: 1.5
}

.narc-submission-form .formfram .formSection .form.stepTwo .formGroup ul#ul {
  margin: 0;
  padding: 0;
  margin-top: 10px;
  min-height: 93px
}

.narc-submission-form .formfram .formSection .form.stepTwo .formGroup ul#ul li {
  margin-bottom: 5px;
  display: block
}

.narc-submission-form .formfram .formSection .form.stepTwo .msg {
  position: absolute;
  right: 0;
  top: 40%
}

@media(max-width: 375px) {
  .narc-submission-form .formfram .formSection .form.stepTwo .msg {
    top: 43%
  }
}

.narc-submission-form .formfram .formSection .form.stepTwo .submitMe {
  margin-top: 23px
}

.narc-submission-form .formfram .formSection .form.stepTwo .pagenation {
  margin-top: 15px
}

.narc-submission-form .formfram .formSection .form.stepTwo .formGroup .input {
  display: block;
  width: 100%
}

@media(max-width: 1112px) {
  .narc-submission-form .formfram .formSection .form.stepTwo .formGroup .input {
    display: block
  }
}

.narc-submission-form .formfram .formSection .form.stepTwo .formGroup .input input {
  flex: 1;
  margin-right: 15px;
  border: 0;
  height: unset;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  text-align: center;
  background-color: #e1e1e1
}

.narc-submission-form .formfram .formSection .form.stepTwo .formGroup .input input.file-input {
  float: left;
  width: 100%;
  border: solid 1px #707070;
  border-radius: 5px;
  border-style: dashed;
  text-align: center;
  padding: 17px 0
}

.narc-submission-form .formfram .formSection .form.stepTwo .formGroup .input input.file-input input {
  border: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  height: 100%;
  cursor: pointer
}

.narc-submission-form .formfram .formSection .form.stepTwo .formGroup .input input.file-input .file-img {
  float: left;
  width: 100%;
  text-align: center
}

.narc-submission-form .formfram .formSection .form.stepTwo .formGroup .input input.file-input .file-img img {
  display: inline-block;
  width: 40px
}

.narc-submission-form .formfram .formSection .form.stepTwo .formGroup .input input.file-input .input-label {
  float: left;
  width: 100%
}

.narc-submission-form .formfram .formSection .form.stepTwo .formGroup .input input.file-input .input-label .text {
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #181818;
  text-align: center
}

.narc-submission-form .formfram .formSection .form.stepTwo .formGroup .input input.file-input .input-label .text.or {
  font-weight: normal
}

.narc-submission-form .formfram .formSection .form.stepTwo .formGroup .input input.file-input .input-label .text.browse {
  color: #2b5caa
}

.narc-submission-form .formfram .formSection .form.stepThree .submitMe {
  margin-top: 109px
}

.narc-submission-form .formfram .formSection .form.stepThree .formGroup .input .ui.checkbox input[disabled]~.box:after,
.narc-submission-form .formfram .formSection .form.stepThree .formGroup .input .ui.checkbox input[disabled]~label,
.narc-submission-form .formfram .formSection .form.stepThree .formGroup .input .ui.disabled.checkbox .box:after,
.narc-submission-form .formfram .formSection .form.stepThree .formGroup .input .ui.disabled.checkbox label {
  cursor: default !important;
  opacity: 1;
  color: #000;
  text-align: justify
}

.narc-submission-form .formfram .formSection .form.stepThree .formGroup .input .ui.checkbox label,
.narc-submission-form .formfram .formSection .form.stepThree .formGroup .input .ui.checkbox+label {
  color: #000
}

.narc-submission-form .formfram .formSection .form.stepThree .formGroup .input .field {
  float: left;
  width: 100%
}

.narc-submission-form .formfram .formSection .form.stepThree .formGroup .input i {
  font-size: 22px;
  width: 42px
}

.narc-submission-form .formfram .formSection .form.stepThree .msg {
  position: absolute;
  bottom: 25%;
  left: 0%
}

.narc-submission-form .formfram .formSection .form.stepThree .submitMe button i {
  float: right
}

.narc-submission-form .formfram .formSection .form.stepFour {
  margin-top: 60px
}

.narc-submission-form .formfram .formSection .form.stepFour .pagenation {
  margin-top: 200px
}

.narc-submission-form .formfram .formSection .form.stepFive {
  margin-top: 60px
}

.narc-submission-form .formfram .formSection .form.stepFive .submitMe {
  margin-top: 130px
}

.narc-submission-form .formfram .formSection .form.stepFive .formGroup .input .ui.search {
  width: 100%;
  border: solid 1px #707070
}

.narc-submission-form .formfram .formSection .form.stepFive .formGroup .input .ui.search .menu {
  max-height: 150px
}

.narc-submission-form .formfram .formSection .form.stepFive .pagenation {
  margin-top: 58px
}

.narc-submission-form .formfram .formSection .form.stepSix .submitMe button {
  height: 50px;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px
}

.narc-submission-form .formfram .formSection .form.stepSix .submitMe button.other {
  background-color: #fff;
  border: solid 1px #245ca2;
  color: #245ca2
}

.narc-submission-form .formfram .formSection .form.stepSeven {
  margin-top: 60px
}

.narc-submission-form .formfram .formSection .form.stepSeven .submitMe {
  margin-top: 80px
}

.narc-submission-form .formfram .formSection .form.stepPay {
  margin-top: 60px
}

.narc-submission-form .formfram .formSection .form.stepPay .pagenation {
  margin-top: 200px
}

.narc-submission-form .formfram .formSection .form.hurray .submitMe {
  margin-top: 80px
}

.narc-submission-form .formfram .formSection .form.hurray .submitMe button {
  height: 50px;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px
}

.narc-submission-form .formfram .formSection .form.hurray .submitMe button.other {
  background-color: #fff;
  border: solid 1px #245ca2;
  color: #245ca2
}

.narc-submission-form .formfram .formSection .form .accountType {
  float: left;
  width: calc(100% + 80px);
  margin-top: 0;
  margin-left: -40px
}

.narc-submission-form .formfram .formSection .form .accountType button {
  float: left;
  width: 100%;
  height: 70px;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: #fff;
  line-height: 65px;
  border: 0;
  cursor: pointer;
  background-color: #245ca2;
  border-top: solid 2px transparent;
  border-bottom: solid 2px transparent;
  box-shadow: 0 0 3px rgba(0, 0, 0, .3)
}

.narc-submission-form .formfram .formSection .form .accountType button.paid {
  background-color: #fff;
  color: #245ca2;
  border-top: solid 2px #245ca2;
  border-bottom: solid 2px #245ca2;
  margin-bottom: 20px
}

.narc-submission-form .formfram .formSection .form .accountPayment {
  float: left;
  width: calc(100% + 80px);
  margin-top: 0;
  margin-left: -40px
}

.narc-submission-form .formfram .formSection .form .accountPayment button {
  float: left;
  width: 100%;
  height: 70px;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: #fff;
  line-height: 65px;
  border: 0;
  cursor: pointer;
  box-shadow: 0 0 3px rgba(0, 0, 0, .3);
  padding: 0 25px
}

.narc-submission-form .formfram .formSection .form .accountPayment button .icon {
  float: left;
  width: 45px;
  margin-top: 15px
}

.narc-submission-form .formfram .formSection .form .accountPayment button .icon img {
  float: left;
  width: 100%
}

.narc-submission-form .formfram .formSection .form .accountPayment button span {
  float: right;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500
}

.narc-submission-form .formfram .formSection .form .accountPayment button.premium {
  background: #003474;
  background: -moz-linear-gradient(-45deg, #003474 1%, #3ab9c7 50%, #1a5aa7 100%);
  background: -webkit-linear-gradient(-45deg, #003474 1%, #3ab9c7 50%, #1a5aa7 100%);
  background: linear-gradient(135deg, #003474 1%, #3ab9c7 50%, #1a5aa7 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#003474", endColorstr="#1a5aa7", GradientType=1);
  margin-bottom: 20px
}

.narc-submission-form .formfram .formSection .form .accountPayment button.regular {
  background: #b2a616;
  background: -moz-linear-gradient(-45deg, #b2a616 1%, #c7b917 50%, #b2a616 100%);
  background: -webkit-linear-gradient(-45deg, #b2a616 1%, #c7b917 50%, #b2a616 100%);
  background: linear-gradient(135deg, #b2a616 1%, #c7b917 50%, #b2a616 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#b2a616", endColorstr="#b2a616", GradientType=1)
}

.narc-submission-form .formfram .formSection .form .verificationTo {
  float: left;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  color: #181818
}

.narc-submission-form .formfram .formSection .form .otpUpdate {
  float: left;
  width: 100%;
  margin-top: 40px
}

.narc-submission-form .formfram .formSection .form .otpUpdate .resend {
  float: left;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #245ca2;
  text-decoration: underline;
  cursor: pointer
}

.narc-submission-form .formfram .formSection .form .otpUpdate .change {
  float: left;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #245ca2;
  text-decoration: underline;
  cursor: pointer;
  clear: left;
  margin-top: 20px
}

.narc-submission-form .formfram .formSection .form .formGroup {
  float: left;
  width: 100%;
  margin-bottom: 15px
}

@media(max-width: 1112px) {
  .narc-submission-form .formfram .formSection .form .formGroup {
    margin-bottom: 15px
  }
}

.narc-submission-form .formfram .formSection .form .formGroup label {
  float: left;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
  cursor: pointer
}

.narc-submission-form .formfram .formSection .form .formGroup .input {
  float: left;
  width: 100%;
  position: relative
}

.narc-submission-form .formfram .formSection .form .formGroup .input.file-input {
  display: block;
  float: left;
  width: 100%;
  border: solid 1px #707070;
  border-radius: 5px;
  border-style: dashed;
  text-align: center;
  padding: 17px 0
}

.narc-submission-form .formfram .formSection .form .formGroup .input.file-input input {
  border: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  height: 100%;
  cursor: pointer
}

.narc-submission-form .formfram .formSection .form .formGroup .input.file-input .file-img {
  float: left;
  width: 100%;
  text-align: center
}

.narc-submission-form .formfram .formSection .form .formGroup .input.file-input .file-img img {
  display: inline-block;
  width: 40px
}

.narc-submission-form .formfram .formSection .form .formGroup .input.file-input .input-label {
  float: left;
  width: 100%
}

.narc-submission-form .formfram .formSection .form .formGroup .input.file-input .input-label .text {
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #181818;
  text-align: center
}

.narc-submission-form .formfram .formSection .form .formGroup .input.file-input .input-label .text.or {
  font-weight: normal
}

.narc-submission-form .formfram .formSection .form .formGroup .input.file-input .input-label .text.browse {
  color: #2b5caa
}

.narc-submission-form .formfram .formSection .form .formGroup .input .ui.dropdown {
  width: 100%;
  padding: 11px 10px;
  border: solid 1px #707070;
  border-radius: 5px
}

.narc-submission-form .formfram .formSection .form .formGroup .input .ui.dropdown>.text {
  font-family: "Montserrat", sans-serif;
  font-size: 14px
}

.narc-submission-form .formfram .formSection .form .formGroup .input .ui.dropdown>.dropdown.icon {
  top: 11px
}

.narc-submission-form .formfram .formSection .form .formGroup .input .ui.search.dropdown>.text {
  top: 0
}

.narc-submission-form .formfram .formSection .form .formGroup .input .ui.checkbox label:before {
  border: 1px solid #181818;
  border-radius: 6px
}

.narc-submission-form .formfram .formSection .form .formGroup .input .ui.checkbox input:checked~label:after {
  content: "✓";
  color: #007d0c;
  font-size: 22px;
  top: -4px;
  font-weight: 600
}

.narc-submission-form .formfram .formSection .form .formGroup .input .msg {
  float: left;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  position: absolute;
  right: 0;
  bottom: -17px;
  text-align: right;
  top: unset;
  bottom: -35%
}

.narc-submission-form .formfram .formSection .form .formGroup .input .msg.error {
  color: red
}

.narc-submission-form .formfram .formSection .form .formGroup .input input {
  float: left;
  width: 100%;
  height: unset;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  border: solid 1px #707070;
  border-radius: 5px;
  padding: 10px
}

.narc-submission-form .formfram .formSection .form .formGroup ul {
  list-style-type: none;
  padding: 0;
  margin: 0
}

.narc-submission-form .formfram .formSection .form .formGroup ul li {
  padding-bottom: 5px
}

.narc-submission-form .formfram .formSection .form .formGroup ul li button {
  background: transparent;
  border: 0
}

.narc-submission-form .formfram .formSection .form .submitMe {
  float: left;
  width: 100%
}

.narc-submission-form .formfram .formSection .form .submitMe button {
  float: left;
  width: 100%;
  height: 50px;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: #fff;
  line-height: 40px;
  border-radius: 5px;
  border: 0;
  cursor: pointer;
  background-color: #245ca2;
  box-shadow: 0 0 3px rgba(0, 0, 0, .3)
}

.narc-submission-form .formfram .formSection .form .submitMe button:disabled {
  opacity: .3
}

.narc-submission-form .formfram .formSection .form .submitMe .info {
  float: left;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  color: #181818;
  text-align: center;
  margin-top: 10px
}

.narc-submission-form .formfram .formSection .form .pagenation {
  float: left;
  width: 100%;
  margin-top: 15px;
  text-align: center
}

.narc-submission-form .formfram .formSection .form .pagenation ul {
  margin: 0;
  padding: 0
}

.narc-submission-form .formfram .formSection .form .pagenation ul li {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  border: solid 1px #245ca2;
  margin-right: 15px
}

.narc-submission-form .formfram .formSection .form .pagenation ul li.current {
  background-color: #245ca2
}

.narc-submission-form .formfram .formSection .form .pagenation ul li a {
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 100%
}

.contextnarcSubmission {
  float: left;
  width: 100%;
  padding-left: calc((100% - 1140px) / 2);
  padding-right: calc((100% - 1140px) / 2)
}

@media(max-width: 1024px) {
  .contextnarcSubmission {
    padding-left: 20px;
    padding-right: 20px
  }
}

@media(max-width: 768px) {
  .contextnarcSubmission {
    padding-left: 15px;
    padding-right: 15px
  }
}

.contextnarcSubmission .membership {
  float: left;
  width: 100%;
  margin-top: 100px
}

.contextnarcSubmission .membership.mobile {
  display: none
}

@media(max-width: 768px) {
  .contextnarcSubmission .membership {
    display: none
  }

  .contextnarcSubmission .membership.mobile {
    display: block;
    margin-top: 50px
  }

  .contextnarcSubmission .membership.mobile .tableSection.mt0 {
    margin-top: 0
  }

  .contextnarcSubmission .membership.mobile .tableSection .th.premium {
    background: #003474;
    background: -moz-linear-gradient(-45deg, #003474 1%, #3ab9c7 50%, #1a5aa7 100%);
    background: -webkit-linear-gradient(-45deg, #003474 1%, #3ab9c7 50%, #1a5aa7 100%);
    background: linear-gradient(135deg, #003474 1%, #3ab9c7 50%, #1a5aa7 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#003474", endColorstr="#1a5aa7", GradientType=1);
    margin-bottom: 20px
  }

  .contextnarcSubmission .membership.mobile .tableSection .th.regular {
    background: #b2a616;
    background: -moz-linear-gradient(-45deg, #b2a616 1%, #c7b917 50%, #b2a616 100%);
    background: -webkit-linear-gradient(-45deg, #b2a616 1%, #c7b917 50%, #b2a616 100%);
    background: linear-gradient(135deg, #b2a616 1%, #c7b917 50%, #b2a616 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#b2a616", endColorstr="#b2a616", GradientType=1)
  }

  .contextnarcSubmission .membership.mobile .tableSection .th td:last-child {
    flex: 1
  }

  .contextnarcSubmission .membership.mobile .tableSection .tr td:last-child {
    flex: 1
  }
}

.contextnarcSubmission .membership .head {
  float: left;
  width: 100%;
  text-align: center
}

.contextnarcSubmission .membership .head .title {
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  font-weight: bold;
  color: #393939;
  position: relative
}

@media(max-width: 480px) {
  .contextnarcSubmission .membership .head .title {
    font-size: 24px
  }
}

.contextnarcSubmission .membership .head .title::before {
  content: "";
  position: absolute;
  width: 50%;
  height: 5px;
  background-color: #285caa;
  border-radius: 25px;
  left: 25%;
  bottom: -15px
}

.contextnarcSubmission .membership .tableSection {
  float: left;
  width: 100%;
  border-radius: 20px;
  border: solid 1px #245ca2;
  margin-top: 50px;
  overflow: hidden
}

.contextnarcSubmission .membership .tableSection .th {
  float: left;
  width: 100%;
  display: flex;
  background-color: #245ca2
}

.contextnarcSubmission .membership .tableSection .th .td {
  flex: 1;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  line-height: 80px;
  text-align: center
}

.contextnarcSubmission .membership .tableSection .th .td:last-child {
  flex: 1.5
}

.contextnarcSubmission .membership .tableSection .th .td .icon {
  display: inline-block;
  width: 22px;
  margin-top: 6px;
  margin-right: 10px;
  margin-bottom: -2px
}

.contextnarcSubmission .membership .tableSection .th .td .iconr {
  display: inline-block;
  width: 33px;
  margin-top: 12px;
  margin-right: 10px;
  margin-bottom: -8px
}

.contextnarcSubmission .membership .tableSection .tr {
  float: left;
  width: 100%;
  display: flex;
  background-color: #fff
}

.contextnarcSubmission .membership .tableSection .tr:nth-child(even) {
  background-color: #eef2f3
}

.contextnarcSubmission .membership .tableSection .tr .td {
  flex: 1;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #181818;
  line-height: 80px;
  text-align: center
}

.contextnarcSubmission .membership .tableSection .tr .td:last-child {
  flex: 1.5
}

.contextnarcSubmission .membership .tableSection .tr .td span {
  color: red;
  font-size: 50px
}

.contextnarcSubmission .membership .note {
  float: left;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  color: #181818;
  padding: 0 40px;
  margin-top: 20px
}

.contextnarcSubmission .narcworks {
  float: left;
  width: 100%;
  margin-top: 100px
}

.contextnarcSubmission .narcworks .head {
  float: left;
  width: 100%;
  text-align: center;
  position: relative
}

.contextnarcSubmission .narcworks .head::before {
  content: "";
  position: absolute;
  width: 20%;
  height: 5px;
  background-color: #285caa;
  border-radius: 25px;
  left: 40%;
  bottom: -15px
}

.contextnarcSubmission .narcworks .head .title {
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  font-weight: bold;
  color: #393939;
  position: relative
}

@media(max-width: 768px) {
  .contextnarcSubmission .narcworks .head .title {
    font-size: 24px
  }
}

@media(max-width: 480px) {
  .contextnarcSubmission .narcworks .head .title {
    font-size: 20px;
    line-height: 22px
  }
}

.contextnarcSubmission .narcworks .sectionBlock {
  float: left;
  width: 100%;
  margin-top: 80px
}

@media(max-width: 768px) {
  .contextnarcSubmission .narcworks .sectionBlock {
    margin-top: 100px
  }
}

@media(max-width: 480px) {
  .contextnarcSubmission .narcworks .sectionBlock {
    margin-top: 100px
  }
}

.contextnarcSubmission .narcworks .sectionBlock .leftSection {
  float: left;
  width: 30%;
  min-height: 400px
}

.contextnarcSubmission .narcworks .sectionBlock .leftSection .block {
  float: right;
  width: 310px;
  padding: 14px;
  box-shadow: 0 0 3px rgba(0, 0, 0, .3)
}

@media(max-width: 1024px) {
  .contextnarcSubmission .narcworks .sectionBlock .leftSection .block {
    width: 88%
  }
}

@media(max-width: 768px) {
  .contextnarcSubmission .narcworks .sectionBlock .leftSection .block {
    width: calc(100% - 250px);
    float: left
  }
}

@media(max-width: 480px) {
  .contextnarcSubmission .narcworks .sectionBlock .leftSection .block {
    width: calc(100% - 110px);
    padding: 10px
  }
}

.contextnarcSubmission .narcworks .sectionBlock .leftSection .block:nth-child(2) {
  margin-top: 120px;
  margin-right: -40px
}

@media(max-width: 1024px) {
  .contextnarcSubmission .narcworks .sectionBlock .leftSection .block:nth-child(2) {
    margin-top: 84px
  }
}

@media(max-width: 768px) {
  .contextnarcSubmission .narcworks .sectionBlock .leftSection .block:nth-child(2) {
    margin-right: 0;
    margin-top: -35px;
    margin-left: -10px
  }
}

@media(max-width: 480px) {
  .contextnarcSubmission .narcworks .sectionBlock .leftSection .block:nth-child(2) {
    margin-left: 0
  }
}

.contextnarcSubmission .narcworks .sectionBlock .leftSection .block:nth-child(3) {
  margin-top: 55px;
  margin-right: 20px
}

@media(max-width: 768px) {
  .contextnarcSubmission .narcworks .sectionBlock .leftSection .block:nth-child(3) {
    margin-right: 0;
    margin-top: 150px;
    margin-left: 25px
  }
}

@media(max-width: 480px) {
  .contextnarcSubmission .narcworks .sectionBlock .leftSection .block:nth-child(3) {
    margin-top: 70px;
    margin-left: 20px
  }
}

.contextnarcSubmission .narcworks .sectionBlock .leftSection .block:nth-child(4) {
  margin-top: 55px;
  margin-right: -20px
}

@media(max-width: 768px) {
  .contextnarcSubmission .narcworks .sectionBlock .leftSection .block:nth-child(4) {
    margin-right: 0;
    margin-top: 110px
  }
}

@media(max-width: 480px) {
  .contextnarcSubmission .narcworks .sectionBlock .leftSection .block:nth-child(4) {
    margin-top: 65px
  }
}

.contextnarcSubmission .narcworks .sectionBlock .leftSection .block .img {
  float: left;
  width: 40px;
  height: 40px;
  padding: 12px;
  border-radius: 100%;
  background-color: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, .2);
  margin-top: -10px;
  margin-left: -10px
}

.contextnarcSubmission .narcworks .sectionBlock .leftSection .block .img img {
  float: left;
  width: 100%
}

@media(max-width: 480px) {
  .contextnarcSubmission .narcworks .sectionBlock .leftSection .block .img {
    margin-top: -6px;
    margin-left: -6px
  }
}

.contextnarcSubmission .narcworks .sectionBlock .leftSection .block .text {
  float: left;
  width: calc(100% - 30px);
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #333;
  padding-left: 15px;
  margin-top: 0px
}

@media(max-width: 480px) {
  .contextnarcSubmission .narcworks .sectionBlock .leftSection .block .text {
    width: calc(100% - 35px);
    padding-left: 6px;
    margin-top: 0
  }
}

.contextnarcSubmission .narcworks .sectionBlock .leftSection .infographic {
  float: left;
  width: 120px;
  display: none
}

.contextnarcSubmission .narcworks .sectionBlock .leftSection .infographic img {
  float: left;
  width: 100%
}

@media(max-width: 768px) {
  .contextnarcSubmission .narcworks .sectionBlock .leftSection .infographic {
    display: block
  }
}

@media(max-width: 480px) {
  .contextnarcSubmission .narcworks .sectionBlock .leftSection .infographic {
    width: 80px;
    margin-top: 0px
  }
}

@media(max-width: 768px) {
  .contextnarcSubmission .narcworks .sectionBlock .leftSection {
    width: 100%
  }
}

.contextnarcSubmission .narcworks .sectionBlock .middleSection {
  float: left;
  width: 40%
}

.contextnarcSubmission .narcworks .sectionBlock .middleSection .img {
  float: left;
  width: 100%
}

.contextnarcSubmission .narcworks .sectionBlock .middleSection .img img {
  float: left;
  width: 100%
}

@media(max-width: 768px) {
  .contextnarcSubmission .narcworks .sectionBlock .middleSection {
    display: none
  }
}

.contextnarcSubmission .narcworks .sectionBlock .rightSection {
  float: left;
  width: 30%;
  min-height: 400px
}

.contextnarcSubmission .narcworks .sectionBlock .rightSection .block {
  float: left;
  width: 310px;
  padding: 14px;
  box-shadow: 0 0 3px rgba(0, 0, 0, .3)
}

@media(max-width: 1024px) {
  .contextnarcSubmission .narcworks .sectionBlock .rightSection .block {
    width: 80%
  }
}

@media(max-width: 768px) {
  .contextnarcSubmission .narcworks .sectionBlock .rightSection .block {
    width: calc(100% - 250px);
    float: right
  }
}

@media(max-width: 480px) {
  .contextnarcSubmission .narcworks .sectionBlock .rightSection .block {
    width: calc(100% - 110px);
    margin-top: 70px;
    padding: 10px
  }
}

.contextnarcSubmission .narcworks .sectionBlock .rightSection .block:nth-child(2) {
  margin-top: 120px;
  margin-left: 0
}

@media(max-width: 1024px) {
  .contextnarcSubmission .narcworks .sectionBlock .rightSection .block:nth-child(2) {
    margin-top: 60px
  }
}

@media(max-width: 768px) {
  .contextnarcSubmission .narcworks .sectionBlock .rightSection .block:nth-child(2) {
    margin-top: 90px;
    margin-left: 0
  }
}

@media(max-width: 480px) {
  .contextnarcSubmission .narcworks .sectionBlock .rightSection .block:nth-child(2) {
    margin-top: 37px
  }
}

.contextnarcSubmission .narcworks .sectionBlock .rightSection .block:nth-child(3) {
  margin-top: 50px;
  margin-left: 40px
}

@media(max-width: 768px) {
  .contextnarcSubmission .narcworks .sectionBlock .rightSection .block:nth-child(3) {
    margin-top: 150px;
    margin-left: 0;
    margin-right: 20px
  }
}

@media(max-width: 480px) {
  .contextnarcSubmission .narcworks .sectionBlock .rightSection .block:nth-child(3) {
    margin-top: 70px;
    margin-left: 0;
    margin-right: 20px
  }
}

.contextnarcSubmission .narcworks .sectionBlock .rightSection .block:nth-child(4) {
  margin-top: 50px;
  margin-left: -25px
}

@media(max-width: 768px) {
  .contextnarcSubmission .narcworks .sectionBlock .rightSection .block:nth-child(4) {
    margin-top: 105px;
    margin-left: 0
  }
}

@media(max-width: 480px) {
  .contextnarcSubmission .narcworks .sectionBlock .rightSection .block:nth-child(4) {
    margin-top: 65px
  }
}

.contextnarcSubmission .narcworks .sectionBlock .rightSection .block .img {
  float: left;
  width: 40px;
  height: 40px;
  padding: 12px;
  border-radius: 100%;
  background-color: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, .2);
  margin-top: -10px;
  margin-left: -10px
}

.contextnarcSubmission .narcworks .sectionBlock .rightSection .block .img img {
  float: left;
  width: 100%
}

@media(max-width: 480px) {
  .contextnarcSubmission .narcworks .sectionBlock .rightSection .block .img {
    margin-top: -6px;
    margin-left: -6px
  }
}

.contextnarcSubmission .narcworks .sectionBlock .rightSection .block .text {
  float: left;
  width: calc(100% - 30px);
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #333;
  padding-left: 15px;
  margin-top: 0px
}

@media(max-width: 480px) {
  .contextnarcSubmission .narcworks .sectionBlock .rightSection .block .text {
    width: calc(100% - 35px);
    padding-left: 6px;
    margin-top: 0
  }
}

.contextnarcSubmission .narcworks .sectionBlock .rightSection .infographic {
  float: right;
  width: 120px;
  display: none
}

.contextnarcSubmission .narcworks .sectionBlock .rightSection .infographic img {
  float: left;
  width: 100%
}

@media(max-width: 768px) {
  .contextnarcSubmission .narcworks .sectionBlock .rightSection .infographic {
    display: block;
    margin-top: 120px
  }
}

@media(max-width: 480px) {
  .contextnarcSubmission .narcworks .sectionBlock .rightSection .infographic {
    width: 80px;
    margin-top: 71px
  }
}

@media(max-width: 768px) {
  .contextnarcSubmission .narcworks .sectionBlock .rightSection {
    width: 100%
  }
}

@media(max-width: 768px) {
  .contextnarcSubmission .narcworks {
    margin-top: 50px;
    padding: 0
  }
}

.contextnarcSubmission .testimonials {
  float: left;
  width: 100%;
  margin-top: 0;
  margin-bottom: 100px;
  padding-left: calc((100% - 1140px) / 2);
  padding-right: calc((100% - 1140px) / 2)
}

@media(max-width: 768px) {
  .contextnarcSubmission .testimonials {
    margin-top: 50px;
    padding: 0 15px;
    margin-bottom: 40px
  }
}

@media(max-width: 480px) {
  .contextnarcSubmission .testimonials {
    margin-top: 60px;
    margin-bottom: 50px;
    padding: 0px
  }
}

.contextnarcSubmission .testimonials .head {
  float: left;
  width: 100%;
  text-align: center;
  position: relative
}

.contextnarcSubmission .testimonials .head::before {
  content: "";
  position: absolute;
  width: 20%;
  height: 5px;
  background-color: #285caa;
  border-radius: 25px;
  left: 40%;
  bottom: -20px
}

@media(max-width: 768px) {
  .contextnarcSubmission .testimonials .head::before {
    width: 30%;
    left: 35%
  }
}

.contextnarcSubmission .testimonials .head .title {
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  font-weight: bold;
  color: #393939
}

@media(max-width: 768px) {
  .contextnarcSubmission .testimonials .head .title {
    font-size: 24px
  }
}

@media(max-width: 480px) {
  .contextnarcSubmission .testimonials .head .title {
    font-size: 20px;
    line-height: 22px
  }
}

.contextnarcSubmission .testimonials .head .subText {
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #393939;
  position: relative;
  margin-top: 5px
}

@media(max-width: 480px) {
  .contextnarcSubmission .testimonials .head .subText {
    font-size: 16px
  }
}

.contextnarcSubmission .testimonials .block {
  float: left;
  width: 100%;
  margin-top: 50px;
  padding: 0
}

.contextnarcSubmission .testimonials .block .slick-list {
  padding: 0 0 0 3px
}

.contextnarcSubmission .testimonials .block .slick-slider {
  padding: 0
}

@media(max-width: 768px) {
  .contextnarcSubmission .testimonials .block .slick-slider {
    padding: 0
  }
}

.contextnarcSubmission .testimonials .block .slick-slide {
  padding-right: 20px
}

@media(max-width: 480px) {
  .contextnarcSubmission .testimonials .block .slick-slide {
    padding: 10px
  }
}

.contextnarcSubmission .testimonials .block .slick-slide div {
  float: left;
  width: 100%
}

.contextnarcSubmission .testimonials .block .slick-next,
.contextnarcSubmission .testimonials .block .slick-prev {
  z-index: 1
}

.contextnarcSubmission .testimonials .block .slick-prev {
  left: -14px;
  font-size: 25px;
  color: #000;
  width: 30px;
  height: 30px;
  line-height: 47px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, .4);
  z-index: 3
}

@media(max-width: 768px) {
  .contextnarcSubmission .testimonials .block .slick-prev {
    left: -4px;
    top: 50%;
    width: 30px;
    height: 30px;
    border-radius: 0
  }
}

.contextnarcSubmission .testimonials .block .slick-prev .icon {
  position: relative;
  top: -9px;
  left: 1px;
  font-size: 23px
}

@media(max-width: 768px) {
  .contextnarcSubmission .testimonials .block .slick-prev .icon {
    top: -8px;
    left: -1px;
    font-size: 23px
  }
}

.contextnarcSubmission .testimonials .block .slick-prev .icon:before {
  color: #c00096
}

.contextnarcSubmission .testimonials .block .slick-next {
  right: 0;
  font-size: 25px;
  color: #000;
  width: 30px;
  height: 30px;
  line-height: 47px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 0 3px rgba(0, 0, 0, .3);
  z-index: 3
}

@media(max-width: 768px) {
  .contextnarcSubmission .testimonials .block .slick-next {
    right: -8px;
    top: 50%;
    width: 30px;
    height: 30px;
    border-radius: 0
  }
}

.contextnarcSubmission .testimonials .block .slick-next .icon {
  position: relative;
  top: -9px;
  right: -1px;
  font-size: 23px
}

@media(max-width: 768px) {
  .contextnarcSubmission .testimonials .block .slick-next .icon {
    top: -8px;
    right: -1px;
    font-size: 23px
  }
}

.contextnarcSubmission .testimonials .block .slick-next .icon:before {
  color: #c00096
}

.contextnarcSubmission .testimonials .block .slick-next:before,
.contextnarcSubmission .testimonials .block .slick-prev:before {
  font-size: 30px;
  color: #393939;
  opacity: 1;
  display: none
}

.contextnarcSubmission .testimonials .block .slick-dots {
  display: none !important
}

@media(max-width: 768px) {
  .contextnarcSubmission .testimonials .block .slick-dots {
    display: none !important
  }
}

.contextnarcSubmission .testimonials .block .slick-dots li {
  width: 8px;
  height: 8px
}

.contextnarcSubmission .testimonials .block .slick-dots li button {
  border: solid 2px #285caa;
  border-radius: 100%;
  width: 8px;
  height: 8px
}

.contextnarcSubmission .testimonials .block .slick-dots li button::before {
  font-size: 14px;
  top: -2px;
  opacity: 0;
  color: #285caa;
  width: 8px;
  height: 8px
}

.contextnarcSubmission .testimonials .block .slick-dots li.slick-active button:before {
  opacity: 1
}

.contextnarcSubmission .testimonials .block .slick-track {
  display: flex !important
}

.contextnarcSubmission .testimonials .block .slick-slide {
  height: inherit !important
}

.contextnarcSubmission .testimonials .block .slick-slide>div {
  display: flex;
  width: 100%;
  height: 100%
}

.contextnarcSubmission .testimonials .block .testimonial {
  float: left;
  width: 100%;
  padding: 20px;
  background-color: #fff;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, .3);
  margin-top: 10px;
  margin-bottom: 5px;
  border-radius: 15px
}

.contextnarcSubmission .testimonials .block .testimonial.videoPost {
  position: relative;
  padding: 0
}

.contextnarcSubmission .testimonials .block .testimonial.videoPost .artPlot {
  display: none
}

.contextnarcSubmission .testimonials .block .testimonial.videoPost .content {
  display: none
}

.contextnarcSubmission .testimonials .block .testimonial.videoPost .video {
  display: block
}

.contextnarcSubmission .testimonials .block .testimonial .video {
  float: left;
  display: none;
  width: 100%;
  height: 180px;
  border-radius: 15px;
  z-index: -1
}

.contextnarcSubmission .testimonials .block .testimonial .artPlot {
  float: left;
  width: 80px
}

.contextnarcSubmission .testimonials .block .testimonial .artPlot .img {
  float: left;
  width: 80px;
  height: 120px;
  border: solid 1px #285caa;
  border-radius: 15px
}

.contextnarcSubmission .testimonials .block .testimonial .artPlot .img img {
  float: left;
  width: 80px;
  height: 120px;
  border-radius: 15px;
  margin-left: 5px;
  margin-top: -5px;
  margin-bottom: 5px;
  object-fit: cover;
  object-position: center top
}

.contextnarcSubmission .testimonials .block .testimonial .text {
  float: left;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #393939;
  padding-top: 8px;
  text-align: left
}

.contextnarcSubmission .testimonials .block .testimonial .content {
  float: left;
  width: calc(100% - 80px);
  padding-left: 30px;
  padding-right: 15px
}

.contextnarcSubmission .testimonials .block .testimonial .content .testimonialText {
  float: left;
  width: 100%;
  min-height: 100px;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  color: #393939;
  display: flex;
  align-items: center;
  text-align: center
}

.contextnarcSubmission .testimonials .block .testimonial .content .testimonialauthor {
  float: right;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  color: #777;
  margin-top: 5px
}

.contextnarcSubmission .testimonials {
  margin-top: 100px
}

@media(max-width: 768px) {
  .contextnarcSubmission .testimonials {
    margin-top: 50px;
    padding: 0
  }
}

/*# sourceMappingURL=narc-submission.css.map */