.narcreg-formBanner {
  float: left;
  width: 100%;
  position: relative;
}
.narcreg-formBanner .form-frame {
  float: left;
  width: 100%;
  padding-left: calc((100% - 1140px) / 2);
  padding-right: calc((100% - 1140px) / 2);
}
@media (max-width: 1024px) {
  .narcreg-formBanner .form-frame {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 480px) {
  .narcreg-formBanner .form-frame {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.narcreg-formBanner .form-frame .formSection {
  position: absolute;
  width: 445px;
  min-height: 485px;
  background-color: #fff;
  border-radius: 15px;
  margin-left: 0px;
  padding: 20px 40px 15px;
  margin-top: 10px;
  margin-bottom: 20px;
  box-shadow: 3px 7px 14px 0 rgba(114, 113, 180, 0.3);
  left: unset;
}
@media (max-width: 1024px) {
  .narcreg-formBanner .form-frame .formSection {
    margin-top: 65px;
  }
}
@media (max-width: 1024px) {
  .narcreg-formBanner .form-frame .formSection {
    float: left;
    margin: 0;
    width: 100%;
    padding: 40px 20px 15px;
    position: relative;
    z-index: 1;
    margin-top: 250px;
  }
}
@media (max-width: 480px) {
  .narcreg-formBanner .form-frame .formSection {
    margin-top: 150px;
  }
}
.narcreg-formBanner .form-frame .formSection .head {
  float: left;
  width: 100%;
}
.narcreg-formBanner .form-frame .formSection .head.center .title {
  text-align: center;
  font-weight: 700;
}
.narcreg-formBanner .form-frame .formSection .head.center .subtext {
  text-align: justify;
}
.narcreg-formBanner .form-frame .formSection .head.eligible {
  margin-top: 60px;
}
.narcreg-formBanner .form-frame .formSection .head.eligible .subtext {
  font-size: 17px;
  text-align: justify;
}
.narcreg-formBanner .form-frame .formSection .head .icon {
  float: left;
  width: 80px;
  margin-left: calc((100% - 80px) / 2);
  margin-bottom: 20px;
}
.narcreg-formBanner .form-frame .formSection .head .icon img {
  float: left;
  width: 100%;
}
.narcreg-formBanner .form-frame .formSection .head .title {
  float: left;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 23px;
  font-weight: 700;
  color: #181818;
}
.narcreg-formBanner .form-frame .formSection .head .typeInfo {
  float: left;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #181818;
  margin-top: 16px;
}
.narcreg-formBanner .form-frame .formSection .head .subtext {
  float: left;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  color: #181818;
  margin-top: 15px;
}
.narcreg-formBanner .form-frame .formSection .form {
  float: left;
  width: 100%;
  margin-top: 30px;
}
.narcreg-formBanner
  .form-frame
  .formSection
  .form.stepOne
  .formGroup
  label
  .star {
  color: red;
}
.narcreg-formBanner .form-frame .formSection .form.stepOne .submitMe {
  margin-top: 114px;
}
.narcreg-formBanner .form-frame .formSection .form.stepTwo {
  margin-top: 30px;
}
.narcreg-formBanner
  .form-frame
  .formSection
  .form.stepTwo
  .formGroup
  label
  .star {
  color: red;
}
.narcreg-formBanner .form-frame .formSection .form.stepTwo .submitMe {
  margin-top: 83px;
}
.narcreg-formBanner .form-frame .formSection .form.stepTwo .formGroup {
  margin-top: 20px;
}
.narcreg-formBanner .form-frame .formSection .form.stepTwo .formGroup .input {
  display: flex;
  width: 100%;
}
.narcreg-formBanner
  .form-frame
  .formSection
  .form.stepTwo
  .formGroup
  .input
  input {
  width: calc((100% - 75px) / 6);
  margin-right: 15px;
  border: 0;
  height: 50px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  text-align: center;
  background-color: #e1e1e1;
}
.narcreg-formBanner
  .form-frame
  .formSection
  .form.stepTwo
  .formGroup
  .input
  input:last-child {
  margin-right: 0;
}
.narcreg-formBanner
  .form-frame
  .formSection
  .form.stepThree
  .formGroup
  label
  .star {
  color: red;
}
.narcreg-formBanner .form-frame .formSection .form.stepThree .submitMe {
  margin-top: 73px;
}
.narcreg-formBanner
  .form-frame
  .formSection
  .form.stepThree
  .formGroup
  .input
  .field {
  float: left;
  width: 100%;
}
.narcreg-formBanner
  .form-frame
  .formSection
  .form.stepThree
  .formGroup
  .input
  i {
  font-size: 16px;
  width: 42px;
  opacity: 0.9;
}
.narcreg-formBanner
  .form-frame
  .formSection
  .form.stepThree
  .submitMe
  button
  i {
  float: right;
}
.narcreg-formBanner .form-frame .formSection .form.stepFour {
  margin-top: 60px;
}
.narcreg-formBanner
  .form-frame
  .formSection
  .form.stepFour
  .formGroup
  label
  .star {
  color: red;
}
.narcreg-formBanner .form-frame .formSection .form.stepFour .pagenation {
  margin-top: 136px;
}
.narcreg-formBanner .form-frame .formSection .form.stepFive {
  margin-top: 30px;
}
.narcreg-formBanner
  .form-frame
  .formSection
  .form.stepFive
  .formGroup
  label
  .star {
  color: red;
}
.narcreg-formBanner .form-frame .formSection .form.stepFive .submitMe {
  margin-top: 130px;
}
.narcreg-formBanner
  .form-frame
  .formSection
  .form.stepFive
  .formGroup
  .input
  .ui.search {
  width: 100%;
  border: solid 1px #707070;
}
.narcreg-formBanner
  .form-frame
  .formSection
  .form.stepFive
  .formGroup
  .input
  .ui.search
  .menu {
  max-height: 150px;
}
.narcreg-formBanner .form-frame .formSection .form.stepFive .pagenation {
  margin-top: 15px;
}
.narcreg-formBanner
  .form-frame
  .formSection
  .form.stepSix
  .formGroup
  label
  .star {
  color: red;
}
.narcreg-formBanner .form-frame .formSection .form.stepSix .submitMe button {
  height: 50px;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
}
.narcreg-formBanner
  .form-frame
  .formSection
  .form.stepSix
  .submitMe
  button.other {
  background-color: #fff;
  border: solid 1px #245ca2;
  color: #245ca2;
}
.narcreg-formBanner .form-frame .formSection .form.stepSeven {
  margin-top: 60px;
}
.narcreg-formBanner
  .form-frame
  .formSection
  .form.stepSeven
  .formGroup
  label
  .star {
  color: red;
}
.narcreg-formBanner .form-frame .formSection .form.stepSeven .submitMe {
  margin-top: 15px;
}
.narcreg-formBanner .form-frame .formSection .form.stepPay {
  margin-top: 60px;
}
.narcreg-formBanner .form-frame .formSection .form.stepPay .pagenation {
  margin-top: 200px;
}
.narcreg-formBanner .form-frame .formSection .form .accountType {
  float: left;
  width: calc(100% + 80px);
  margin-top: 0;
  margin-left: -40px;
}
@media (max-width: 1024px) {
  .narcreg-formBanner .form-frame .formSection .form .accountType {
    width: calc(100% + 40px);
    margin-left: -20px;
  }
}
.narcreg-formBanner .form-frame .formSection .form .accountType button {
  float: left;
  width: 100%;
  height: 70px;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 16px;
  color: #fff;
  line-height: 65px;
  border: 0;
  cursor: pointer;
  background-color: #245ca2;
  border-top: solid 2px transparent;
  border-bottom: solid 2px transparent;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}
.narcreg-formBanner .form-frame .formSection .form .accountType button:hover {
  background-color: #08316e;
  color: #fff;
}
.narcreg-formBanner .form-frame .formSection .form .accountType button.paid {
  background-color: #fff;
  color: #245ca2;
  border-top: solid 2px #245ca2;
  border-bottom: solid 2px #245ca2;
  margin-bottom: 20px;
}
.narcreg-formBanner
  .form-frame
  .formSection
  .form
  .accountType
  button.paid:hover {
  background-color: #245ca2;
  color: #fff;
}
.narcreg-formBanner
  .form-frame
  .formSection
  .form
  .accountType
  button.regschool-btn {
  box-shadow: 0 2px 6px 0 rgba(36, 92, 162, 0.3);
  background-image: linear-gradient(
    269deg,
    #003474 107%,
    #3ab9c7 49%,
    #1a5aa7 -5%
  );
  position: relative;
}
.narcreg-formBanner
  .form-frame
  .formSection
  .form
  .accountType
  button.regschool-btn
  .icon {
  display: inline-block;
  width: 22px;
  margin-top: 20px;
  margin-left: 20px;
  margin-bottom: -2px;
  float: left;
  position: absolute;
  top: 0;
  left: 0;
}
.narcreg-formBanner
  .form-frame
  .formSection
  .form
  .accountType
  button.regteam-btn {
  box-shadow: 0 2px 6px 0 rgba(36, 92, 162, 0.3);
  background-image: linear-gradient(
    to left,
    #b2a616 100%,
    #c7b917 43%,
    #b2a616 -7%
  );
  position: relative;
}
.narcreg-formBanner
  .form-frame
  .formSection
  .form
  .accountType
  button.regteam-btn
  .iconr {
  display: inline-block;
  width: 33px;
  margin-top: 20px;
  margin-left: 20px;
  margin-bottom: -8px;
  float: left;
  position: absolute;
  top: 0;
  left: 0;
}
.narcreg-formBanner .form-frame .formSection .form .accountPayment {
  float: left;
  width: calc(100% + 80px);
  margin-top: 0;
  margin-left: -40px;
}
@media (max-width: 1024px) {
  .narcreg-formBanner .form-frame .formSection .form .accountPayment {
    width: calc(100% + 40px);
    margin-left: -20px;
  }
}
.narcreg-formBanner .form-frame .formSection .form .accountPayment button {
  float: left;
  width: 100%;
  height: 70px;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: #fff;
  line-height: 65px;
  border: 0;
  cursor: pointer;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  padding: 0 25px;
}
.narcreg-formBanner
  .form-frame
  .formSection
  .form
  .accountPayment
  button
  .icon {
  float: left;
  width: 45px;
  margin-top: 15px;
}
.narcreg-formBanner
  .form-frame
  .formSection
  .form
  .accountPayment
  button
  .icon
  img {
  float: left;
  width: 100%;
}
.narcreg-formBanner .form-frame .formSection .form .accountPayment button span {
  float: right;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
}
.narcreg-formBanner
  .form-frame
  .formSection
  .form
  .accountPayment
  button.premium {
  background: #003474;
  background: -moz-linear-gradient(
    -45deg,
    #003474 1%,
    #3ab9c7 50%,
    #1a5aa7 100%
  );
  background: -webkit-linear-gradient(
    -45deg,
    #003474 1%,
    #3ab9c7 50%,
    #1a5aa7 100%
  );
  background: linear-gradient(135deg, #003474 1%, #3ab9c7 50%, #1a5aa7 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#003474", endColorstr="#1a5aa7",GradientType=1 );
  margin-bottom: 20px;
}
.narcreg-formBanner
  .form-frame
  .formSection
  .form
  .accountPayment
  button.regular {
  background: #b2a616;
  background: -moz-linear-gradient(
    -45deg,
    #b2a616 1%,
    #c7b917 50%,
    #b2a616 100%
  );
  background: -webkit-linear-gradient(
    -45deg,
    #b2a616 1%,
    #c7b917 50%,
    #b2a616 100%
  );
  background: linear-gradient(135deg, #b2a616 1%, #c7b917 50%, #b2a616 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#b2a616", endColorstr="#b2a616",GradientType=1 );
}
.narcreg-formBanner
  .form-frame
  .formSection
  .form
  .accountPayment
  .country-pice {
  float: left;
  margin-top: 12px;
  margin-left: 10px;
  width: 100%;
  padding-left: 35px;
  font-style: italic;
}
.narcreg-formBanner .form-frame .formSection .form .verificationTo {
  float: left;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  color: #181818;
}
.narcreg-formBanner .form-frame .formSection .form .otpUpdate {
  float: left;
  width: 100%;
  margin-top: 40px;
}
.narcreg-formBanner .form-frame .formSection .form .otpUpdate .resend {
  float: left;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #245ca2;
  text-decoration: underline;
  cursor: pointer;
}
.narcreg-formBanner .form-frame .formSection .form .otpUpdate .change {
  float: left;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #245ca2;
  text-decoration: underline;
  cursor: pointer;
  clear: left;
  margin-top: 20px;
}
.narcreg-formBanner .form-frame .formSection .form .formGroup {
  float: left;
  width: 100%;
  margin-bottom: 15px;
}
.narcreg-formBanner .form-frame .formSection .form .formGroup label {
  float: left;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}
.narcreg-formBanner .form-frame .formSection .form .formGroup .input {
  float: left;
  width: 100%;
  position: relative;
}
.narcreg-formBanner
  .form-frame
  .formSection
  .form
  .formGroup
  .input
  .ui.dropdown {
  width: 100%;
  padding: 11px 10px;
  border: solid 1px #707070;
  border-radius: 5px;
}
.narcreg-formBanner
  .form-frame
  .formSection
  .form
  .formGroup
  .input
  .ui.dropdown
  > .text {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
}
.narcreg-formBanner
  .form-frame
  .formSection
  .form
  .formGroup
  .input
  .ui.dropdown
  > .dropdown.icon {
  top: 7px;
  right: 12px;
}
.narcreg-formBanner
  .form-frame
  .formSection
  .form
  .formGroup
  .input
  .ui.search.dropdown
  > .text {
  top: 0;
  color: #707070;
  padding-right: 20px;
}
.narcreg-formBanner
  .form-frame
  .formSection
  .form
  .formGroup
  .input
  .clndr-left {
  width: 75%;
}
.narcreg-formBanner .form-frame .formSection .form .formGroup .input .msg {
  position: absolute;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  bottom: -17px;
  right: 0;
}
.narcreg-formBanner
  .form-frame
  .formSection
  .form
  .formGroup
  .input
  .msg.error {
  color: red;
}
.narcreg-formBanner .form-frame .formSection .form .formGroup .input input {
  float: left;
  width: 100%;
  height: unset;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  border: solid 1px #707070;
  border-radius: 5px;
  padding: 10px;
}
.narcreg-formBanner .form-frame .formSection .form .submitMe {
  float: left;
  width: 100%;
}
.narcreg-formBanner .form-frame .formSection .form .submitMe button {
  float: left;
  width: 100%;
  height: 50px;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: #fff;
  line-height: 40px;
  border-radius: 5px;
  border: 0;
  cursor: pointer;
  background-color: #245ca2;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}
.narcreg-formBanner .form-frame .formSection .form .submitMe button:disabled {
  opacity: 0.3;
}
.narcreg-formBanner .form-frame .formSection .form .submitMe .info {
  float: left;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  color: #181818;
  text-align: center;
  margin-top: 10px;
}
.narcreg-formBanner .form-frame .formSection .form .pagenation {
  float: left;
  width: 100%;
  margin-top: 30px;
  text-align: center;
}
.narcreg-formBanner .form-frame .formSection .form .pagenation ul {
  margin: 0;
  padding: 0;
}
.narcreg-formBanner .form-frame .formSection .form .pagenation ul li {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  border: solid 1px #245ca2;
  margin-right: 15px;
}
.narcreg-formBanner .form-frame .formSection .form .pagenation ul li.current {
  background-color: #245ca2;
}
.narcreg-formBanner .form-frame .formSection .form .pagenation ul li a {
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 100%;
}
.narcreg-formBanner .bannerImg {
  float: left;
  width: 100%;
}
@media (max-width: 1024px) {
  .narcreg-formBanner .bannerImg {
    position: absolute;
    left: 0;
    top: 0;
  }
}
.narcreg-formBanner .bannerImg img {
  float: left;
  width: 100%;
}
.narcreg-formBanner .bannerImg img.mb {
  display: none;
}
@media (max-width: 1024px) {
  .narcreg-formBanner .bannerImg img {
    display: none;
  }
  .narcreg-formBanner .bannerImg img.mb {
    display: block;
  }
}
.contextnarcreg {
  float: left;
  width: 100%;
  padding-left: calc((100% - 1140px) / 2);
  padding-right: calc((100% - 1140px) / 2);
}
@media (max-width: 1024px) {
  .contextnarcreg {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 480px) {
  .contextnarcreg {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.contextnarcreg .membership {
  float: left;
  width: 100%;
  margin-top: 100px;
}
@media (max-width: 480px) {
  .contextnarcreg .membership {
    margin-top: 50px;
  }
}
.contextnarcreg .membership.mobile {
  display: none;
}
@media (max-width: 768px) {
  .contextnarcreg .membership {
    display: none;
  }
  .contextnarcreg .membership.mobile {
    display: block;
    font-size: 12px;
    text-align: justify;
    margin-top: 25px;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-style: italic;
  }
  .contextnarcreg .membership.mobile .tableSection.mt0 {
    margin-top: 0;
  }
  .contextnarcreg .membership.mobile .tableSection .th.premium {
    background: #003474;
    background: -moz-linear-gradient(
      -45deg,
      #003474 1%,
      #3ab9c7 50%,
      #1a5aa7 100%
    );
    background: -webkit-linear-gradient(
      -45deg,
      #003474 1%,
      #3ab9c7 50%,
      #1a5aa7 100%
    );
    background: linear-gradient(135deg, #003474 1%, #3ab9c7 50%, #1a5aa7 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#003474", endColorstr="#1a5aa7",GradientType=1 );
  }
  .contextnarcreg .membership.mobile .tableSection .th.regular {
    background: #b2a616;
    background: -moz-linear-gradient(
      -45deg,
      #b2a616 1%,
      #c7b917 50%,
      #b2a616 100%
    );
    background: -webkit-linear-gradient(
      -45deg,
      #b2a616 1%,
      #c7b917 50%,
      #b2a616 100%
    );
    background: linear-gradient(135deg, #b2a616 1%, #c7b917 50%, #b2a616 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#b2a616", endColorstr="#b2a616",GradientType=1 );
  }
  .contextnarcreg .membership.mobile .tableSection .th td:last-child {
    flex: 1;
  }
  .contextnarcreg .membership.mobile .tableSection .tr td:last-child {
    flex: 1;
  }
}
.contextnarcreg .membership .head {
  float: left;
  width: 100%;
  text-align: center;
}
.contextnarcreg .membership .head .title {
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  font-weight: bold;
  color: #393939;
  position: relative;
}
@media (max-width: 480px) {
  .contextnarcreg .membership .head .title {
    font-size: 24px;
  }
}
.contextnarcreg .membership .head .title::before {
  content: "";
  position: absolute;
  width: 50%;
  height: 5px;
  background-color: #285caa;
  border-radius: 25px;
  left: 25%;
  bottom: -15px;
}
.contextnarcreg .membership .tableSection {
  float: left;
  width: 100%;
  border-radius: 20px;
  border: solid 1px #245ca2;
  margin-top: 50px;
  overflow: hidden;
}
.contextnarcreg .membership .tableSection .th {
  float: left;
  width: 100%;
  display: flex;
  background-color: #245ca2;
}
.contextnarcreg .membership .tableSection .th .td {
  flex: 1;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  line-height: 80px;
  text-align: center;
}
.contextnarcreg .membership .tableSection .th .td:last-child {
  flex: 1.5;
}
.contextnarcreg .membership .tableSection .th .td .icon {
  display: inline-block;
  width: 22px;
  margin-top: -5px;
  margin-right: 10px;
  margin-bottom: -2px;
}
.contextnarcreg .membership .tableSection .th .td .iconr {
  display: inline-block;
  width: 33px;
  margin-top: -5px;
  margin-right: 10px;
  margin-bottom: -8px;
}
.contextnarcreg .membership .tableSection .tr {
  float: left;
  width: 100%;
  display: flex;
  background-color: #fff;
}
.contextnarcreg .membership .tableSection .tr:nth-child(even) {
  background-color: #eef2f3;
}
.contextnarcreg .membership .tableSection .tr .td {
  flex: 1;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #181818;
  line-height: 80px;
  text-align: center;
  white-space: nowrap;
  padding: 0 10px;
}
.contextnarcreg .membership .tableSection .tr .td:last-child {
  flex: 1.5;
}
@media (max-width: 480px) {
  .contextnarcreg .membership .tableSection .tr .td:last-child {
    flex: 0.5;
  }
}
.contextnarcreg .membership .tableSection .tr .td span {
  color: red;
  font-size: 50px;
}
.contextnarcreg .membership .note {
  float: left;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  color: #181818;
  padding: 0 40px;
  margin-top: 20px;
}
.contextnarcreg .testimonials {
  float: left;
  width: 100%;
  margin-top: 0;
  margin-bottom: 100px;
  padding-left: calc((100% - 1140px) / 2);
  padding-right: calc((100% - 1140px) / 2);
}
@media (max-width: 768px) {
  .contextnarcreg .testimonials {
    margin-top: 50px;
    padding: 0 15px;
    margin-bottom: 40px;
  }
}
@media (max-width: 480px) {
  .contextnarcreg .testimonials {
    margin-top: 60px;
    margin-bottom: 50px;
    padding: 0px;
  }
}
.contextnarcreg .testimonials .head {
  float: left;
  width: 100%;
  text-align: center;
  position: relative;
}
.contextnarcreg .testimonials .head::before {
  content: "";
  position: absolute;
  width: 20%;
  height: 5px;
  background-color: #285caa;
  border-radius: 25px;
  left: 40%;
  bottom: -20px;
}
@media (max-width: 768px) {
  .contextnarcreg .testimonials .head::before {
    width: 30%;
    left: 35%;
  }
}
.contextnarcreg .testimonials .head .title {
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  font-weight: bold;
  color: #393939;
}
@media (max-width: 768px) {
  .contextnarcreg .testimonials .head .title {
    font-size: 24px;
  }
}
@media (max-width: 480px) {
  .contextnarcreg .testimonials .head .title {
    font-size: 20px;
    line-height: 22px;
  }
}
.contextnarcreg .testimonials .head .subText {
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #393939;
  position: relative;
  margin-top: 5px;
}
@media (max-width: 480px) {
  .contextnarcreg .testimonials .head .subText {
    font-size: 16px;
  }
}
.contextnarcreg .testimonials .block {
  float: left;
  width: 100%;
  margin-top: 50px;
  padding: 0;
}
.contextnarcreg .testimonials .block .slick-list {
  padding: 0 0 0 3px;
}
.contextnarcreg .testimonials .block .slick-slider {
  padding: 0;
}
@media (max-width: 768px) {
  .contextnarcreg .testimonials .block .slick-slider {
    padding: 0;
  }
}
.contextnarcreg .testimonials .block .slick-slide {
  padding-right: 20px;
}
@media (max-width: 480px) {
  .contextnarcreg .testimonials .block .slick-slide {
    padding: 10px;
  }
}
.contextnarcreg .testimonials .block .slick-slide div {
  float: left;
  width: 100%;
}
.contextnarcreg .testimonials .block .slick-next,
.contextnarcreg .testimonials .block .slick-prev {
  z-index: 1;
}
.contextnarcreg .testimonials .block .slick-prev {
  left: -14px;
  font-size: 25px;
  color: #000;
  width: 30px;
  height: 30px;
  line-height: 47px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
  z-index: 3;
}
@media (max-width: 768px) {
  .contextnarcreg .testimonials .block .slick-prev {
    left: -4px;
    top: 50%;
    width: 30px;
    height: 30px;
    border-radius: 0;
  }
}
.contextnarcreg .testimonials .block .slick-prev .icon {
  position: relative;
  top: -9px;
  left: 1px;
  font-size: 23px;
}
@media (max-width: 768px) {
  .contextnarcreg .testimonials .block .slick-prev .icon {
    top: -8px;
    left: -1px;
    font-size: 23px;
  }
}
.contextnarcreg .testimonials .block .slick-prev .icon:before {
  color: #c00096;
}
.contextnarcreg .testimonials .block .slick-next {
  right: 0;
  font-size: 25px;
  color: #000;
  width: 30px;
  height: 30px;
  line-height: 47px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  z-index: 3;
}
@media (max-width: 768px) {
  .contextnarcreg .testimonials .block .slick-next {
    right: -8px;
    top: 50%;
    width: 30px;
    height: 30px;
    border-radius: 0;
  }
}
.contextnarcreg .testimonials .block .slick-next .icon {
  position: relative;
  top: -9px;
  right: -1px;
  font-size: 23px;
}
@media (max-width: 768px) {
  .contextnarcreg .testimonials .block .slick-next .icon {
    top: -8px;
    right: -1px;
    font-size: 23px;
  }
}
.contextnarcreg .testimonials .block .slick-next .icon:before {
  color: #c00096;
}
.contextnarcreg .testimonials .block .slick-next:before,
.contextnarcreg .testimonials .block .slick-prev:before {
  font-size: 30px;
  color: #393939;
  opacity: 1;
  display: none;
}
.contextnarcreg .testimonials .block .slick-dots {
  display: none !important;
}
@media (max-width: 768px) {
  .contextnarcreg .testimonials .block .slick-dots {
    display: none !important;
  }
}
.contextnarcreg .testimonials .block .slick-dots li {
  width: 8px;
  height: 8px;
}
.contextnarcreg .testimonials .block .slick-dots li button {
  border: solid 2px #285caa;
  border-radius: 100%;
  width: 8px;
  height: 8px;
}
.contextnarcreg .testimonials .block .slick-dots li button::before {
  font-size: 14px;
  top: -2px;
  opacity: 0;
  color: #285caa;
  width: 8px;
  height: 8px;
}
.contextnarcreg .testimonials .block .slick-dots li.slick-active button:before {
  opacity: 1;
}
.contextnarcreg .testimonials .block .slick-track {
  display: flex !important;
}
.contextnarcreg .testimonials .block .slick-slide {
  height: inherit !important;
}
.contextnarcreg .testimonials .block .slick-slide > div {
  display: flex;
  width: 100%;
  height: 100%;
}
.contextnarcreg .testimonials .block .testimonial {
  float: left;
  width: 100%;
  padding: 20px;
  background-color: #fff;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  margin-top: 10px;
  margin-bottom: 5px;
  border-radius: 15px;
}
.contextnarcreg .testimonials .block .testimonial.videoPost {
  position: relative;
  padding: 0;
}
.contextnarcreg .testimonials .block .testimonial.videoPost .artPlot {
  display: none;
}
.contextnarcreg .testimonials .block .testimonial.videoPost .content {
  display: none;
}
.contextnarcreg .testimonials .block .testimonial.videoPost .video {
  display: block;
}
.contextnarcreg .testimonials .block .testimonial .video {
  float: left;
  display: none;
  width: 100%;
  height: 180px;
  border-radius: 15px;
  z-index: -1;
}
.contextnarcreg .testimonials .block .testimonial .artPlot {
  float: left;
  width: 80px;
}
.contextnarcreg .testimonials .block .testimonial .artPlot .img {
  float: left;
  width: 80px;
  height: 120px;
  border: solid 1px #285caa;
  border-radius: 15px;
}
.contextnarcreg .testimonials .block .testimonial .artPlot .img img {
  float: left;
  width: 80px;
  height: 120px;
  border-radius: 15px;
  margin-left: 5px;
  margin-top: -5px;
  margin-bottom: 5px;
  object-fit: cover;
  object-position: center top;
}
.contextnarcreg .testimonials .block .testimonial .text {
  float: left;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #393939;
  padding-top: 8px;
  text-align: left;
}
.contextnarcreg .testimonials .block .testimonial .content {
  float: left;
  width: calc(100% - 80px);
  padding-left: 30px;
  padding-right: 15px;
}
.contextnarcreg .testimonials .block .testimonial .content .testimonialText {
  float: left;
  width: 100%;
  min-height: 100px;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  color: #393939;
  display: flex;
  align-items: center;
  text-align: center;
}
.contextnarcreg .testimonials .block .testimonial .content .testimonialauthor {
  float: right;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  color: #777;
  margin-top: 5px;
}
.contextnarcreg .testimonials {
  margin-top: 100px;
}
@media (max-width: 480px) {
  .contextnarcreg .testimonials {
    margin-top: 50px;
  }
} /*# sourceMappingURL=narc-register.css.map */

/* ssd */
@media (max-width:1380px) {
  .narcreg-formBanner .form-frame .formSection{
    min-height: unset;
    width: 380px;
    margin-left: 20px;
  }
  .narcreg-formBanner .form-frame .formSection .form.stepOne {
    margin-top: 16px;
  }
  .narcreg-formBanner .form-frame .formSection .form.stepOne .submitMe {
    margin-top: 10px;
  }
  .narcreg-formBanner .form-frame .formSection .form .pagenation {
    margin-top: 20px;
  }
}
@media (max-width:1025px) {
  .narcreg-formBanner .form-frame .formSection{
    min-height: unset;
    max-width: 480px;
    margin-top: 70px;
    margin-left: 0px;
    padding: 40px 42px;
  }
}
@media (max-width:960px) {
  .narcreg-formBanner .form-frame .formSection{
    margin-top: 40px;
    margin-left: 0px;
  }
}
@media (max-width:840px) {
  .narcreg-formBanner .form-frame .formSection{
    margin-top: 20px;
    margin-left: 0px;
    padding: 16px 30px;
  }
}
@media (max-width:768px) {
  .narcreg-formBanner .form-frame .formSection{
    margin-top: 150px;
    margin-left: 0px;
  }
}

/*  */
.atl-team-reg.atl-tinker-register .bannerImgMob img{
  display: none;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: 0% 0%;
}


@media (max-width: 1160px) {
  .container-kidex.mt-5.ns-quiz {
    padding: 0px 40px;
  }
  .ns-quiz .text-block {
    text-align: justify;
  }
  .ns-quiz .heads-title::before {
    width: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: -22px;
  }
}
@media (max-width: 768px){
  .atl-team-reg.atl-tinker-register .bannerImgMob img{
    display: block;
    height: 560px;
    width: 100%;
    object-fit: cover;
    object-position: 0% 0%;
  } 
  .atl-team-reg.atl-tinker-register .bannerImg img {
    display: none;
  }
}
@media (max-width:640px) {
  .atl-team-reg.atl-tinker-register .bannerImgMob img{
    height: 500px;
  }
}
@media (max-width:540px) {
  .atl-team-reg.atl-tinker-register .bannerImgMob img{
    height: 460px;
  }
}
@media (max-width:480px) {
  .atl-team-reg.atl-tinker-register .bannerImgMob img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center 0%;
  }
  .narcreg-formBanner .form-frame .formSection{
    min-height: unset;
    width: 100%;
    margin-left: unset;
    padding: 20px 20px;
  }
  .narcreg-formBanner .form-frame .formSection .form.stepOne {
    margin-top: 16px;
  }
  .narcreg-formBanner .form-frame .formSection .form.stepOne .submitMe {
    margin-top: 12px;
  }
  .narcreg-formBanner .form-frame .formSection .form .pagenation {
    margin-top: 20px;
  }
  /*  */
  /* .atl-team-reg.atl-tinker-register .bannerImgMob img{
    display: none;
  } */
  /* .atl-team-reg.atl-tinker-register .bannerImg img {
    height: 120px;
    object-fit: cover;
    object-position: 38% 0%;
    display: none;
  } */
  .ns-quiz .heads-title .title {
    font-size: 18px;
  }
  .ns-quiz .heads-title:before {
    width: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .ns-quiz .text-block a {
    width: 100%;
    display: block;
  }
  .ns-quiz .text-block ul {
    padding: 0px 18px;
  }
  .ns-quiz .text-block ul li {
    text-align: justify;
  }
}