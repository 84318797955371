* {
  box-sizing: border-box;
}

body {
  line-height: unset !important;
}

ul {
  margin: 0px;
  padding: 0px;
}

body {
  margin: 0px;
  font-family: "Montserrat";
  font-size: 16px;
  color: #000;
}

img {
  max-width: 100%;
}

.banner-img {
  width: 100%;
  width: 100%;
  object-fit: cover;
}

.allbooking .banner-img {
  width: 100%;
  width: 100%;
  object-fit: cover;
  height: 355px;
}

div#nav-tab {
  border: none;
}

header.header {
  padding: 10px 0 10px;
}

/* .logo img {
  max-width: 108px;
} */
.btn-group.profile-menu {
  float: right;
}

.profile-menu button.dropdown-toggle {
  border: none;
  background: none;
}

.profile-menu .dropdown-toggle::after {
  display: none;
}

.profile-menu ul.dropdown-menu.dropdown-menu-end {
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border: none;
  border-radius: 5px;
  top: 20px !important;
}

.profile-menu ul.dropdown-menu.dropdown-menu-end::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.9em solid;
  border-right: 0.8em solid transparent;
  border-bottom: 0;
  border-left: 0.8em solid transparent;
  position: absolute;
  transform: rotate(180deg);
  color: #fff;
  top: -12px;
  right: 23px;
}

.background-image {
  position: relative;
  display: contents;
}

.image-btn-option {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  max-width: 634px;
  width: 100%;
}

label.replace-btn {
  position: relative;
  background: rgba(40, 40, 40, 0.64);
  border-radius: 52px;
  padding: 15px 0px;
  font-size: 22px;
  color: #fff;
  text-align: center;
  font-weight: 600;
}

label.replace-btn input[type="file"] {
  max-width: 280px;
  width: 100%;
  opacity: 0;
  position: relative;
  z-index: 2;
  height: 0;
}

label.replace-btn span {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

label.remove-btn button {
  background: none;
  border: none;
  color: #000;
  font-size: 22px;
  font-weight: 600;
}

.profile-name {
  width: 100%;
  justify-content: center;
  margin-top: -40px;
  position: relative;
  font-weight: 600;
  font-size: 24px;
  z-index: 999;
}

.profile-name button.dropdown-toggle {
  background: #fff;
  border: none;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 62px;
  font-weight: 600;
  font-size: 24px;
  padding: 25px 60px 25px 60px;
  z-index: 2;
  color: #000;
}

.profile-menu button.dropdown-toggle img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.profile-name ul.dropdown-menu {
  width: 100%;
  max-width: 298px;
  background: #f7f7f7;
  border: none;
  border-radius: 0 0 20px 20px !important;
  padding-top: 84px;
  top: -55px !important;
  z-index: 1;
  font-weight: 600;
  font-size: 26px;
  text-align: center;
}

.profile-name li a {
  padding-top: 15px;
  padding-bottom: 15px;
  font-weight: 600;
}

li.add-new {
  border-top: 2px solid #ddd;
  font-size: 24px;
}

li.add-new a {
  color: #999;
  text-decoration: underline;
}

nav.tab-main {
  border-bottom: 3px solid #dadada;
  margin-top: 70px;
}

.tab-main .nav-link {
  border: none;
  margin-bottom: 0;
  color: #282828;
  font-weight: 500;
  font-size: 22px;
  position: relative;
  margin-right: 40px;
}

.tab-main .nav-link.active {
  color: #08316e;
  font-weight: 600;
}

.tab-main .nav-link.active:after {
  width: 100%;
  content: "";
  height: 5px;
  position: absolute;
  left: 0;
  background: #08316e;
  bottom: -4px;
  border-radius: 20px;
}

.tags {
  margin-top: 50px;
  box-shadow: 0px 3px 22px rgb(0 0 0 / 24%);
  border-radius: 10px;
  padding: 15px 30px;
  margin-bottom: 70px;
}

.top-tags {
  padding: 12px;
}

.top-tags button {
  list-style: none !important;
  display: inline-block !important;
  margin-right: 20px !important;
  background: #f7f7f7 !important;
  border-radius: 54px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  cursor: pointer !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.top-tags button.active:after {
  width: unset !important;
}

.top-tags button.active {
  background: #ffc5f2 !important;
  color: #c41d8e !important;
}

.top-tags button:hover {
  background: #ffc5f2 !important;
  color: #c41d8e !important;
}

h2.heading_h2 {
  font-weight: 700;
  font-size: 32px;
  color: #2b5caa;
  text-align: center;
}

/* .swiper-button-next:after,
.swiper-button-prev:after {
  display: none;
} */

/* .swiper-button-next,
.swiper-button-prev {
  background: #eeeeee;
  border-radius: 50%;
  width: 50px;
  height: 50px;
} */

.books-section .swiper {
  margin-bottom: 25px;
  margin-top: 25px;
}

.books-section {
  /* margin-bottom: 80px; */
  margin-top: 25px;
}

.view-btn a {
  color: #000;
  text-decoration: none;
}

.view-btn a:hover {
  color: #fff;
  text-decoration: none;
}

.view-btn button {
  background: #ffffff;
  border: 2px solid #c41d8e;
  border-radius: 49px;
  padding: 0px 25px;
  color: #000;
  font-weight: 400;
  font-size: 16px;
  width: 160px;
  line-height: 35px;
  font-family: "Montserrat", sans-serif;
}

.view-btn button:hover {
  background: #c41d8e;
}

.view-btn button img {
  width: 11px;
  margin-left: 9px;
}

.view-btn {
  text-align: center;
  margin-bottom: 80px;
  margin-top: 40px;
}

.slider-outer {
  position: relative;
}

/* .swiper-button-next {
  right: -25px;
  left: auto;
} */

/* .swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: -25px;
  right: auto;
} */

label.remove-btn {
  margin-left: 40px;
}

.slider-outer .swiper-slide img {
  width: 100%;
  border-radius: 5px;
}

.grid-image img {
  margin-bottom: 30px;
  width: 420px;
  border-radius: 5px;
  height: 236px;
}

.overlay-body {
  position: fixed;
  left: 0;
  top: 0;
  background: #000;
  z-index: 99;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  display: none;
}

.body-ac .overlay-body {
  display: block;
}

.logo a:hover,
label.remove-btn button:hover {
  opacity: 0.6;
}

a,
button,
li {
  transition: all 0.5s ease;
}

/*09 - April*/
.main-content {
  margin-top: 70px;
}

h2.heading_h2.head_after {
  position: relative;
  padding-bottom: 50px;
  margin-bottom: 50px;
}

h2.heading_h2.head_after:after {
  content: "";
  height: 10px;
  background: #567cb9;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 10px;
  max-width: 280px;
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
}

.tab-main2 {
  margin-top: 100px;
  box-shadow: 0 1.5px 3px 0 rgb(0 0 0 / 16%);
  border-radius: 10px;
  padding: 0px 20px;
  margin-bottom: 60px;
}

.tab-main2 div#nav-tab {
  justify-content: space-between;
}

.tab-main2 .nav-tabs .nav-link.active {
  color: #c41d8e;
  border: none;
  position: relative;
}

.tab-main2 .nav-tabs .nav-link {
  color: #282828;
  font-size: 21px;
  max-width: 266px;
}

.all-booking .tab-main2 .nav-tabs .nav-link {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  padding: 0;
  margin-right: 0px;
  /* width: 150px; */
  text-align: center;
  flex-wrap: nowrap;
  align-self: flex-start;
  min-height: 50px;
  line-height: 26px;
  padding: 0px 18px;
  display: inline-block;
}

.tab-main2 .nav-tabs .nav-link.active:after {
  width: 100%;
  height: 5px;
  content: "";
  position: absolute;
  left: 0;
  bottom: 1px;
  background: #c41d8e;
}

.tab-main2 .nav-tabs .nav-link:hover {
  border: none !important;
}

.activities-section {
  box-shadow: 0px 2px 11px rgb(0 0 0 / 24%);
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 30px;
}

.activities-section img {
  width: 100%;
}

.activities-section a {
  text-decoration: none;
  color: inherit;
}

.activities-bottom {
  padding: 20px 15px;
}

.activities-bottom .details {
  background: #ffc5f2;
  color: #c41d8e;
  padding: 5px 8px;
  float: right;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  padding: 2px 3px;
  text-decoration: none;
  font-weight: 400;
}

.activities-bottom h4 {
  margin-bottom: 0;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 600;
}

.activities-bottom a {
  background: #ffc5f2;
  color: #c41d8e;
  padding: 5px 8px;
  float: right;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  padding: 2px 3px;
  text-decoration: none;
  font-weight: 400;
}

.anchor-btn {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.anchor-btn button {
  background: #c41d8e;
  color: #fff;
  text-decoration: none;
  padding: 3px 30px;
  font-size: 16px;
  border-radius: 70px;
  border: none;
  line-height: 35px;
}

.img-100 {
  width: 100%;
}

/* .view-btn button:after {
  content: "";
  background: url(../images/arrow.svg);
  width: 11px;
  height: 6px;
  display: inline-block;
  background-size: cover;
  margin-left: 10px;
  transition: all 0.5s;
} */
.view-btn button.btn-arrow:after {
  transform: rotate(180deg);
}

.grid-image {
  margin-bottom: 25px;
  margin-top: 25px;
}

.profile-name ul.dropdown-menu[data-popper-placement="top-start"] {
  padding-top: 0;
  border-radius: 20px !important;
}

/*09 - April end */
/*activity uploader design  */
.activity-img-uploader .head .title {
  display: none;
}

.activity-img-uploader .file-img,
.activity-img-uploader .input-label,
.upload-img-act img.upload-img {
  display: none;
}

.activity-img-uploader form.form .formGroup label {
  font-size: 14px;
  font-weight: 500;
  margin-top: 18px;
}

.activity-img-uploader .file-img img {
  width: 100px;
  margin-bottom: 4px;
}

.activity-img-uploader .input.file-input {
  display: block;
  width: 90%;
  margin: 15px auto;
  border: solid 1px #707070;
  border-radius: 5px;
  border-style: dashed;
  text-align: center;
  padding: 10px 0;
  position: relative;
  max-height: 160px;
  max-width: 360px;
}

.activity-img-uploader .input.file-input input#file-input {
  position: absolute !important;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  opacity: 0;
}

.activity-img-uploader .input .input-label .text {
  font-weight: 500;
}

.activity-img-uploader .input .input-label .text.or {
  font-weight: normal;
  padding: 6px 0px;
}

.activity-img-uploader .input .input-label .text.browse {
  color: #2b5caa;
}

.activity-details .add-img {
  text-align: center;
  margin-bottom: 1rem;
  display: none;
}

.activity-details .add-img button {
  background-color: #c00096;
  padding: 1rem 8rem;
  border: none;
  border-radius: 8px;
  color: #fff;
  font-weight: 700;
  margin: 1rem 0;
}

.quiz-end-msg {
  font-size: 16px;
}

/*Responsive staart */
@media only screen and (max-width: 1024px) {
  .profile-name button.dropdown-toggle {
    font-size: 28px;
    padding: 19px 40px 20px 32px;
  }

  .profile-name {
    margin-top: -42px;
  }
}

@media only screen and (max-width: 991px) {
  .tags {
    margin-top: 50px;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .profile-name {
    margin-top: 25px;
  }

  label.replace-btn input[type="file"] {
    max-width: 176px;
  }

  label.replace-btn span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0;
    padding-top: 18px;
  }

  label.replace-btn,
  label.remove-btn button {
    font-size: 16px;
  }

  label.remove-btn {
    margin-left: 10px;
  }

  .image-btn-option {
    padding: 0 15px;
  }

  .profile-name button.dropdown-toggle {
    font-size: 20px;
    padding: 19px 40px 20px 32px;
  }

  nav.tab-main {
    margin-top: 50px;
  }

  .tab-main .nav-link {
    font-size: 18px;
    margin-right: 15px;
  }

  .top-tags button {
    padding: 10px 14px;
    font-size: 14px;
    font-weight: 500;
    margin-right: 20px;
  }

  h2.heading_h2 {
    font-size: 28px;
  }

  .tab-main2 .nav-tabs .nav-link {
    color: #282828;
    font-size: 12px;
    /* max-width: 140px; */
  }

  .tab-main2 {
    margin-top: 40px;
  }
}

/* /// */
.mySwiper1 .swiper-wrapper .swiper-slide {
  width: 25%;
  margin: 4px;
  /* display: flex; */
}

#pc1 {
  transition: height 2s, transform 2s;
}

.grid-image.grid-image1.mb-0 img {
  margin: 0;
}

.list-activities .nav-tabs .nav-link.active,
.list-activities .nav-tabs .nav-link:hover {
  border-color: transparent !important;
}

.list-activity-sub-tab ul {
  margin: 0px;
}

/* Activity detail */
#top-sec1,
#top-sec2,
#top-sec3 {
  transition: all 2s ease 0s;
}

/* All Bookings */
.all-bookings {
  padding: 12px;
  background: #567cb9;
  box-shadow: 0px 10px 17px #bbd4fc;
  border-radius: 20px;
  width: 80%;
  margin: 0 auto;
}

.all-bookings .booking-tab-btn {
  list-style: none !important;
  display: inline-block !important;
  margin-right: 20px !important;
  background: #f7f7f7 !important;
  border-radius: 54px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  cursor: pointer !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  width: 200px;
  padding: 14px;
  border: none !important;
  color: #c41d8e;
  text-align: center;
  text-decoration: none;
}

.all-bookings .booking-tab-btn.active:after {
  width: unset !important;
}

.all-bookings .booking-tab-btn.active {
  background: #ffc5f2 !important;
  color: #c41d8e !important;
}

.all-bookings .booking-tab-btn:hover {
  background: #f7f7f7 !important;
  color: #c41d8e !important;
}

/* Organizer Tab */
.kidex-org-messages .accordion-item {
  border-radius: 3px;
  box-shadow: 0 0 5px rgb(0 0 0 / 30%);
  margin-bottom: 30px;
  padding: 14px 30px;
  font-family: "Montserrat", sans-serif;
}

.kidex-org-messages {
  margin: 40px 0px;
}

.kidex-org-messages .accordion-item {
  border: unset !important;
}

.kidex-org-messages button {
  font-weight: 600;
  color: #1c2b3e;
  opacity: 1;
  text-decoration: none;
  font-size: 16px;
}

.kidex-org-messages .accordion-button:not(.collapsed),
.kidex-org-messages .accordion-button:focus {
  background-color: #fff !important;
  color: #000 !important;
  box-shadow: unset !important;
}

.kidex-org-messages .accordion-button:not(.collapsed)::after {
  background-image: unset !important;
  content: "-";
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
  position: absolute;
  left: -10px;
  transform: unset !important;
  -webkit-transform: unset !important;
}

.kidex-org-messages .accordion-button::after {
  background-image: unset !important;
  content: "+";
  position: absolute;
  left: -10px;
  transform: unset !important;
  -webkit-transform: unset !important;
}

.competetion-data {
  width: 70%;
  margin: 70px auto;
}

.competetion-data .nav-tabs .nav-link {
  padding: 10px;
  width: 30%;
}

.competetion-data .nav-tabs .nav-link {
  padding: 10px;
  width: 30%;
  border: none !important;
  outline: none !important;
}

.competetion-data .tab-main2 .nav-tabs .nav-link:focus-visible {
  border: none !important;
  outline: none !important;
}

.all-booking .nav-tabs .nav-link {
  border: none !important;
}

.all-booking .tab-main2 {
  border: none !important;
  border-radius: none !important;
  /* margin-top: 90px !important; */
  width: 88%;
  margin: 0 auto;
  padding: 8px 30px;
  overflow: auto;
}

.maxContent {
  width: max-content !important;
}

.all-booking .tab-content {
  width: 88%;
  margin: 0 auto;
}

.all-booking .participate-details {
  margin-bottom: 70px;
}

/* organizer details tab */
.kidex-org-messages li {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #000 !important;
}

.wrapper.organizer-wrapper {
  margin-top: 34px;
}

.wrapper.organizer-wrapper .nav-pills button {
  text-align: center;
  float: left;
  width: 100%;
  height: 70px;
  border-radius: 4px;
  background-color: #fff;
  position: relative;
  padding: 23px 16px;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.2px;
  color: #2b5caa;
  font-weight: 600;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.organizer-wrapper .narc-details ul li .active {
  color: #fff !important;
  background-color: #2b5caa !important;
}

.wrapper.organizer-wrapper .nav-pills li.nav-item {
  float: left;
  width: calc((100% - 105px) / 4);
  margin-right: 25px;
  margin-bottom: 30px;
  cursor: pointer;
  position: relative;
  border-radius: 6px;
  border: solid 1px #2b5caa;
  box-shadow: 0 1.5px 3px 0 rgb(0 0 0 / 16%);
}

.formtop-banner img {
  height: 600px;
  object-fit: cover;
}

.context-narchome .participate-details .participate-info .head::before {
  left: 40%;
}

/*  */
body {
  font-family: "Montserrat", sans-serif !important;
}

.banner-sec {
  height: 520px !important;
  width: 100%;
  background-image: url("../../Assets/banner/submission.svg") !important;
  position: relative;
}

.banner-title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.banner-title img {
  height: auto;
  max-width: 350px;
  max-height: 100px;
  width: 100%;
}

.select-users {
  position: absolute;
  bottom: 0%;
  transform: translate(-50%, 50%);
  width: 300px;
  left: 50%;
}

.form-select.select-users-option {
  text-align: center;
  font-weight: 600;
  padding: 12px 10px !important;
  border-radius: 24px !important;
  box-shadow: 0px 2px 2px #c7c4c4;
  border: none;
  background-position: right 3.75rem center !important;
  /* background-image: url("./images/drop-icon.png"); */
  background-size: 14px;
}

.form-select.select-users-option:focus {
  outline: none;
  box-shadow: 0px 2px 2px #c7c4c4;
  border: none;
}

.tab-header {
  padding: 20px 0px;
  margin-top: 30px;
}

.tab-header .nav-tabs {
  border: none;
  border-bottom: 1px solid #ddd;
}

.tab-header ul li {
  position: relative;
  padding: 12px 0px;
  margin: 0px 20px;
}

.tab-header ul li button {
  color: #282828;
  padding: 0px;
}

.tab-header ul li button.active {
  color: #08316e !important;
  font-weight: 400;
}

.tab-header ul li button.active::before {
  /* border: 5px solid #08316e; */
  content: " ";
  height: 5px;
  width: 100%;
  position: absolute;
  bottom: -2px;
  background-color: #08316e;
  border-radius: 5px;
}

.tab-header ul li:first-child button {
  padding-left: 0px;
}

.tab-post-sec .post-banner {
  padding: 20px 0px 50px 0px;
}

.post-banner img {
  width: 100%;
  height: 350px;
  border-radius: 8px;
}

.tab-post-sec {
  border-bottom: 3px solid #ddd !important;
}

/* common css */
.post-banner .mobile-video {
  width: 100%;
  border-radius: 8px;
  height: 500px;
  object-fit: cover;
  max-height: 500px;
}

.box-outer-sec {
  padding: 20px 0px;
  border-top: 3px solid #dddd;
  border-bottom: 3px solid #ddd;
}

.sec-heading h5 {
  position: relative;
  width: max-content;
  padding-right: 4px;
  color: #08316e;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 18px;
}

.sec-heading h5::before {
  content: " ";
  height: 5px;
  width: -webkit-fill-available;
  position: absolute;
  bottom: -12px;
  background-color: #08316e;
  border-radius: 5px;
}

/* common css */

.details-list-sec {
  padding: 35px 0px;
}

.details-list-sec ul li::before {
  content: " ";
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #cd00a0;
  margin-right: 20px;
  padding: 5px !important;
  /* display: inline-block; */
}

.details-list-sec ul li {
  line-height: 36px;
  color: #333333;
  display: flex;
  align-items: baseline;
}

.grades-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.grades-icons img {
  max-height: 80px;
  height: auto;
  max-width: 80px;
}

.grades-icons img:first-child {
  height: auto;
  max-height: 100px;
  width: 100px !important;
}

/* second tab */
.post-card {
  background-color: #e6e7e8;
  border-radius: 8px;
  margin: 25px 0px;
}

.post-card-content {
  padding: 20px 20px;
}

.post-card-content h6 {
  color: #282828;
  font-weight: 600;
}

@media only screen and (max-width: 420px) {
  .tab-header ul li {
    margin: 0px auto;
  }

  .grades-icons img:first-child {
    max-width: 16%;
  }

  .grades-icons img {
    max-width: 16%;
  }
}

.activity-guidlines .nav button {
  border: none;
  background: none;
  margin: 0px;
  padding: 0px;
}

.activity-guidlines,
.activity-guidlines .nav-tabs {
  width: 100%;
}

.activity-guidlines .nav-tabs {
  justify-content: space-between !important;
  display: flex;
}

.activity-guidlines .nav-tabs .nav-link.active {
  transform: scale(1.2);
  transition: 0.5s;
}

.activity-details .tab-header ul li button.active {
  font-weight: 600 !important;
}

.activity-details .tab-header ul li button {
  font-weight: 500;
  font-size: 18px;
}

.detail-sec {
  border-top: 6px solid #ddd !important;
}

@media only screen and (max-width: 480px) {
  .wrapper.organizer-wrapper .nav-pills li.nav-item {
    width: calc((96%) / 2);
    margin: 2px;
    margin-bottom: 15px;
  }

  .wrapper.organizer-wrapper .nav-pills button {
    height: 50px;
    font-size: 15px;
  }

  .allbooking .narcreg-formBanner .bannerImg {
    position: unset !important;
  }

  .post-banner .mobile-video {
    width: 100%;
    border-radius: 8px;
    height: 180px;
    object-fit: cover;
    max-height: 500px;
  }

  .allbooking .profile-name {
    margin-top: 0px;
  }

  .narc-wrapper .tab-main2 .nav-tabs .nav-link {
    width: 300px !important;
  }
}

.add-new-child {
  position: absolute;
  bottom: 0%;
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
  width: 300px;
  left: 50%;
  background-color: #fff;
  border: none !important;
  box-shadow: 0px 2px 2px #c7c4c4;
  text-align: center;
  font-weight: 600;
  padding: 10px 10px !important;
  border-radius: 62px !important;
  font-size: 19px;
  text-decoration: none;
  color: #000;
}

.not-found {
  text-align: center;
}

.narc-submission-form .formfram .formSection {
  min-height: 430px !important;
}

/* subbmision section 5 may css */
#submissions .sub-emp-content-btn button.activity-pink-btn {
  padding: 0px;
}

#submissions .sub-emp-content-btn button.activity-pink-btn label {
  padding: 6px !important;
  cursor: pointer;
}

#submissions .sub-emp-img {
  max-width: 600px;
  margin: 5rem auto;
  width: 100%;
  padding: 0px 20px;
}

#submissions .sub-emp-content p {
  margin: 0px;
  font-size: 24px;
}

/* comman css */
.btn.activity-pink-btn {
  background-color: #cd00a0;
  box-shadow: 1px 1px 5px 0px #888;
  border-radius: 24px;
  color: #fff;
  font-weight: 400;
}

.btn.modal-close-btn {
  border: 1px solid #000;
  border-radius: 24px;
  font-weight: 400;
  color: #000;
}

.mdl-header h5 {
  font-weight: 600;
}

.mdl-footer {
  display: flex;
  justify-content: space-around;
}

.modal-content .form-check-input:checked {
  background-color: #cd00a0;
  border-color: #cd00a0;
}

.modal-content .form-check-input:focus {
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.25);
}

/*  */
#submissions #drop-area {
  position: relative;
}

#submissions .uploader {
  display: none;
}

#submissions #fileElem {
  display: none;
}

#submissions .upload-label {
  padding: 1rem;
  cursor: pointer;
  border-radius: 6px;
  box-sizing: border-box;
  max-height: 250px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #000;
  border-style: dashed;
}

#submissions .upload-label:hover {
  background-color: #f2f2f2;
}

#submissions .upload-text img {
  width: 100%;
  height: 100%;
}

#submissions .image.col-sm-6.col-md-6 {
  height: 100%;
  max-height: 250px;
  object-fit: cover;
  object-position: 100% 20%;
  padding: 0px;
  border-radius: 20px;
  border: 5px solid #fff;
}

#submissions .uploader-outer {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

#submissions .submit-img img {
  max-height: 100px;
}

/*  */
#submissions .submissin-post-heading {
  display: flex;
  justify-content: space-between;
  padding: 20px 0px;
}

#submissions .submissin-post-heading h5,
#submissions .submissin-post-heading span {
  color: #000;
  font-weight: 600;
}

/* colors class*/
.status-beginner {
  color: #9868ff !important;
}

.status-awaited {
  color: #f6d10c !important;
}

.grade-status.success {
  color: #00da57 !important;
}

.grade-status.warning {
  color: #f6d10c !important;
}

.grade-status.danger {
  color: #ff2020 !important;
}

.pull-right {
  float: right;
}

.grade-resubmit {
  color: #fff;
  background: #00da57;
  border-radius: 50%;
  padding: 5px;
  width: 20px;
  height: 20px;
  display: inline-block;
  line-height: 11px;
}

.status-notsubmited {
  color: #ff2020 !important;
}

/*  */
#submissions .submissin-poster-post img {
  width: 100%;
  height: auto;
  object-fit: contain !important;
  max-height: 650px;
}

#submissions .submissin-poster-post .carousel {
  height: 640px !important;
}

#submissions .submissin-poster {
  border-top: 5px solid #dddd;
  border-bottom: 5px solid #dddd;
}

#submissions .submissin-poster:first-child {
  border-top: none;
}

#submissions .submission-post-profile img {
  height: 60px;
  border-radius: 50%;
  width: 60px;
  margin-top: 10px;
}

#submissions .submission-post-details h6 {
  color: #5195ff;
  font-weight: 600;
}

#submissions .submission-post-details span {
  color: #666666;
  font-weight: 600;
}

#submissions .submission-post-details span.sub-upload-time {
  color: #000;
  font-weight: 400;
}

#submissions .submission-post-details span.sub-upload-time::before {
  content: ".";
  font-size: 30px;
  font-weight: 600;
  padding: 0px 8px;
  color: #c4c4c4;
}

/* #submissions .submission-post-footer span svg {
  font-size: 28px;
  margin-top: 14px;
} */

.sbmsn-poster-re-submit p a {
  color: #cd00a0;
  padding: 0px 10px;
  font-weight: 600;
}

.sbmsn-poster-re-submit p span {
  color: #cd00a0;
  padding: 0px 10px;
  font-weight: 600;
  cursor: pointer;
}

#submissions .resubmit-mdl-btn button {
  border-radius: 5px !important;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}

#submissions .resubmit-mdl-btn button img {
  height: 20px;
  margin: auto 0px;
}

#submissions .submissin-old-bnr h4 {
  font-weight: 600;
}

.submissin-poster-post-footer {
  background-color: #f2f2f2;
  padding: 10px;
  margin-bottom: 15px;
}

#submissions .submissin-poster-post-footer p {
  color: #282828;
}

/*  */
@media only screen and (max-width: 540px) {

  /* tab header css */
  #submissions .tab-header {
    overflow: auto;
  }

  #submissions .tab-header .nav-tabs {
    width: max-content;
  }

  #submissions .tab-header ul li {
    padding: 12px 15px;
  }

  #submissions .submission-post-details h6 {
    margin: 0px;
  }

  #submissions .submissin-post-heading h5,
  #submissions .submissin-post-heading span {
    font-size: 15px;
  }

  #submissions .submissin-post-heading {
    padding: 10px 0px;
  }

  #submissions div#proceed-review .modal-body,
  #submissions div#submit-review .modal-body,
  #submissions div#staticBackdrop .modal-body,
  #submissions div#languages .modal-body,
  #submissions div#submit-sbmsn .modal-body {
    padding: 10px 20px !important;
  }
}

/* Narc 22 activity */
.narc-wrapper .tab-main2 {
  margin: 0px !important;
}

.narc-wrapper .tab-main2 .nav-tabs .nav-link {
  width: unset !important;
}

.cstm-age-dropdown {
  width: 76%;
  margin: auto;
}

.cstm-age-dropdown .ui.fluid.search.selection.dropdown {
  float: right !important;
  width: max-content;
  min-width: auto !important;
  border-color: #c41d8e !important;
  border-radius: 30px !important;
  background-color: initial !important;
}

.cstm-age-dropdown .cstm-age-dropdown-selecter {
  float: right !important;
  width: max-content;
  min-width: auto !important;
  border-color: #c41d8e !important;
  border-radius: 30px !important;
  background-color: initial !important;
}

.add-child-btn {
  position: absolute;
  bottom: 0%;
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
  width: 300px;
  left: 50%;
}

/*  */
div#nav-tabContent.slider-custom-class .slick-arrow {
  z-index: 11;
}

/*  */
.allbooking .slick-arrow {
  font-size: 25px;
  color: #c00096;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background-color: #fff;
  z-index: 111;
  box-shadow: 0 0 4px rgb(0 0 0 / 40%);
}

.allbooking .slick-arrow::before {
  display: none;
}

.allbooking .slick-prev {
  top: 45%;
  left: -10px;
}

.allbooking .slick-next {
  top: 45%;
  right: -10px;
}

.allbooking .slick-arrow:hover {
  color: #c00096;
  box-shadow: 0 0 4px rgb(0 0 0 / 40%);
  background-color: #fff;
}

.tab-main2::-webkit-scrollbar {
  height: 6px !important;
}

/* ::-webkit-scrollbar-track {
  background:red !important;
} */
.tab-main2::-webkit-scrollbar-thumb {
  background: #999 !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #999 !important;
}

@media (max-width: 1340px) {
  .bookingTrialBanner .form-frame .formSection {
    min-height: unset;
    width: 370px;
    margin-left: 20px;
  }

  .bookingTrialBanner .form-frame .formSection .head .title {
    font-size: 24px;
  }

  .bookingTrialBanner .form-frame .formSection .form .formGroup .input input {
    font-size: 13px;
  }

  .bookingTrialBanner .form-frame .formSection .form .submitMe button {
    justify-content: center;
    display: flex;
    align-items: center;
    font-size: 15px;
  }

  .bookingTrialBanner .form-frame .formSection .form .pagenation ul li {
    height: 13px;
    width: 13px;
  }

  .bookingTrialBanner .form-frame .formSection .form .formGroup .input .ui.dropdown {
    padding: 10px 10px;
  }

  .bookingTrialBanner .form-frame .formSection .form.stepOne {
    margin-top: 16px;
  }

  .bookingTrialBanner .form-frame .formSection .form.stepOne .submitMe {
    margin-top: 10px;
  }

  .bookingTrialBanner .form-frame .formSection .form .pagenation {
    margin-top: 20px;
  }
}

@media (max-width: 1320px) {
  .all-booking .tab-main2 {
    width: 100%;
  }

  .all-booking .tab-content {
    width: 100%;
    margin: 0 auto;
  }
}

@media (max-width: 1025px) {
  .bookingTrialBanner .form-frame .formSection {
    min-height: unset;
    max-width: 480px;
    margin-top: 70px;
    margin-left: 0px;
  }
}

@media (max-width: 960px) {
  .bookingTrialBanner .form-frame .formSection {
    margin-top: 32px;
    margin-left: 0px;
    padding: 26px 31px;
    width: 320px;
  }

  .contextBookingTrial .workflow {
    margin-top: 120px;
  }
}

@media (max-width: 769px) {
  .bookingTrialBanner .form-frame .formSection {
    width: 304px;
    padding: 24px 31px;
    margin-top: 9px;
  }

  .bookingTrialBanner .form-frame .formSection .book-ribbon {
    top: -30px;
    right: -23px;
    width: 38%;
    margin-top: unset;
  }

  .activities-section {
    margin-bottom: 10px !important;
  }
}

/* (1366x768) WXGA Display */
@media screen and (max-width: 1366px) {
  .all-booking .tab-main2 {
    width: 100% !important;
  }
}

/* (1680x1050) WSXGA+ Display */
@media screen and (max-width: 1680px) {
  /* insert styles here */
}

/* *********** My Quiz Tab Css ****** */
/* my quiz tab css start */
.quiz-header-btns .nav-tabs {
  border: none;
  justify-content: center;
  margin-top: 2rem;
}

.quiz-content-outer {
  margin-top: 6rem;
  margin-bottom: 2rem;
}

.quiz-header-btns .nav-tabs .nav-link {
  border: none;
  padding: 10px 50px;
  border-radius: 26px;
  background-color: #ffe5f6;
  color: #767676;
  font-weight: 600;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  margin: 0px 26px;
}

.quiz-header-btns .nav-tabs .nav-link.active,
.quiz-header-btns .nav-tabs .nav-item.show .nav-link {
  background-color: #c41d8e;
  color: #ffffff;
}

/* common button pink button */
.quiz-detail-card-outer-sec .quiz-detail-card {
  margin-bottom: 10px;
}

.quiz-detail-card-outer-sec .quiz-detail-card h5 {
  font-weight: 600;
  color: #282828;
}

.quiz-detail-card-outer-sec .quiz-detail-card p {
  color: #282828;
}

.btn.quiz-button-pnk-comn {
  padding: 8px 50px;
  border-radius: 26px;
  background-color: #c41d8e;
  color: #ffffff;
  font-weight: 600;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}

.btn.quiz-button-pnk-comn:focus {
  box-shadow: none;
  color: #ffffff;
}

.btn.quiz-button-out-link-pnk-comn {
  padding: 8px 50px;
  border-radius: 26px;
  background-color: #ffffff;
  color: #c41d8e;
  font-weight: 600;
  border: 1px solid #c41d8e;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}

.btn.quiz-button-out-link-pnk-comn:focus {
  box-shadow: none;
  color: #c41d8e;
}

/* common button pink button end*/
.card-bottom-btn .btn.quiz-button-pnk-comn,
.card-bottom-btn .btn.quiz-button-out-link-pnk-comn {
  margin-top: 2rem;
  margin-left: 10px;
  margin-right: 10px;
}

/* past attemp tab css */
.past-attem-tab-header .nav-tabs li.nav-item {
  margin: 0px 12px;
}

.past-attem-tab-header .nav-tabs .nav-link {
  border: none;
  font-weight: 500;
  color: #282828;
  /* font-size: 18px; */
}

.past-attem-tab-header .nav-tabs button.nav-link.active {
  position: relative;
  color: #08316e;
  font-weight: 600;
}

.past-attem-tab-header .nav-tabs button.nav-link.active::before {
  content: " ";
  height: 5px;
  width: 100%;
  position: absolute;
  bottom: -2px;
  background-color: #08316e;
  border-radius: 5px;
  left: 0;
}

.past-attempt-tab-content .score-title h4 {
  margin: 2rem 0px;
  color: #c41d8e;
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .quiz-header-btns .nav-tabs {
    border: none;
    justify-content: center;
    margin-top: 2rem;
  }

  .quiz-content-outer {
    margin-top: 6rem;
    margin-bottom: 2rem;
  }

  .quiz-header-btns .nav-tabs .nav-link {
    border: none;
    padding: 10px 50px;
    border-radius: 26px;
    background-color: #ffe5f6;
    color: #767676;
    font-weight: 600;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    margin: 0px 26px;
  }

  .quiz-header-btns .nav-tabs .nav-link.active,
  .quiz-header-btns .nav-tabs .nav-item.show .nav-link {
    background-color: #c41d8e;
    color: #ffffff;
  }

  /* common button pink button */
  .quiz-detail-card-outer-sec .quiz-detail-card {
    margin-bottom: 10px;
  }

  .quiz-detail-card-outer-sec .quiz-detail-card h5 {
    font-weight: 600;
    color: #282828;
  }

  .quiz-detail-card-outer-sec .quiz-detail-card p {
    color: #282828;
  }

  .quiz-button-pnk-comn {
    padding: 8px 50px;
    border-radius: 26px;
    background-color: #c41d8e;
    color: #ffffff;
    font-weight: 600;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  }

  .quiz-button-pnk-comn:focus {
    box-shadow: none;
    color: #ffffff;
  }

  .quiz-button-out-link-pnk-comn {
    padding: 8px 50px;
    border-radius: 26px;
    background-color: #ffffff;
    color: #c41d8e;
    font-weight: 600;
    border: 1px solid #c41d8e;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  }

  .quiz-button-out-link-pnk-comn:focus {
    box-shadow: none;
    color: #c41d8e;
  }

  /* common button pink button end*/
  .card-bottom-btn .btn.quiz-button-pnk-comn,
  .card-bottom-btn .btn.quiz-button-out-link-pnk-comn {
    margin-top: 2rem;
    margin-left: 10px;
    margin-right: 10px;
  }

  /* past attemp tab css */
  .past-attem-tab-header .nav-tabs li.nav-item {
    margin: 0px 12px;
  }

  .past-attem-tab-header .nav-tabs .nav-link {
    border: none;
    font-weight: 500;
    color: #282828;
    /* font-size: 18px; */
  }

  .past-attem-tab-header .nav-tabs button.nav-link.active {
    position: relative;
    color: #08316e;
    font-weight: 600;
  }

  .past-attem-tab-header .nav-tabs button.nav-link.active::before {
    content: " ";
    height: 5px;
    width: 100%;
    position: absolute;
    bottom: -2px;
    background-color: #08316e;
    border-radius: 5px;
    left: 0;
  }

  .past-attempt-tab-content .score-title h4 {
    margin: 2rem 0px;
    color: #c41d8e;
    font-weight: 600;
  }
}

@media screen and (max-width: 476px) {
  .quiz-header-btns .nav-tabs {
    margin-top: 1rem;
  }

  .quiz-header-btns .nav-tabs .nav-link {
    margin: 10px 26px;
  }

  .quiz-content-outer {
    margin-top: 3rem;
  }

  .card-bottom-btn .btn.quiz-button-pnk-comn,
  .card-bottom-btn .btn.quiz-button-out-link-pnk-comn {
    margin-top: 1rem;
  }
}

/* my quiz tab css end */
@media only screen and (max-width: 445px) {
  .activity-details nav.activity-guidlines .nav-tabs {
    justify-content: space-between !important;
  }
}