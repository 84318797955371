.job-fair-banner {
  background-image: linear-gradient(to bottom, #AFCDFC, #FFFFFF);
}

.jf-title {
  font-size: 32px;
  /* line-height: 24px; */
  font-weight: bold;
  color: #2B5CAA;
}

.jf-subtitle {
  font-size: 20px;
  font-weight: 600;
  color: #598FE4;
}

.jf-people-image {
  width: 400px;
}

.jf-step-box {
  width: auto;
  height: auto;
  border-radius: 10px;
  padding: 10px;
}

.jf-step-box.odd {
  background: #D5E5FF;
}

.jf-step-box.even {
  background: #FFE1F5;
}

.jf-step-number {
  color: #2B5CAA;
  background: #A7C9FF;
  width: 100px;
  height: 100px;
  text-align: center;
  display: inline-block;
  padding: 10px;
  line-height: 80px;
  border-radius: 200px;
  font-size: 26px;
  font-weight: bold;
}

.jf-step-text {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

.jf-step-box.even .jf-step-number {
  color: #C41D8E;
  background: #FFC2EB;
}

.jf-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* grid-template-columns: repeat(auto-fill, 250px); */
  gap: 10px;
}

.jf-grid-item {
  border: 1px solid #000;
  padding: 10px;
}

.jf-video-container {
  width: 800px;
  height: 450px;
}


@media only screen and (max-width: 600px) {
  .jf-title {
    font-size: 16px;
  }

  .jf-subtitle {
    font-size: 14px;
  }

  .jf-people-image {
    width: auto
  }

  .jf-register-btn {
    width: max-content;
    padding: 6px 20px !important;

  }

  .jf-grid-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .jf-video-container {
    width: 90%;
    height: 280px;
  }
}