body {
  padding: 0px;
  margin: 0px;
  font-family: "Montserrat", sans-serif !important;
}

/* header css start*/
.header {
  background-color: #fff;
  box-shadow: 0px 2px 2px #ddd;
}

.logo-sec {
  display: flex;
}

.logo-sec img {
  height: 35px;
}

.header.navbar ul.nav li.nav-item a.nav-link {
  color: #000;
}

.header.navbar ul.nav li.nav-item a.nav-link:hover {
  color: #2b5caa;
}

.header.navbar ul.nav li.nav-item a.nav-link.active {
  color: #2b5caa !important;
  font-weight: 600;
}

.profile-icon img {
  padding: 0px;
  height: 40px;
}

.profile-menu img {
  padding: 0px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid #979797;
}

.profile-menu .dropdown-item:hover,
.profile-menu .dropdown-item:focus {
  color: #0a58ca !important;
  background-color: #fff !important;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 500;
}

.profile-icon a {
  padding-top: 0px;
  padding-bottom: 0px;
}

/* header css end */
/*side menu sec*/
.header.navbar .openbtn {
  font-size: 32px;
  cursor: pointer;
  background-color: #fff;
  color: #000;
  line-height: 0px;
  border: none;
  display: none;
}

.header.navbar .openbtn:focus {
  outline: none;
}

.header.navbar .sidepanel {
  width: 0;
  position: fixed;
  z-index: 1;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #e9ecef;
  overflow-x: hidden;
  transition: 0.5s;
}

.header.navbar .sidepanel .closebtn {
  font-size: 36px;
  border: none;
  display: flex;
  padding: 0px 10px;
  align-items: center;
  justify-content: space-between;
  /* border-bottom: 1px solid #c4c4c4; */
  box-shadow: 0px 2px 2px #ddd;
}

.header.navbar .sidepanel .closebtn span {
  /* margin-top: 9px; */
  color: #000;
  font-weight: 600;
}

.header.navbar .sidepanel .closebtn img {
  height: 24px;
  margin: 0px 10px;
}

.sidepanel ul li a {
  color: #2b5caa;
  font-weight: 400;
  border-left: 5px solid transparent;
  padding: 6px 20px !important;
}

.sidepanel ul li a.active {
  background-color: #fff;
  border-left: 5px solid #2b5caa;
  font-weight: 600;
}

/*side menu sec end  */
/* banner css start*/
.banner-sec {
  height: 240px;
}

.banner-sec img {
  height: 100%;
  width: 100%;
  object-position: 100% 36%;
  object-fit: cover;
}

/* banner css end */
/* content-sec css start */
.content-sec {
  display: flex;
}

/* content-sec css end */
/*side-bar-sec css start */
.side-bar-sec {
  background-color: #f6f9ff;
  padding: 0px;
  border-right: 1px solid #c4c4c4;
}

.sider-bar-profile-outer {
  position: relative;
}

.sidebar-profile {
  position: absolute;
  width: 100px;
  height: 100px;
  top: -54px;
  left: 50%;
  transform: translate(-50%, -8%);
}

.sidebar-profile img {
  width: 100%;
  height: 100%;
  cursor: pointer;
  border: 1px solid #979797;
  object-fit: cover;
}

.sidebar-profile-details {
  padding: 4rem 0 3rem;
  text-align: center;
  border-bottom: 1px solid #c4c4c4;
}

.sidebar-profile-details h5 {
  color: #2b5caa;
  font-weight: 600;
  padding: 10px 0px;
  margin: 0px;
  font-size: 22px;
}

.sidebar-profile-details p {
  margin: 0px;
  padding: 5px 0px;
  color: #000;
}

.sidebar-profile-details span {
  color: #666666;
}

/*side-bar-sec css end */
/* sidebar-menu-sec css start */
.side-bar-menu-sec .side-bar-menu-heading h5 {
  padding: 30px 50px 20px;
  color: #000;
  font-weight: 500;
  margin: 0px;
  text-align: start;
  font-size: 20px;
}

.side-bar-menu-sec ul li a.active {
  background-color: #fff;
  border-left: 5px solid #2b5caa;
}

.side-bar-menu-sec ul li a {
  color: #2b5caa;
  font-weight: 600;
  border-left: 5px solid transparent;
}

.side-bar-menu-sec ul li:first-child a {
  color: #000;
  font-weight: 400;
}

.side-bar-menu-sec ul li a img {
  margin-right: 20px;
  margin-left: 50px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

/* sidebar-menu-sec css end */
/* your-detail-form-sec css start */
.your-detail-form-sec {
  padding: 50px 0 50px;
}

/* .your-detail-form-sec form input,
.your-detail-form-sec form select {
  height: 40px !important;
  padding: 8px 20px;
  margin-bottom: 10px;
} */

.gender-options button {
  color: #000;
  border-radius: 20px;
  padding: 4px 25px;
  background-color: #fff;
  border: 1px solid #000;
  margin: 0px 10px 0px 0px;
}

.gender-options button:hover {
  color: #fff;
  background-color: #2b5caa;
  border: 1px solid #2b5caa;
}

/* your-detail-form-sec css end */
/* children-detail-form-sec css start */
.children-detail-form-sec {
  padding: 50px 0 50px;
}

.children-detail-form-sec .form-pill-btn button {
  margin-top: 12px;
}

#search-box {
  /* background-image: url("./../../../skin/img/search-icon.png"); */
  background-repeat: no-repeat;
  background-position: 25px 12px;
  padding: 15px 55px;
}

/* children-detail-form-sec css end */
/* common css start*/
h5.form-title {
  font-weight: 700;
  margin: 0px;
  padding-bottom: 20px;
}

.children-detail-sec input::placeholder {
  color: #000 !important;
}

/* form.form-sec input,
form.form-sec select {
  height: 40px !important;
  padding: 8px 20px;
  margin-bottom: 10px;
} */

.form-pill-btn button {
  color: #000;
  border-radius: 20px;
  padding: 4px 25px;
  background-color: #fff;
  border: 1px solid #000;
  margin: 0px 10px 0px 0px;
}

.form-pill-btn button:hover {
  color: #fff;
  background-color: #2b5caa;
  border: 1px solid #2b5caa;
}

button.form-submit-btn {
  background-color: #2b5caa;
  color: #fff;
  font-weight: 400;
  border-radius: 20px;
  margin: 10px 0;
  padding: 6px 60px;
}

button.form-submit-btn:hover {
  color: #fff;
  transition: 0.4s;
  box-shadow: 1px 1px 5px 2px rgb(168, 167, 167);
}

/* commoncss end */
/* Responsive Start */
@media only screen and (max-width: 1140px) {

  /* header css start */
  /* .header .col-2.logo-sec,
    .header .col-5 {
      padding: 0px;
    } */
  .header .logo-sec img {
    height: 30px;
  }

  .header.navbar ul.nav li.nav-item a.nav-link {
    font-size: 16px;
  }

  .header.navbar .right-menu-sec ul.nav li.nav-item:last-child a.nav-link {
    padding: 0px;
  }

  /* header css end */
  /* side profile css start */
  .sidebar-profile {
    width: 100px;
    height: 100px;
    top: -46px;
  }

  .sidebar-profile-details {
    padding: 3.5rem 0 2rem;
  }

  /* side profile css end*/
  /*side-bar-menu-sec css start */
  .side-bar-menu-sec .side-bar-menu-heading h5 {
    padding: 30px 30px 20px;
    text-align: center;
  }

  .side-bar-menu-sec ul li a img {
    margin-left: 22px;
  }

  /* side profile css end*/
}

@media only screen and (max-width: 991px) {

  /* header css start */
  .header .col-2.logo-sec,
  .header .col-5 {
    padding: 0px;
  }

  .header .logo-sec img {
    height: 25px;
  }

  .header.navbar ul.nav li.nav-item a.nav-link {
    padding: 8px 6px;
    font-size: 14px;
  }

  .header.navbar .right-menu-sec ul.nav li.nav-item:last-child a.nav-link {
    padding: 0px;
  }

  .header .profile-icon img {
    padding: 0px;
    /* height: 36px; */
  }

  /* header css end */
  /* banner-sec css start  */
  .banner-sec {
    height: 160px;
  }

  /* banner-sec css end  */
  /* side profile css start */
  .sidebar-profile {
    width: 80px;
    height: 80px;
    top: -34px;
  }

  .sidebar-profile-details {
    padding: 3rem 0 1rem;
  }

  .sidebar-profile-details h5 {
    font-size: 18px;
  }

  .sidebar-profile-details p {
    font-size: 14px;
  }

  .sidebar-profile-details span {
    font-size: 14px;
  }

  /* side profile css end*/
  /*side-bar-menu-sec css start */
  .side-bar-menu-sec .side-bar-menu-heading h5 {
    padding: 30px 15px 20px;
    font-size: 16px;
  }

  .side-bar-menu-sec ul li a {
    font-size: 14px;
  }

  .side-bar-menu-sec ul li a img {
    margin-right: 5px;
    margin-left: 10px;
    height: 30px;
    width: 30px;
  }

  /* side-bar-menu-sec css end */
  /*common css start */
  h5.form-title {
    font-size: 16px;
  }

  form.form-sec input,
  form.form-sec select {
    height: 35px !important;
    padding: 5px 20px;
    font-size: 14px;
  }

  form.form-sec .input-group-text {
    padding: 0px 8px;
    font-size: 14px;
  }

  .form-pill-btn button {
    padding: 3px 20px;
    font-size: 14px;
    margin-bottom: 10px;
  }

  button.form-submit-btn {
    /* padding: 4px 50px;
    font-size: 14px; */
  }

  /* common css end */
}

@media only screen and (max-width: 767px) {

  /*side menu sec*/
  .header.navbar .openbtn {
    display: unset;
  }

  .header.navbar .sidepanel .closebtn span {
    font-size: 26px;
    padding: 6px;
  }

  .sidepanel ul li a {
    font-size: 14px;
  }

  .left-menu-sec {
    display: none;
  }

  .right-menu-sec ul li {
    display: none;
  }

  .right-menu-sec ul li:last-child {
    display: unset;
    padding: 0px 8px;
  }

  /*side menu sec end*/
  /* content-sec css start */
  /* .content-sec .col-sm-3.side-bar-sec {
    flex: 35% !important;
    max-width: 35% !important;
  }
  .content-sec .col-sm-9.right-content-sec {
    max-width: 65% !important;
    flex: 65% !important;
  } */
  /* content-sec css end */

  /* side-menu-sec css start */
  .side-bar-menu-sec .side-bar-menu-heading h5 {
    padding: 30px 20px 20px;
  }

  /* side-menu-sec css end */
  #search-box {
    /* background-image: url("./../../../skin/img/search-icon.png"); */
    background-repeat: no-repeat;
    background-position: 8px 9px;
    padding: 2px 6px 2px 30px;
  }
}

@media only screen and (max-width: 460px) {

  /* header css start*/
  .header .container-fluid {
    padding: 0px;
  }

  /* header css end*/
  /* banner-sec css start  */
  .banner-sec {
    height: 100px;
  }

  /* banner-sec css end  */
  /*content-sec css start  */
  .content-sec {
    display: unset;
  }

  .content-sec .col-7,
  .content-sec .col-5 {
    flex: 0 0 auto;
    width: 100%;
  }

  /*content-sec css end  */
  /* side-bar-sec css start*/
  .side-bar-sec {
    border: none;
  }

  /* side-bar-sec css end */
}

/*  */
.side-bar-menu-sec .nav .nav-link.active {
  background-color: #fff !important;
  border-left: 5px solid #2b5caa !important;
  border-radius: unset !important;
  color: #2b5caa;
  font-weight: bold;
}

.side-bar-menu-sec .nav .nav-link {
  border-left: 5px solid transparent !important;
  color: #2b5caa;
  font-weight: bold;
  text-align: start;
  font-size: 15px;
}

.side-bar-menu-sec .nav .nav-link img {
  margin-right: 20px;
  margin-left: 0px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid #979797;
}

h5 {
  font-size: 1.25rem;
  font-weight: 800;
}

/* .navbar a,
.navbar a:focus {
  font-weight: unset !important;
} */

.navbar li {
  margin-bottom: 0px !important;
}

.header.navbar {
  padding: 10px;
}

.profile-icon.dropdown .dropdown-toggle::after {
  display: none;
}

.profile-icon .dropdown-menu {
  left: -117px !important;
}

/* .relation-btn:hover {
  background-color: #2b5caa !important;
  color: #fff !important;
  border: 1px solid #2b5caa !important;
  border-radius: 20px !important;
}
.relation-btn {
  color: #000 !important;
  border-radius: 20px !important;
  padding: 4px 25px;
  background-color: #fff !important;
  border: 1px solid #000 !important;
  margin: 0px 10px 0px 0px;
}
.btn-check:checked + .relation-btn {
  background-color: #2b5caa !important;
  color: #fff !important;
  border: 1px solid #2b5caa !important;
  border-radius: 20px !important;
} */

input[type="radio"]:checked {
  color: #fff !important;
  background-color: #2b5caa !important;
  border: 1px solid #2b5caa !important;
}

input[type="radio"]+.gender-btn {
  color: #000 !important;
  border-radius: 20px !important;
  padding: 4px 25px;
  background-color: #fff !important;
  border: 1px solid #000000 !important;
  margin: 0px 10px 0px 0px;
}

input[type="radio"]:checked+.gender-btn {
  color: #fff !important;
  border-radius: 20px !important;
  padding: 4px 25px;
  background-color: #2b5caa !important;
  border: 1px solid #2b5caa !important;
}

.select-dropdown .search.selection.dropdown {
  width: 100% !important;
}

.image-upload>input {
  display: none;
}

.btn-check {
  position: absolute;
  clip: unset !important;
  opacity: 0;
  pointer-events: unset !important;
  width: 108px;
}

.children-detail-sec .divider.default.text {
  color: #000 !important;
  font-size: 14px !important;
}

/* Profile pic hover */
.profilepic {
  position: relative;
}

.profilepic:hover .profilepic__content {
  opacity: 1;
}

.profilepic:hover .profilepic__image {
  opacity: 0.5;
  background-color: #131313b6;
}

.profilepic__content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;
}

.profilepic__icon {
  color: white;
}

.fas {
  font-size: 20px;
}

/*  */
input[type="date"]::-webkit-calendar-picker-indicator,
input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.date-form-field {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.relation-field {
  width: auto;
  float: left;
  position: relative;
}

.children-detail-form-sec .form-select {
  color: #000 !important;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
}

@media only screen and (max-width: 480px) {
  .hidden-sidebar {
    display: none;
  }

  .children-detail-form-sec,
  .your-detail-form-sec {
    width: 80% !important;
  }

  .Back {
    display: block !important;
  }

  .hidden .Back {
    visibility: hidden !important;
    height: 0px !important;
  }

  .activity-details .mbs-120 {
    margin-bottom: 120px;
  }
}

.Back {
  background-color: #2b5caa;
  color: #fff;
  font-weight: 400;
  border-radius: 20px;
  margin: 10px 16px;
  padding: 4px 12px;
  font-size: 14px;
  border: 0px;
  display: none;
}

.cal-icon {
  position: absolute;
  right: 25px;
  top: 32px;
  width: 24px;
  fill: #fff;
  stroke: #2b5caa;
}

.react-date-picker__wrapper {
  border: none;
}