.wrapper .t-n-c-block {
  float: left;
  padding-left: calc(50% - 570px);
  padding-right: calc(50% - 570px);
}

.wrapper .t-n-c-block .heading {
  width: 100%;
  float: left;
  margin-bottom: 28px;
}

.wrapper .t-n-c-block .heading .head {
  width: 100%;
  float: left;
  text-align: center;
  margin-top: 40px;
}

.wrapper .t-n-c-block .heading .date {
  width: 100%;
  float: left;
  text-align: right;
  margin-top: 28px;
  font-size: 13px;
  font-family: Montserrat;
  color: #141414;
}

.wrapper .t-n-c-block .heading .head .title {
  width: auto;
  float: none;
  text-align: center;
  font-family: Montserrat;
  font-size: 28px;
  color: #08316e;
  font-weight: 700;
  display: inline-block;
  border-bottom: 4px solid #285caa;
  padding-bottom: 4px;
}

/*  */
.wrapper .acceptance-terms .head .term-title {
  width: auto;
  float: none;
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  color: #08316e;
  font-weight: 700;
  display: inline-block;
  text-decoration: underline;
  padding-bottom: 4px;
  margin-bottom: 18px;
}

.wrapper .acceptance-terms {
  width: 100%;
  float: left;
  margin-top: 6px;
  margin-bottom: 18px;
}

.wrapper .acceptance-terms .head {
  width: 100%;
  float: left;
  text-align: center;
}

.wrapper .acceptance-terms .text-block {
  width: 100%;
  float: left;
}

.wrapper .acceptance-terms .text-block .paragraph {
  width: 100%;
  float: left;
  text-align: justify;
  font-family: Montserrat;
  font-size: 14px;
  color: #141414;
  margin: 0 0 13px;
}

.wrapper .acceptance-terms .text-block .subtitle {
  width: 100%;
  float: left;
  text-align: justify;
  font-family: Montserrat;
  font-size: 14px;
  color: #141414;
  margin-bottom: 13px;
}

.wrapper .acceptance-terms .text {
  width: 100%;
  float: left;
  text-align: justify;
  font-family: Montserrat;
  font-size: 20px;
  color: #08316e;
  font-weight: 700;
  display: inline-block;
  padding-bottom: 4px;
  margin-bottom: 8px;
}

.wrapper .acceptance-terms ul li {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  color: #000;
  top: 17px;
  text-align: justify;
  margin-bottom: 5px;
}