.top-banner-sec {
  background-image: url("../../Assets/banner/event.svg");
  height: 650px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  display: flow-root;
  position: relative;
}

.top-banner-sec::before {
  content: "";
  background-color: #000;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  opacity: 0.5;
}

#register-page .row,
#register-page .banner-overly {
  height: 100%;
}

#register-page .form-sec {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

#register-page .row {
  margin: 0px;
}

#register-page .bnr-content-sec {
  display: flex;
  height: 100%;
  justify-content: start;
  align-items: center;
  opacity: 0.9;
}

#register-page .content-sec {
  display: block;
}

#register-page .content-sec h1.hdng {
  font-size: 30px;
  font-weight: 700;
  color: #ffff;
  padding: 0;
  font-family: "Montserrat", sans-serif !important;
}

#register-page .content-sec p.desc-para {
  color: #ffff;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  margin: 25px 0px;
}

#register-page .content-sec p.timeline {
  color: #ffff;
  font-size: 16px;
  font-weight: 600;
}

#register-page .tabs .tab-buttons {
  background-color: #3e6db3;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
}

.adjust-padding {
  padding-left: calc((100% - 1140px) / 2);
  padding-right: calc((100% - 1140px) / 2);
}

#register-page .tabs .tab-buttons ul {
  display: flex;
  align-items: center;
}

#register-page .tabs .tab-buttons ul li {
  margin-bottom: 10;
  margin-right: 12px;
}

#register-page .tabs .tab-buttons a.tab-btns {
  background-color: #fff;
  color: #c41d8e;
  border: none;
  padding: 9px 50px;
  border-radius: 18px;
  font-weight: 600;
  text-decoration: none;
}

#register-page .tabs .tab-buttons a.tab-btns:hover {
  background-color: #c41d8e !important;
  color: #fff !important;
}

#register-page .nav-link.tab-btns.active {
  background-color: #c41d8e !important;
  color: #fff !important;
}

#register-page .tabs .upper-sec .hdng {
  display: flex;
  justify-content: space-between;
  padding: 4rem 0 1rem;
  align-items: center;
}

#register-page .tabs .upper-sec .hdng .form-select {
  display: block;
  font-weight: 600;
  width: max-content;
  min-width: 124px;
  border: 2px solid #c41d8e;
  color: #c41d8e;
  border-radius: 18px;
  /* background-image: url("../../skin/img/new-quiz/select-arrow.svg"); */
}

#register-page p.txt {
  font-weight: 500;
  font-size: 16px;
  margin: 0px;
}

#register-page .participants-card {
  padding: 3rem 0;
}

.benefit-sec .sec-hdng p {
  position: relative;
  width: max-content;
  margin: 0 auto;
}

.benefit-sec .sec-hdng p::after {
  content: ' ';
  /* background-image: url("../../skin/img/new-quiz/hdn-icon.svg"); */
  height: 80px;
  width: 80px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: -50px;
  transform: translate(4%, -45%);
  background-size: 100%;
  background-repeat: no-repeat;
  z-index: -11;
}

#benefitCards {
  padding: 1rem 0;
}

#register-page .participants-img {
  width: 260px;
  height: 180px;
  margin: 0 auto;
}

#register-page .participants-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

#register-page .participants-text {
  text-align: center;
  color: #3e6db3;
  font-size: 18px;
  font-weight: 500;
  padding-top: 10px;
}

#register-page .sec-hdng p {
  font-size: 25px;
  color: #2b5caa;
  text-align: center;
  font-weight: 600;
  padding: 4rem 0 1rem;
}

#register-page .details .content {
  padding-top: 2rem;
}

#register-page .details .content ul li {
  font-weight: 500;
  font-size: 16px;
  margin: 0px;
  line-height: 26px;
  padding-bottom: 10px;
}

#register-page .details .content ul li::marker {
  color: #c41d8e;
  font-size: 26px;
}

/* second tab */
#register-page #vedios {
  padding: 2rem 0;
}

/*  */