.ns-quiz-content .bannerImg img.mb {
  display: none;
}

.ns-quiz-content .start-quiz-content-sec .quiz-detail-header .quiz-detail-sec {
  padding-top: 3rem;
}

.ns-quiz-content .quiz-hint-sec {
  position: unset;
  -webkit-transform: unset;
}

.ns-quiz-content .component-question .question-sec {
  display: unset;
}

.ns-quiz-content .component-question .question-sec .question-header {
  width: 100%;
  background-color: #f5f5f5;
}

.ns-quiz-content .component-question .question-sec .question-answers-content {
  width: 100%;
}


.ns-quiz-content .component-question .question-sec .question-header::before {
  display: none;
}

/* .ns-quiz-summary-con .summary-content.container-kidex {
  padding: 20px;
  margin: 0px 15px;
} */

.clear-response {
  cursor: pointer;
}

.answer-correct {
  background-color: #FFEAF5;
}

.answer-incorrect {
  background-color: #EDEDED;
}

.text-correct {
  color: #C41D8E;
  padding: 2rem 0rem !important;
}

.text-correct img {
  margin-top: -5px;
}

.text-incorrect {
  color: #505050;
  padding: 2rem 0rem !important;
}

.text-incorrect img {
  margin-top: 5px;
}

@media screen and (max-width: 768px) {
  .ns-quiz-content .bannerImg img.mb {
    display: block;
  }
  /*  */
  .atl-team-reg.atl-tinker-register.ns-quiz-summary-con img#ns-quiz-summary-banner{
    display: block;
  }
  #act-details .past-attem-tab-header{
    width: 100%;
    overflow: auto;
    height: 50px;
  }
  #act-details .tab-content .past-attem-tab-header ul#myTab{
    min-width: max-content;
    width: 100%;
  }
/*  */
  .ns-quiz-content .bannerImg img {
    display: none;
  }

  .ns-quiz-summary-con .summary-content.container-kidex {
    padding: 20px;
    margin: 0px;
  }

  .ns-quiz-summary-con .summary-content.container-kidex .question-btn .btn {
    padding: 20px;
    margin-bottom: 10px;
  }
  .wrapper.champ-detail .banner .banner-slot div.content#national-perform{
    left:34%;
    height: auto;
  }
}
@media screen and (max-width: 1460px) {
  .wrapper .banner .banner-slot .content#national-perform .content-block .home-btn{
    padding: 10px 15px;
    width: max-content;
  }
}

@media screen and (max-width: 1260px) {
  #ns-quiz-summary-page .container-kidex{
    padding: 0px 35px;
  }
  .wrapper.champ-detail .banner-slot{
    height: auto;
  }
  .wrapper.champ-detail .banner .banner-slot .content#national-perform{
    left: 36%;
    top: 50%;
    transform: translate(-50%,-50%);
    height: auto;
    float:unset
  }
}
@media screen and (max-width: 1080px) {
  .wrapper.champ-detail .banner .banner-slot .content#national-perform{
    left: 28%;
    top: 50%;
    transform: translate(-50%,-50%);
    height: auto;
  }
}

@media screen and (max-width: 480px) {
  .wrapper.champ-detail .banner .banner-slot{
    height: 450px;
  }
  .wrapper.champ-detail .banner .banner-slot div.content#national-perform{
    left: 50%;
    width: inherit;
  }
}
