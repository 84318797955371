.profile-banner {
    background: linear-gradient(105.89deg, #CEE1FF -8.36%, #CEE1FF 101.61%);
    width: 100%;
    height: 164px;
}

.profile-left-area {
    background: #F7F9FE;
    padding: 20px;
    position: sticky;
    top: 90px;
}

.profile-user-img {
    padding: 16px;
    width: 132px;
    height: 132px;
    margin: -90px auto 15px auto;
    border-radius: 100%;
}

.avatar-bg {
    background-image: url(../../Assets/images/avatar-bg.svg);
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
}

.profile-user-img>img {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    object-fit: cover;
}

.profile-user-name {
    font-size: 20px;
    font-weight: 500;
    line-height: 33.33px;
    text-align: center;
    color: #2B2B2B;
    display: flex;
    align-items: center;
    justify-content: center;

}

.profile-user-name>img {
    margin-left: 10px;
}

.profile-info-list {
    list-style: none;
}

.profile-info-list li {
    font-size: 13.33px;
    font-weight: 400;
    line-height: 12.5px;
    text-align: left;
    color: #1D1D1D;
    margin-bottom: 15px;

}

.profile-info-list li img {
    max-width: 20px;
    margin-right: 10px;

}

.profile-info-list li span {
    color: #2B5CAA;
}

.qr-code {
    text-align: center;
}

.qr-code>img {
    max-width: 100%;
}

.profile-right-area,
.profile-main-content-area {
    padding: 20px;
}

.profile-right-area {
    position: sticky;
    top: 20px;
}


.job-exp-row-item {
    margin-bottom: 30px;
}

.job-detail-left {
    width: 200px;
    padding-right: 40px;
}

.job-exp-date {
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #47516B;

}

.job-designation,
.job-company {
    font-family: Inter;
    font-size: 15px;
    font-weight: 400;
    line-height: 17.02px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #484848;

}

.job-type {
    display: block;
    color: #999999;
    margin-top: 5px;
}

.job-company {
    color: #2E2E48;

}

.job-description {
    font-size: 13px;
    font-weight: 400;
    line-height: 15.73px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #79819A;
    flex: 1;
}

.job-exp-row-item {
    position: relative;
    display: flex;
    padding-left: 30px;
}

.job-exp-row-item:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 0px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #17457B;
}

.job-exp-row-item:after {
    content: "";
    position: absolute;
    top: 24px;
    left: 3px;
    width: 2px;
    height: calc(100% - 48px);
    background: #17457B;
}

.job-exp-row .job-exp-row-item:last-child::after {
    display: none;
}


/* Edit Profile */
.edit-profile .profile-user-img {
    padding: 0px;
    position: relative;

}

.profile-img-upload-btn {
    width: 27px;
    height: 27px;
    border-radius: 100%;
    display: flex;
    background: #17457B;
    position: absolute;
    right: 10px;
    bottom: 10px;
    cursor: pointer;
}

.profile-img-upload-btn>img {
    margin: auto;
}

.edit-profile .profile-info-list li label {
    font-size: 13.33px;
    font-weight: 500;
    line-height: 18.33px;
    text-align: left;
    color: #7E7E7E;
    margin-bottom: 4px;
}

.edit-profile .profile-info-list li .form-control {
    border: 1px solid #DEDEDE;
    padding: 5px 15px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 33.33px;
    text-align: left;
    color: #2B2B2B;

}

.edit-profile select.form-control {
    /* background-image: url(../../Assets/images/arrow-down.png); */
    background-position: 94% 18px;
    background-repeat: no-repeat;
    background-size: 14px;
}

.media-upload {
    border: 2px dashed var(--blue);
    background: #F5F9FF;
    font-size: 20px;
    font-weight: 500;
    line-height: 31px;
    text-align: center;
    color: var(--blue);
    border-radius: 15px;
    padding: 5px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.media-upload>img {
    max-width: 28px;
    margin-right: 10px;
}

.upload-file-type {
    display: flex;
    justify-content: space-around;
}

.upload-file-type-item {
    margin: 0px 20px;
    cursor: pointer;
}

.upload-file-type-item-icon {
    width: 70px;
    height: 70px;
    background: #2B5CAA;
    border-radius: 100%;
    display: flex;
    position: relative;
    cursor: pointer;
}

.upload-file-type-item-icon>input[type="file"] {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.upload-file-type-item-icon>img {
    margin: auto;
}

.upload-file-type-item-text {
    font-size: 20px;
    font-weight: 500;
    line-height: 32.74px;
    text-align: center;
    color: #4B4747;
    margin-top: 10px;
}

.uploaded-file-name label {
    font-size: 13.33px;
    font-weight: 500;
    line-height: 18.33px;
    text-align: left;
    color: #7E7E7E;
    margin-bottom: 5px;
    display: block;
}

.uploaded-file-name .form-control {
    background: #FFFFFF;
    border: 1px solid #DEDEDE;
    border-radius: 6px;
    max-width: 70%;
    font-size: 16px;
    font-weight: 400;
    line-height: 12.5px;
    text-align: left;
    color: #1D1D1D;

}

.file-delete {
    border: 0px;
    padding: 0px;
    background: transparent;
}

.file-progess {
    display: flex;
    justify-content: space-between;
    align-items: start;
}

.file-progess .progress {
    flex: 1;
    height: 24px;
    border-radius: 30px;
    background: #E9F1FF;
}

.file-progess .progress-bar {
    border-radius: 30px;
    background: #2B5CAA;
}

.progress-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 12.5px;
    text-align: left;
    color: #1D1D1D;
    margin-top: 10px;
}

.flex-1 {
    flex: 1;
}

.file-progess .switch {
    width: 44px;
    height: 28px;
}

.file-progess .switch-checkbox .switch-btn:before {
    height: 21px;
    width: 21px;
}

.file-progess .switch-checkbox input:checked+.switch-btn:before {
    transform: translateX(15px);
}

.back {
    position: absolute;
    z-index: 1;
    left: -40px;
    top: 20px;
}

/* Edit Profile End*/

@media screen and (max-width:580px) {
    .profile-left-area {
        margin: 0px -12px;
    }

    .profile-info-list {
        display: table-cell;
        width: 60%;
        vertical-align: top;
    }

    .qr-code {
        display: table-cell;
        text-align: right;
    }

    .profile-info-list li {
        font-size: 12px;
    }

    .qr-code>img {
        max-width: 70%;
    }

    .mob-order-1 {
        order: 1;
    }

    .profile-banner {
        height: 64px;
    }

    .profile-user-img {
        width: 110px;
        height: 110px;
        margin: -60px auto 5px auto;
    }

    .profile-user-img>img {
        width: 78px;
        height: 78px;
    }

    .job-detail-left {
        width: 100%;
        padding-right: 0px;
    }

    .job-exp-row-item {
        flex-direction: column;
        margin-bottom: 0px;
    }

    .job-description p {
        margin: 8px 0px 0px;
        padding-bottom: 25px;
    }

    .job-exp-row-item:after {
        height: calc(100% - 38px);
    }

    .edit-profile .profile-user-info {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-end;
    }

    .edit-profile .profile-info-list {
        width: 100%;
    }

    .edit-profile .qr-code {
        width: 100px;
        margin-top: -70px;
    }

    .upload-file-type-item-icon {
        width: 50px;
        height: 50px;
    }

    .upload-file-type-item-icon>img {
        margin: auto;
        max-width: 22px;
    }

    .upload-file-type-item-text {
        font-size: 11px;
        margin-top: 2px;
    }

    .file-progess .progress {
        height: 12px;
    }

    .progress-text {
        font-size: 9px;
    }

    .file-delete>img {
        max-width: 14px;
    }

    .file-delete {
        line-height: 12px;
    }

    .uploaded-file-name label {
        font-size: 12px;
    }

    .profile-user-name {
        margin-bottom: 20px;
    }
}