.summary-banner{
    background: #DFEBFF;
    padding: 50px 0px;
}
.summary-banner h1{
    font-size: 40px;
    font-weight: 700;
    text-align: left;
    color: #143D7F;
}
.summary-banner h1 span{
    display: block;
    padding-top: 15px;
    font-size: 32px;
    font-weight: 700;
    text-align: left;
    color: #729CE1;
}

/* Banner End */

.sorting-dropdown{
    font-size: 16px;
    font-weight: 600;
    line-height: 30.24px;
    letter-spacing: 0.7350262403488159px;
    text-align: left;
    color: #333333;
}
.sorting-dropdown>select{
    color: #2B5CAA;
    border: 0px;
    background-image: url(../../Assets/images/arrow-down.png);
    background-position:94% 11px;
    background-repeat: no-repeat;
    background-size: 18px;
    padding-right: 28px;
}
.sorting-dropdown>select:focus{
    box-shadow: none;
}

.user-detail-main{
    border: 1px solid #CFCFCF;
    border-radius: 16px;
    padding: 25px;
}

.skill-sub-title{
    font-size: 13px;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0.01em;
    text-align: left;
    color: #47516B;
}
.skill-title{
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
    text-align: left;
    color: #2B2B2B;

}
.profile-user-img{
    position: relative;
}
.profile-user-img>svg{
    position: absolute;
    top: -36px;
    left: -4px;
    width: 132px;
    height: 132px;
} 
.profile-user-img  textPath {
    fill: #fff; 
    font-size: 10.22px;
    font-weight: 500;
    line-height: 17.1px

  } 

@media screen and (min-width:1366px){
    .summary-container{
        max-width: 90%;
    }
}
@media screen and (max-width:767px){

}
@media screen and (max-width:767px){
    .summary-banner h1{
        font-size: 18px;
    }
    .summary-banner h1 span{
        font-size: 14px;
    }
    .user-detail-main .site-btn{
        font-size: 12px !important;
        width: 100%;
        flex: 0 0 auto;
    }
    .user-detail-main .profile-user-img{
        width: 78px;
        height: 78px;
        padding: 10px;
    }
    .user-detail-main  .user-detail-main .profile-user-img>img{
        width: 58px;
        height: 58px;
    }
    .user-detail-main  .profile-user-img>svg{
        width: 78px;
        height: 78px;
        top: -53px;
        left: -10px;
    }
    .user-detail-main  .profile-user-img textPath{
        font-size: 6px;
    }
    .user-detail-main .profile-user-name{
        font-size: 12px;
        margin-bottom: 0px;
    }
    .user-detail-main  .profile-user-name>img {
        margin-left: 4px;
        max-width: 19px;
    }
    .skill-sub-title{
        font-size: 11px;
        margin-bottom: 0px;
    }
    .skill-title{
        font-size: 12px;
        line-height: 100%;
    }
}

@media screen and (min-width:768px) and (max-width:1024px){
    .summary-banner h1{
        font-size: 20px;
    }
    .summary-banner h1 span{
        font-size: 16px;
    }
    .user-detail-main .profile-user-img{
        width: 70px;
        height: 70px;
        padding: 10px;
    }
    .user-detail-main  .user-detail-main .profile-user-img>img{
        width: 50px;
        height: 50px;
    }
    .user-detail-main  .profile-user-img>svg{
        width: 70px;
        height: 70px;
        top: -53px;
        left: -10px;
    }
    .user-detail-main  .profile-user-img textPath{
        font-size: 6px;
    }
    .profile-user-name{
        font-size:13px;
        line-height: 100%;
    }
    .skill-sub-title{
        font-size: 11px;
    }
    .skill-title{
        font-size: 12px;
    }
    .profile-user-name>img {
        margin-left: 2px;
        max-width: 16px;
    }
    .user-detail-main{
        padding: 15px;
    }
    .summary-video{
        height: 150px !important;
    }
}

@media screen and (min-width:992px) and (max-width:1100px){
    .user-detail-main .site-btn{
        font-size: 12px !important;
    }
}

@media screen and (min-width:1024px) and (max-width:1280px){
    .user-detail-main .col-xl-7,
    .user-detail-main .col-xl-5{
       width: 50%;
    }
}