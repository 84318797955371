.register-new-child.card {
  border: none;
  background-color: #fff;
  width: 380px;
  padding: 20px 30px;
  border-radius: 10px;
}

.register-new-child .rgs-child-hdng {
  color: #000;
  font-size: 18px;
  padding: 0px 0px 3rem;
  font-weight: 600;
}

/* .register-new-child .form-select {
  margin-bottom: 20px;
  color: #c41d8e;
  background-position: 94% 48%;
} */

.register-new-child .btn {
  background-color: #c41d8e;
  color: #fff;
  margin-bottom: 20px;
}

.register-new-child .btn {
  border-radius: 26px;
  border: 2px solid #c41d8e;
  font-weight: 600;
  width: 100%;
  padding: 8px 20px;
}