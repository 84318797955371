.context_narchome {
  float: left;
  width: 100%;
  padding-left: calc((100% - 1140px) / 2);
  padding-right: calc((100% - 1140px) / 2);
}

@media (max-width: 1024px) {
  .context_narchome {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 480px) {
  .context_narchome {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.context_narchome .banner {
  float: left;
  width: 100%;
  background-color: #fff;
  overflow: hidden;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  margin-top: 20px;
}

.context_narchome .banner .content {
  float: left;
  width: 40%;
}

@media (max-width: 1024px) {
  .context_narchome .banner .content {
    width: 45%;
  }
}

@media (max-width: 768px) {
  .context_narchome .banner .content {
    width: 100%;
    height: 369px;
  }
}

.context_narchome .banner .content .graphic {
  float: left;
  width: 100%;
}

@media (max-width: 480px) {
  .context_narchome .banner .content .graphic {
    width: 50%;
  }
}

.context_narchome .banner .content .graphic img {
  float: left;
  width: 100%;
  height: 32px;
}

.context_narchome .banner .content .block {
  float: left;
  width: 100%;
  margin-top: 40px;
  padding-left: 45px;
  padding-bottom: 60px;
}

.context_narchome .banner .content .block.narc20-21 {
  padding-left: 100px;
}

@media (max-width: 480px) {
  .context_narchome .banner .content .block.narc20-21 {
    padding-left: 0px;
  }
}

@media (max-width: 1024px) {
  .context_narchome .banner .content .block {
    margin-top: 50px;
    padding-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .context_narchome .banner .content .block {
    margin-top: 30px;
    padding-left: 25%;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .context_narchome .banner .content .block {
    padding: 20px;
    margin-top: 30px;
    text-align: center;
  }
}

.context_narchome .banner .content .block h1 {
  float: left;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  color: #1c2b3e;
  font-size: 29px;
  margin-bottom: 25px;
}

@media (max-width: 768px) {
  .context_narchome .banner .content .block h1 {
    font-size: 30px;
    text-align: left;
  }
}

@media (max-width: 480px) {
  .context_narchome .banner .content .block h1 {
    text-align: center;
    font-size: 24px;
  }
}

.context_narchome .banner .content .block h2 {
  float: left;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  color: #1c2b3e;
  font-size: 29px;
  margin-bottom: 25px;
  font-weight: 700;
  line-height: 1.28571429em;
}

@media (max-width: 768px) {
  .context_narchome .banner .content .block h2 {
    font-size: 30px;
    text-align: left;
  }
}

@media (max-width: 480px) {
  .context_narchome .banner .content .block h2 {
    text-align: center;
    font-size: 24px;
  }
}

.context_narchome .banner .content .block .text {
  float: left;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #1c2b3e;
  line-height: 22px;
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .context_narchome .banner .content .block .text {
    font-size: 15px;
    text-align: left;
  }
}

@media (max-width: 480px) {
  .context_narchome .banner .content .block .text {
    text-align: center;
  }
}

.context_narchome .banner .content .block button {
  float: left;
  width: 145px;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  color: #c41d8e;
  border: solid 1px #c41d8e;
  background-color: #fff;
  border-radius: 25px;
  line-height: 35px;
  margin-top: 20px;
  cursor: pointer;
}

.context_narchome .banner .content .block button:hover {
  background-color: #c41d8e;
  color: #fff;
}

.context_narchome .banner .content .block button.fill {
  color: #fff;
  border: solid 1px #c41d8e;
  background-color: #c41d8e;
  margin-left: 25px;
}

.context_narchome .banner .content .block button.fill:hover {
  background-color: #a90b76;
  border-color: #a90b76;
}

@media (max-width: 480px) {
  .context_narchome .banner .content .block button {
    margin-left: 0;
    margin-top: 30px;
    float: none;
    display: inline-block;
    width: 140px;
  }

  .context_narchome .banner .content .block button.fill {
    margin-top: 15px;
    margin-left: calc((100% - 160px) / 2);
  }
}

@media (max-width: 480px) and (max-width: 480px) {
  .context_narchome .banner .content .block button.fill {
    margin-top: 15px;
    margin-left: 8px;
  }
}

.context_narchome .banner .imgBlock {
  float: right;
  width: 50%;
  margin-top: 20px;
  margin-bottom: -27px;
}

@media (max-width: 1112px) {
  .context_narchome .banner .imgBlock {
    margin-top: -3px;
  }
}

@media (max-width: 480px) {
  .context_narchome .banner .imgBlock {
    width: 100%;
    padding: 0px;
    margin-top: 0;
    margin-bottom: -13px;
  }
}

.context_narchome .banner .imgBlock img {
  float: left;
  width: 100%;
}

.context_narchome .banner .videoblog {
  float: right;
  width: 50%;
  padding-top: 0px;
}

@media (max-width: 768px) {
  .context_narchome .banner .videoblog {
    width: 100%;
  }
}

.context_narchome .banner .videoblog .slick-dots {
  display: none !important;
}

.context_narchome .banner .videoblog .slick-prev {
  left: 0;
  display: none !important;
}

.context_narchome .banner .videoblog .slick-next {
  right: 29px;
  top: 62%;
  font-size: 25px;
  color: #000;
  width: 30px;
  height: 30px;
  line-height: 47px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
  .context_narchome .banner .videoblog .slick-next {
    right: 39px;
    top: 57%;
  }
}

@media (max-width: 480px) {
  .context_narchome .banner .videoblog .slick-next {
    right: 4px;
  }
}

.context_narchome .banner .videoblog .slick-next .icon {
  position: relative;
  top: -8px;
  right: 0;
}

.context_narchome .banner .videoblog .slick-next .icon:before {
  color: #c00096;
}

.context_narchome .banner .videoblog .slick-next:before,
.context_narchome .banner .videoblog .slick-prev:before {
  font-size: 30px;
  color: #393939;
  opacity: 1;
  display: none;
}

.context_narchome .banner .videoblog .videoBlock {
  float: right;
  width: 100%;
  padding-top: 40px;
}

@media (max-width: 768px) {
  .context_narchome .banner .videoblog .videoBlock {
    width: 100%;
    padding-left: 15%;
    padding-right: 15%;
  }
}

@media (max-width: 480px) {
  .context_narchome .banner .videoblog .videoBlock {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.context_narchome .banner .videoblog .videoBlock .title {
  float: left;
  width: 85%;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #333;
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
}

@media (max-width: 768px) {
  .context_narchome .banner .videoblog .videoBlock .title {
    width: 100%;
  }
}

.context_narchome .banner .videoblog .videoBlock .video {
  float: left;
  width: 85%;
  margin-right: 10%;
  border-radius: 30px;
  background-color: #000;
  padding: 11px 0;
}

@media (max-width: 768px) {
  .context_narchome .banner .videoblog .videoBlock .video {
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
  }
}

@media (max-width: 480px) {
  .context_narchome .banner .videoblog .videoBlock .video {
    width: 100%;
    padding: 11px 0px;
    margin-top: 0;
    margin-bottom: 15px;
  }
}

.context_narchome .banner .videoblog .videoBlock .video iframe {
  float: left;
  width: 100%;
  border-radius: 30px;
}

@media (max-width: 480px) {
  .context_narchome .banner .videoblog .videoBlock .video iframe {
    height: 100%;
  }
}

.context_narchome .banner .videoGraphic {
  float: right;
  width: 30%;
}

@media (max-width: 768px) {
  .context_narchome .banner .videoGraphic {
    margin-right: -22%;
  }
}

@media (max-width: 480px) {
  .context_narchome .banner .videoGraphic {
    width: 40%;
    margin-right: 0%;
  }
}

.context_narchome .banner .videoGraphic img {
  float: left;
  width: 100%;
}

.context_narchome .need-plot {
  float: left;
  width: 100%;
  background-position: initial;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #fff;
  text-align: center;
  padding-top: 80px;
  padding-bottom: 80px;
}

@media (max-width: 1366px) {
  .context_narchome .need-plot {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 480px) {
  .context_narchome .need-plot {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 50px;
  }
}

.context_narchome .need-plot .title {
  float: left;
  width: 100%;
  position: relative;
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  font-weight: 500;
  color: #1d1d1d;
  text-align: center;
  padding-bottom: 16px;
  text-align: center;
  padding-left: calc((100% - 1000px) / 2);
  padding-right: calc((100% - 1000px) / 2);
}

.context_narchome .need-plot .title span {
  color: #285caa;
}

@media (max-width: 480px) {
  .context_narchome .need-plot .title {
    font-size: 21px;
    padding: 0px 0px 18px;
  }
}

.context_narchome .need-plot .title:after {
  display: none;
}

@media (max-width: 480px) {
  .context_narchome .need-plot .title:after {
    display: block;
    content: "";
    position: absolute;
    width: 30%;
    height: 0;
    bottom: 0;
    left: 36%;
    border-bottom: 3px solid #285caa;
    border-top: 3px solid #285caa;
    border-radius: 25px;
    background-color: #285caa;
  }
}

.context_narchome .need-plot .impact-wrap {
  float: left;
  width: 100%;
}

.context_narchome .need-plot .impact-wrap .need-icon {
  min-height: 430px;
  background-position: center top;
  background-size: 70%;
  background-repeat: no-repeat;
  padding: 0 15%;
}

@media (max-width: 1112px) {
  .context_narchome .need-plot .impact-wrap .need-icon {
    background-position: center;
    background-size: 100%;
  }
}

@media (max-width: 1024px) {
  .context_narchome .need-plot .impact-wrap .need-icon {
    padding: 0 5%;
  }
}

@media (max-width: 480px) {
  .context_narchome .need-plot .impact-wrap .need-icon {
    background-size: 130%;
    margin: 50px 0;
    min-height: 200px;
  }
}

.context_narchome .need-plot .impact-wrap .need-icon .partner-col {
  float: left;
  width: 33%;
}

@media (max-width: 1024px) {
  .context_narchome .need-plot .impact-wrap .need-icon .partner-col {
    width: 45%;
  }
}

@media (max-width: 768px) {
  .context_narchome .need-plot .impact-wrap .need-icon .partner-col {
    width: 30%;
  }
}

.context_narchome .need-plot .impact-wrap .need-icon .partner-col.right {
  float: right;
}

.context_narchome .need-plot .impact-wrap .need-icon .partner-col.right .partner-block {
  float: left;
}

.context_narchome .need-plot .impact-wrap .need-icon .partner-col .partner-block {
  float: right;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #285caa;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
  padding: 19px 42px;
  margin-top: 51px;
  cursor: default;
  margin-right: 11px;
  margin-left: 7px;
}

@media (max-width: 768px) {
  .context_narchome .need-plot .impact-wrap .need-icon .partner-col .partner-block {
    margin-right: 4px;
    margin-left: 0px;
  }
}

@media (max-width: 480px) {
  .context_narchome .need-plot .impact-wrap .need-icon .partner-col .partner-block {
    margin-right: 18px;
    margin-left: 16px;
  }
}

@media (max-width: 320px) {
  .context_narchome .need-plot .impact-wrap .need-icon .partner-col .partner-block {
    margin-right: 20px;
    margin-left: 16px;
    font-size: 11px;
  }
}

.context_narchome .need-plot .impact-wrap .need-icon .partner-col .partner-block.second-block {
  margin-top: 87px;
  margin-right: 22px;
  margin-left: 18px;
}

@media (max-width: 768px) {
  .context_narchome .need-plot .impact-wrap .need-icon .partner-col .partner-block.second-block {
    margin-top: 60px;
    margin-left: 11px;
  }
}

@media (max-width: 736px) {
  .context_narchome .need-plot .impact-wrap .need-icon .partner-col .partner-block.second-block {
    margin-top: 50px;
  }
}

@media (max-width: 480px) {
  .context_narchome .need-plot .impact-wrap .need-icon .partner-col .partner-block.second-block {
    margin-top: 25px;
    margin-left: 23px;
  }
}

.context_narchome .need-plot .impact-wrap .need-icon .partner-col .partner-block.third-block {
  margin-top: 60px;
  margin-right: 12px;
  margin-left: 7px;
}

@media (max-width: 768px) {
  .context_narchome .need-plot .impact-wrap .need-icon .partner-col .partner-block.third-block {
    margin-top: 43px;
    margin-right: 5px;
    margin-left: 0px;
  }
}

@media (max-width: 480px) {
  .context_narchome .need-plot .impact-wrap .need-icon .partner-col .partner-block.third-block {
    margin-top: 17px;
    margin-right: 20px;
    margin-left: 17px;
  }
}

@media (max-width: 480px) {
  .context_narchome .need-plot .impact-wrap .need-icon .partner-col .partner-block.third-block {
    margin-top: 17px;
    margin-right: 18px;
    margin-left: 17px;
  }
}

@media (max-width: 480px) {
  .context_narchome .need-plot .impact-wrap .need-icon .partner-col .partner-block {
    font-size: 12px;
    margin-top: 10px;
    padding: 10px 9px;
  }
}

.context_narchome .need-plot .impact-wrap .need-icon .partner-col .partner-block:hover {
  background-color: #285caa;
  color: #fff;
}

.context_narchome .context.narc-sub-context {
  padding-bottom: 1px;
}

.context_narchome .narcworks {
  float: left;
  width: 100%;
  margin-top: 100px;
}

.context_narchome .narcworks .head {
  float: left;
  width: 100%;
  text-align: center;
  position: relative;
}

.context_narchome .narcworks .head::before {
  content: "";
  position: absolute;
  width: 20%;
  height: 5px;
  background-color: #285caa;
  border-radius: 25px;
  left: 40%;
  bottom: -15px;
}

.context_narchome .narcworks .head .title {
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  font-weight: bold;
  color: #393939;
  position: relative;
}

@media (max-width: 768px) {
  .context_narchome .narcworks .head .title {
    font-size: 24px;
  }
}

@media (max-width: 480px) {
  .context_narchome .narcworks .head .title {
    font-size: 20px;
    line-height: 22px;
  }
}

.context_narchome .narcworks .sectionBlock {
  float: left;
  width: 100%;
  margin-top: 80px;
}

@media (max-width: 768px) {
  .context_narchome .narcworks .sectionBlock {
    margin-top: 100px;
  }
}

@media (max-width: 480px) {
  .context_narchome .narcworks .sectionBlock {
    margin-top: 100px;
  }
}

.context_narchome .narcworks .sectionBlock .leftSection {
  float: left;
  width: 30%;
  min-height: 400px;
}

.context_narchome .narcworks .sectionBlock .leftSection .block {
  float: right;
  width: 310px;
  padding: 14px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}

@media (max-width: 1024px) {
  .context_narchome .narcworks .sectionBlock .leftSection .block {
    width: 88%;
  }
}

@media (max-width: 768px) {
  .context_narchome .narcworks .sectionBlock .leftSection .block {
    width: calc(100% - 250px);
    float: left;
  }
}

@media (max-width: 480px) {
  .context_narchome .narcworks .sectionBlock .leftSection .block {
    width: calc(100% - 110px);
    padding: 10px;
  }
}

.context_narchome .narcworks .sectionBlock .leftSection .block:nth-child(2) {
  margin-top: 120px;
  margin-right: -40px;
}

@media (max-width: 1024px) {
  .context_narchome .narcworks .sectionBlock .leftSection .block:nth-child(2) {
    margin-top: 84px;
  }
}

@media (max-width: 768px) {
  .context_narchome .narcworks .sectionBlock .leftSection .block:nth-child(2) {
    margin-right: 0;
    margin-top: -35px;
    margin-left: -10px;
  }
}

@media (max-width: 480px) {
  .context_narchome .narcworks .sectionBlock .leftSection .block:nth-child(2) {
    margin-left: 0;
  }
}

.context_narchome .narcworks .sectionBlock .leftSection .block:nth-child(3) {
  margin-top: 55px;
  margin-right: 20px;
}

@media (max-width: 768px) {
  .context_narchome .narcworks .sectionBlock .leftSection .block:nth-child(3) {
    margin-right: 0;
    margin-top: 150px;
    margin-left: 25px;
  }
}

@media (max-width: 480px) {
  .context_narchome .narcworks .sectionBlock .leftSection .block:nth-child(3) {
    margin-top: 70px;
    margin-left: 20px;
  }
}

.context_narchome .narcworks .sectionBlock .leftSection .block:nth-child(4) {
  margin-top: 55px;
  margin-right: -20px;
}

@media (max-width: 768px) {
  .context_narchome .narcworks .sectionBlock .leftSection .block:nth-child(4) {
    margin-right: 0;
    margin-top: 110px;
  }
}

@media (max-width: 480px) {
  .context_narchome .narcworks .sectionBlock .leftSection .block:nth-child(4) {
    margin-top: 65px;
  }
}

.context_narchome .narcworks .sectionBlock .leftSection .block .img {
  float: left;
  width: 40px;
  height: 40px;
  padding: 12px;
  border-radius: 100%;
  background-color: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  margin-top: -10px;
  margin-left: -10px;
}

.context_narchome .narcworks .sectionBlock .leftSection .block .img img {
  float: left;
  width: 100%;
}

@media (max-width: 480px) {
  .context_narchome .narcworks .sectionBlock .leftSection .block .img {
    margin-top: -6px;
    margin-left: -6px;
  }
}

.context_narchome .narcworks .sectionBlock .leftSection .block .text {
  float: left;
  width: calc(100% - 30px);
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #333;
  padding-left: 15px;
  margin-top: 0px;
}

@media (max-width: 480px) {
  .context_narchome .narcworks .sectionBlock .leftSection .block .text {
    width: calc(100% - 35px);
    padding-left: 6px;
    margin-top: 0;
  }
}

.context_narchome .narcworks .sectionBlock .leftSection .infographic {
  float: left;
  width: 120px;
  display: none;
}

.context_narchome .narcworks .sectionBlock .leftSection .infographic img {
  float: left;
  width: 100%;
}

@media (max-width: 768px) {
  .context_narchome .narcworks .sectionBlock .leftSection .infographic {
    display: block;
  }
}

@media (max-width: 480px) {
  .context_narchome .narcworks .sectionBlock .leftSection .infographic {
    width: 80px;
    margin-top: 0px;
  }
}

@media (max-width: 768px) {
  .context_narchome .narcworks .sectionBlock .leftSection {
    width: 100%;
  }
}

.context_narchome .narcworks .sectionBlock .middleSection {
  float: left;
  width: 40%;
}

.context_narchome .narcworks .sectionBlock .middleSection .img {
  float: left;
  width: 100%;
}

.context_narchome .narcworks .sectionBlock .middleSection .img img {
  float: left;
  width: 100%;
}

@media (max-width: 768px) {
  .context_narchome .narcworks .sectionBlock .middleSection {
    display: none;
  }
}

.context_narchome .narcworks .sectionBlock .rightSection {
  float: left;
  width: 30%;
  min-height: 400px;
}

.context_narchome .narcworks .sectionBlock .rightSection .block {
  float: left;
  width: 310px;
  padding: 14px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}

@media (max-width: 1024px) {
  .context_narchome .narcworks .sectionBlock .rightSection .block {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .context_narchome .narcworks .sectionBlock .rightSection .block {
    width: calc(100% - 250px);
    float: right;
  }
}

@media (max-width: 480px) {
  .context_narchome .narcworks .sectionBlock .rightSection .block {
    width: calc(100% - 110px);
    margin-top: 70px;
    padding: 10px;
  }
}

.context_narchome .narcworks .sectionBlock .rightSection .block:nth-child(2) {
  margin-top: 120px;
  margin-left: 0;
}

@media (max-width: 1024px) {
  .context_narchome .narcworks .sectionBlock .rightSection .block:nth-child(2) {
    margin-top: 60px;
  }
}

@media (max-width: 768px) {
  .context_narchome .narcworks .sectionBlock .rightSection .block:nth-child(2) {
    margin-top: 90px;
    margin-left: 0;
  }
}

@media (max-width: 480px) {
  .context_narchome .narcworks .sectionBlock .rightSection .block:nth-child(2) {
    margin-top: 37px;
  }
}

.context_narchome .narcworks .sectionBlock .rightSection .block:nth-child(3) {
  margin-top: 50px;
  margin-left: 40px;
}

@media (max-width: 768px) {
  .context_narchome .narcworks .sectionBlock .rightSection .block:nth-child(3) {
    margin-top: 150px;
    margin-left: 0;
    margin-right: 20px;
  }
}

@media (max-width: 480px) {
  .context_narchome .narcworks .sectionBlock .rightSection .block:nth-child(3) {
    margin-top: 70px;
    margin-left: 0;
    margin-right: 20px;
  }
}

.context_narchome .narcworks .sectionBlock .rightSection .block:nth-child(4) {
  margin-top: 50px;
  margin-left: -25px;
}

@media (max-width: 768px) {
  .context_narchome .narcworks .sectionBlock .rightSection .block:nth-child(4) {
    margin-top: 105px;
    margin-left: 0;
  }
}

@media (max-width: 480px) {
  .context_narchome .narcworks .sectionBlock .rightSection .block:nth-child(4) {
    margin-top: 65px;
  }
}

.context_narchome .narcworks .sectionBlock .rightSection .block .img {
  float: left;
  width: 40px;
  height: 40px;
  padding: 12px;
  border-radius: 100%;
  background-color: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  margin-top: -10px;
  margin-left: -10px;
}

.context_narchome .narcworks .sectionBlock .rightSection .block .img img {
  float: left;
  width: 100%;
}

@media (max-width: 480px) {
  .context_narchome .narcworks .sectionBlock .rightSection .block .img {
    margin-top: -6px;
    margin-left: -6px;
  }
}

.context_narchome .narcworks .sectionBlock .rightSection .block .text {
  float: left;
  width: calc(100% - 30px);
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #333;
  padding-left: 15px;
  margin-top: 0px;
}

@media (max-width: 480px) {
  .context_narchome .narcworks .sectionBlock .rightSection .block .text {
    width: calc(100% - 35px);
    padding-left: 6px;
    margin-top: 0;
  }
}

.context_narchome .narcworks .sectionBlock .rightSection .infographic {
  float: right;
  width: 120px;
  display: none;
}

.context_narchome .narcworks .sectionBlock .rightSection .infographic img {
  float: left;
  width: 100%;
}

@media (max-width: 768px) {
  .context_narchome .narcworks .sectionBlock .rightSection .infographic {
    display: block;
    margin-top: 120px;
  }
}

@media (max-width: 480px) {
  .context_narchome .narcworks .sectionBlock .rightSection .infographic {
    width: 80px;
    margin-top: 71px;
  }
}

@media (max-width: 768px) {
  .context_narchome .narcworks .sectionBlock .rightSection {
    width: 100%;
  }
}

.context_narchome .narcworks {
  margin-top: 0px;
}

.context_narchome .testimonials {
  float: left;
  width: 100%;
  margin-top: 0;
  margin-bottom: 100px;
  padding-left: calc((100% - 1140px) / 2);
  padding-right: calc((100% - 1140px) / 2);
}

@media (max-width: 768px) {
  .context_narchome .testimonials {
    margin-top: 50px;
    padding: 0 15px;
    margin-bottom: 40px;
  }
}

@media (max-width: 480px) {
  .context_narchome .testimonials {
    margin-top: 60px;
    margin-bottom: 50px;
    padding: 0px;
  }
}

.context_narchome .testimonials .head {
  float: left;
  width: 100%;
  text-align: center;
  position: relative;
}

.context_narchome .testimonials .head::before {
  content: "";
  position: absolute;
  width: 20%;
  height: 5px;
  background-color: #285caa;
  border-radius: 25px;
  left: 40%;
  bottom: -20px;
}

@media (max-width: 768px) {
  .context_narchome .testimonials .head::before {
    width: 30%;
    left: 35%;
  }
}

.context_narchome .testimonials .head .title {
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  font-weight: bold;
  color: #393939;
}

@media (max-width: 768px) {
  .context_narchome .testimonials .head .title {
    font-size: 24px;
  }
}

@media (max-width: 480px) {
  .context_narchome .testimonials .head .title {
    font-size: 20px;
    line-height: 22px;
  }
}

.context_narchome .testimonials .head .subText {
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #393939;
  position: relative;
  margin-top: 5px;
}

@media (max-width: 480px) {
  .context_narchome .testimonials .head .subText {
    font-size: 16px;
  }
}

.context_narchome .testimonials .block {
  float: left;
  width: 100%;
  margin-top: 50px;
  padding: 0;
}

.context_narchome .testimonials .block .slick-list {
  padding: 0 0 0 3px;
}

.context_narchome .testimonials .block .slick-slider {
  padding: 0;
  user-select: text;
}

@media (max-width: 768px) {
  .context_narchome .testimonials .block .slick-slider {
    padding: 0;
  }
}

.context_narchome .testimonials .block .slick-slide {
  padding-right: 20px;
}

@media (max-width: 480px) {
  .context_narchome .testimonials .block .slick-slide {
    padding: 10px;
  }
}

.context_narchome .testimonials .block .slick-slide div {
  float: left;
  width: 100%;
}

.context_narchome .testimonials .block .slick-next,
.context_narchome .testimonials .block .slick-prev {
  z-index: 1;
}

.context_narchome .testimonials .block .slick-prev {
  left: -14px;
  font-size: 25px;
  color: #000;
  width: 30px;
  height: 30px;
  line-height: 47px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
  z-index: 3;
}

@media (max-width: 768px) {
  .context_narchome .testimonials .block .slick-prev {
    left: -4px;
    top: 50%;
    width: 30px;
    height: 30px;
    border-radius: 0;
  }
}

.context_narchome .testimonials .block .slick-prev .icon {
  position: relative;
  top: -9px;
  left: 1px;
  font-size: 23px;
}

@media (max-width: 768px) {
  .context_narchome .testimonials .block .slick-prev .icon {
    top: -8px;
    left: -1px;
    font-size: 23px;
  }
}

.context_narchome .testimonials .block .slick-prev .icon:before {
  color: #c00096;
}

.context_narchome .testimonials .block .slick-next {
  right: 0;
  font-size: 25px;
  color: #000;
  width: 30px;
  height: 30px;
  line-height: 47px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  z-index: 3;
}

@media (max-width: 768px) {
  .context_narchome .testimonials .block .slick-next {
    right: -8px;
    top: 50%;
    width: 30px;
    height: 30px;
    border-radius: 0;
  }
}

.context_narchome .testimonials .block .slick-next .icon {
  position: relative;
  top: -9px;
  right: -1px;
  font-size: 23px;
}

@media (max-width: 768px) {
  .context_narchome .testimonials .block .slick-next .icon {
    top: -8px;
    right: -1px;
    font-size: 23px;
  }
}

.context_narchome .testimonials .block .slick-next .icon:before {
  color: #c00096;
}

.context_narchome .testimonials .block .slick-next:before,
.context_narchome .testimonials .block .slick-prev:before {
  font-size: 30px;
  color: #393939;
  opacity: 1;
  display: none;
}

.context_narchome .testimonials .block .slick-dots {
  display: none !important;
}

@media (max-width: 768px) {
  .context_narchome .testimonials .block .slick-dots {
    display: none !important;
  }
}

.context_narchome .testimonials .block .slick-dots li {
  width: 8px;
  height: 8px;
}

.context_narchome .testimonials .block .slick-dots li button {
  border: solid 2px #285caa;
  border-radius: 100%;
  width: 8px;
  height: 8px;
}

.context_narchome .testimonials .block .slick-dots li button::before {
  font-size: 14px;
  top: -2px;
  opacity: 0;
  color: #285caa;
  width: 8px;
  height: 8px;
}

.context_narchome .testimonials .block .slick-dots li.slick-active button:before {
  opacity: 1;
}

.context_narchome .testimonials .block .slick-track {
  display: flex !important;
}

.context_narchome .testimonials .block .slick-slide {
  height: inherit !important;
}

.context_narchome .testimonials .block .slick-slide>div {
  display: flex;
  width: 100%;
  height: 100%;
}

.context_narchome .testimonials .block .testimonial {
  float: left;
  width: 100%;
  padding: 20px;
  background-color: #fff;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  margin-top: 10px;
  margin-bottom: 5px;
  border-radius: 15px;
}

.context_narchome .testimonials .block .testimonial.videoPost {
  position: relative;
  padding: 0;
}

.context_narchome .testimonials .block .testimonial.videoPost .artPlot {
  display: none;
}

.context_narchome .testimonials .block .testimonial.videoPost .content {
  display: none;
}

.context_narchome .testimonials .block .testimonial.videoPost .video {
  display: block;
}

.context_narchome .testimonials .block .testimonial .video {
  float: left;
  display: none;
  width: 100%;
  height: 180px;
  border-radius: 15px;
  z-index: -1;
}

.context_narchome .testimonials .block .testimonial .artPlot {
  float: left;
  width: 80px;
}

.context_narchome .testimonials .block .testimonial .artPlot .img {
  float: left;
  width: 80px;
  height: 120px;
  border: solid 1px #285caa;
  border-radius: 15px;
}

.context_narchome .testimonials .block .testimonial .artPlot .img img {
  float: left;
  width: 80px;
  height: 120px;
  border-radius: 15px;
  margin-left: 5px;
  margin-top: -5px;
  margin-bottom: 5px;
  object-fit: cover;
  object-position: center top;
}

.context_narchome .testimonials .block .testimonial .text {
  float: left;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #393939;
  padding-top: 8px;
  text-align: left;
}

.context_narchome .testimonials .block .testimonial .content {
  float: left;
  width: calc(100% - 80px);
  padding-left: 30px;
  padding-right: 15px;
}

.context_narchome .testimonials .block .testimonial .content .testimonialText {
  float: left;
  width: 100%;
  min-height: 100px;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  color: #393939;
  display: flex;
  align-items: center;
  text-align: center;
}

.context_narchome .testimonials .block .testimonial .content .testimonialauthor {
  float: right;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  color: #777;
  margin-top: 5px;
}

.context_narchome .testimonials {
  margin-top: 100px;
  margin-bottom: 50px;
}

@media (max-width: 480px) {
  .context_narchome .testimonials {
    margin-bottom: 0;
  }
}

.context_narchome .narc-sponcer {
  float: left;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 20px;
  padding-bottom: 60px;
}

@media (max-width: 1024px) {
  .context_narchome .narc-sponcer {
    padding: 30px 15px 80px;
  }
}

@media (max-width: 480px) {
  .context_narchome .narc-sponcer {
    padding: 0;
    margin-top: 70px;
    margin-bottom: 50px;
  }
}

.context_narchome .narc-sponcer .head {
  float: left;
  width: 100%;
  text-align: center;
  position: relative;
}

.context_narchome .narc-sponcer .head::before {
  content: "";
  position: absolute;
  width: 10%;
  height: 5px;
  background-color: #285caa;
  border-radius: 25px;
  left: 45%;
  bottom: -15px;
}

.context_narchome .narc-sponcer .head .title {
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
  font-weight: bold;
  color: #393939;
}

@media (max-width: 768px) {
  .context_narchome .narc-sponcer .head .title {
    font-size: 24px;
  }
}

@media (max-width: 480px) {
  .context_narchome .narc-sponcer .head .title {
    font-size: 20px;
    line-height: 22px;
  }
}

.context_narchome .narc-sponcer .head .subText {
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #393939;
  position: relative;
  margin-top: 5px;
}

@media (max-width: 480px) {
  .context_narchome .narc-sponcer .head .subText {
    font-size: 16px;
  }
}

.context_narchome .narc-sponcer .sectionBlock {
  float: left;
  width: 100%;
  margin-top: 50px;
  text-align: center;
}

@media (max-width: 1112px) {
  .context_narchome .narc-sponcer .sectionBlock {
    margin-bottom: 20px;
  }
}

.context_narchome .narc-sponcer .sectionBlock .block {
  float: none;
  width: calc((100% - 300px) / 4);
  display: inline-block;
  margin-right: 100px;
}

.context_narchome .narc-sponcer .sectionBlock .block:last-child {
  margin-right: 0px;
}

@media (max-width: 480px) {
  .context_narchome .narc-sponcer .sectionBlock .block {
    width: calc((100% - 20px) / 2);
    margin-right: 20px;
    text-align: center;
  }

  .context_narchome .narc-sponcer .sectionBlock .block:nth-child(2n) {
    margin-right: 0;
  }
}

.context_narchome .narc-sponcer .sectionBlock .block .img {
  float: left;
  width: 100%;
  padding: 0;
}

@media (max-width: 480px) {
  .context_narchome .narc-sponcer .sectionBlock .block .img {
    float: none;
    width: 65%;
    display: inline-block;
  }
}

.context_narchome .narc-sponcer .sectionBlock .block .img img {
  float: left;
  width: 100%;
}

.context_narchome .narc-sponcer .sectionBlock button {
  float: left;
  width: 230px;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #c41d8e;
  border: solid 1px #c41d8e;
  background-color: #fff;
  border-radius: 25px;
  line-height: 50px;
  margin-top: 30px;
  margin-left: calc((100% - 230px) / 2);
  cursor: pointer;
}

.context_narchome .narc-sponcer .sectionBlock button:hover {
  background-color: #c41d8e;
  color: #fff !important;
}

.context_narchome .narc-sponcer .sponset-btn-plot {
  float: left;
  width: 100%;
  text-align: center;
}

.context_narchome .narc-sponcer .sponset-btn-plot button {
  float: left;
  width: 230px;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #c41d8e;
  border: solid 1px #c41d8e;
  background-color: #fff;
  border-radius: 25px;
  line-height: 50px;
  margin-top: 30px;
  margin-left: calc((100% - 230px) / 2);
  cursor: pointer;
}

.context_narchome .narc-sponcer .sponset-btn-plot button:hover {
  background-color: #c41d8e;
  color: #fff !important;
}

.narcLand {
  float: left;
  width: 100%;
}

.narcLand .champions {
  float: left;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 20px;
  margin-top: 0;
}

@media (max-width: 1024px) {
  .narcLand .champions {
    padding: 0 15px;
    margin-top: 60px;
  }
}

@media (max-width: 480px) {
  .narcLand .champions {
    margin-top: 30px;
  }
}

.narcLand .champions .head {
  float: left;
  width: 100%;
  text-align: center;
  position: relative;
}

.narcLand .champions .head::before {
  content: "";
  position: absolute;
  width: 10%;
  height: 5px;
  background-color: #285caa;
  border-radius: 25px;
  left: 45%;
  bottom: -15px;
}

.narcLand .champions .head .title {
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  font-weight: bold;
  color: #393939;
  position: relative;
}

@media (max-width: 768px) {
  .narcLand .champions .head .title {
    font-size: 24px;
  }
}

@media (max-width: 480px) {
  .narcLand .champions .head .title {
    font-size: 20px;
    line-height: 22px;
  }
}

.narcLand .champions .sectionBlock {
  float: left;
  width: 100%;
  padding-left: calc((100% - 1320px) / 2);
  padding-right: calc((100% - 1320px) / 2);
  padding-top: 80px;
  padding-bottom: 80px;
}

@media (max-width: 480px) {
  .narcLand .champions .sectionBlock {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.narcLand .champions .sectionBlock .block {
  float: left;
  width: 100%;
  padding-left: calc((100% - 1140px) / 2);
  padding-right: calc((100% - 1140px) / 2);
}

@media (max-width: 1024px) {
  .narcLand .champions .sectionBlock .block {
    width: 80%;
    margin-left: 10%;
  }
}

@media (max-width: 768px) {
  .narcLand .champions .sectionBlock .block {
    width: 90%;
    margin-left: 8%;
  }
}

@media (max-width: 480px) {
  .narcLand .champions .sectionBlock .block {
    width: 100%;
    margin-left: 0;
  }
}

.narcLand .champions .sectionBlock .block .slick-slider {
  padding: 0;
}

@media (max-width: 768px) {
  .narcLand .champions .sectionBlock .block .slick-slider {
    padding: 0;
  }
}

@media (max-width: 480px) {
  .narcLand .champions .sectionBlock .block .slick-slide {
    padding: 10px;
  }
}

.narcLand .champions .sectionBlock .block .slick-slide div {
  float: left;
  width: 100%;
}

.narcLand .champions .sectionBlock .block .slick-next,
.narcLand .champions .sectionBlock .block .slick-prev {
  z-index: 1;
}

.narcLand .champions .sectionBlock .block .slick-prev {
  left: -14px;
  font-size: 25px;
  color: #000;
  width: 30px;
  height: 30px;
  line-height: 47px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
  z-index: 3;
}

@media (max-width: 768px) {
  .narcLand .champions .sectionBlock .block .slick-prev {
    left: -4px;
    top: 40%;
    width: 30px;
    height: 30px;
    border-radius: 0;
  }
}

.narcLand .champions .sectionBlock .block .slick-prev .icon {
  position: relative;
  top: -9px;
  left: 1px;
  font-size: 23px;
}

@media (max-width: 768px) {
  .narcLand .champions .sectionBlock .block .slick-prev .icon {
    top: -8px;
    left: -1px;
    font-size: 23px;
  }
}

.narcLand .champions .sectionBlock .block .slick-prev .icon:before {
  color: #c00096;
}

.narcLand .champions .sectionBlock .block .slick-next {
  right: -14px;
  font-size: 25px;
  color: #000;
  width: 30px;
  height: 30px;
  line-height: 47px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  z-index: 3;
}

@media (max-width: 768px) {
  .narcLand .champions .sectionBlock .block .slick-next {
    right: -8px;
    top: 40%;
    width: 30px;
    height: 30px;
    border-radius: 0;
  }
}

.narcLand .champions .sectionBlock .block .slick-next .icon {
  position: relative;
  top: -9px;
  right: -1px;
  font-size: 23px;
}

@media (max-width: 768px) {
  .narcLand .champions .sectionBlock .block .slick-next .icon {
    top: -8px;
    right: -1px;
    font-size: 23px;
  }
}

.narcLand .champions .sectionBlock .block .slick-next .icon:before {
  color: #c00096;
}

.narcLand .champions .sectionBlock .block .slick-next:before,
.narcLand .champions .sectionBlock .block .slick-prev:before {
  font-size: 30px;
  color: #393939;
  opacity: 1;
  display: none;
}

.narcLand .champions .sectionBlock .block .slick-dots {
  display: none !important;
}

@media (max-width: 768px) {
  .narcLand .champions .sectionBlock .block .slick-dots {
    display: none !important;
  }
}

.narcLand .champions .sectionBlock .block .slick-dots li {
  width: 8px;
  height: 8px;
}

.narcLand .champions .sectionBlock .block .slick-dots li button {
  border: solid 2px #285caa;
  border-radius: 100%;
  width: 8px;
  height: 8px;
}

.narcLand .champions .sectionBlock .block .slick-dots li button::before {
  font-size: 14px;
  top: -2px;
  opacity: 0;
  color: #285caa;
  width: 8px;
  height: 8px;
}

.narcLand .champions .sectionBlock .block .slick-dots li.slick-active button:before {
  opacity: 1;
}

.narcLand .champions .sectionBlock .block .slick-track {
  display: flex !important;
}

.narcLand .champions .sectionBlock .block .slick-slide {
  height: inherit !important;
}

.narcLand .champions .sectionBlock .block .slick-slide>div {
  display: flex;
  width: 100%;
  height: 100%;
}

.narcLand .champions .sectionBlock .block .champ {
  float: left;
  width: calc((100% - 80px) / 3);
  margin-right: 40px;
}

.narcLand .champions .sectionBlock .block .champ:last-child {
  margin-right: 0;
  padding-left: 10px;
  padding-right: 10px;
}

@media (max-width: 480px) {
  .narcLand .champions .sectionBlock .block .champ {
    width: 100%;
    margin-right: 0;
    margin-bottom: 40px;
  }
}

.narcLand .champions .sectionBlock .block .champ .slot {
  float: left;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
}

.narcLand .champions .sectionBlock .block .champ .slot .img {
  float: left;
  width: 100%;
  padding: 10px;
  border-bottom: solid 1px #d6d6d6;
}

.narcLand .champions .sectionBlock .block .champ .slot .img img {
  float: left;
  width: 100%;
}

.narcLand .champions .sectionBlock .block .champ .slot .content {
  float: left;
  width: 100%;
}

.narcLand .champions .sectionBlock .block .champ .slot .content .date {
  float: left;
  width: 40%;
  line-height: 20px;
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  color: #333;
  padding: 5px 0;
  text-align: center;
  background-color: #e4efff;
}

.narcLand .champions .sectionBlock .block .champ .slot .content .explore {
  float: right;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #333;
  padding: 5px 0;
  margin-top: 12px;
  margin-right: 15px;
}

.narcLand .champions .sectionBlock .block .champ .slot .content .explore img {
  width: 10px;
  float: right;
  margin-left: 10px;
}

.narcLand .champions .sectionBlock .block .champ .title {
  float: left;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  text-align: center;
  margin-top: 25px;
}

.narcLand .champions .sectionBlock button {
  float: left;
  width: 230px;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #c41d8e;
  border: solid 1px #c41d8e;
  background-color: #fff;
  border-radius: 25px;
  line-height: 50px;
  margin-top: 60px;
  margin-left: calc((100% - 230px) / 2);
  cursor: pointer;
}

@media (max-width: 480px) {
  .narcLand .champions .sectionBlock button {
    margin-top: 20px;
  }
}

.narcLand .champions .sectionBlock button:hover {
  background-color: #c41d8e;
  color: #fff !important;
}

.narcLand .narcBenifit {
  float: left;
  width: 100%;
  margin-top: 30px;
  padding-bottom: 0px;
}

@media (max-width: 1024px) {
  .narcLand .narcBenifit {
    padding: 0 15px;
    margin-top: 80px;
  }
}

.narcLand .narcBenifit .head {
  float: left;
  width: 100%;
  text-align: center;
  position: relative;
}

.narcLand .narcBenifit .head::before {
  content: "";
  position: absolute;
  width: 10%;
  height: 5px;
  background-color: #285caa;
  border-radius: 25px;
  left: 45%;
  bottom: -15px;
}

.narcLand .narcBenifit .head .title {
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  font-weight: bold;
  color: #393939;
  position: relative;
}

@media (max-width: 768px) {
  .narcLand .narcBenifit .head .title {
    font-size: 24px;
  }
}

@media (max-width: 480px) {
  .narcLand .narcBenifit .head .title {
    font-size: 20px;
    line-height: 22px;
  }
}

.narcLand .narcBenifit .sectionBlock {
  float: left;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-left: calc((100% - 1140px) / 2);
  padding-right: calc((100% - 1140px) / 2);
  padding-top: 80px;
}

.narcLand .narcBenifit .sectionBlock .block {
  float: left;
  width: calc(100% / 3);
  margin-bottom: 50px;
}

@media (max-width: 480px) {
  .narcLand .narcBenifit .sectionBlock .block {
    width: 100%;
  }
}

.narcLand .narcBenifit .sectionBlock .block .img {
  float: left;
  width: 100%;
  height: 100px;
  padding: 0 15%;
}

@media (max-width: 768px) {
  .narcLand .narcBenifit .sectionBlock .block .img {
    padding: 0 10%;
  }
}

.narcLand .narcBenifit .sectionBlock .block .img img {
  float: left;
  width: 100%;
  height: 100%;
}

.narcLand .narcBenifit .sectionBlock .block .text {
  float: left;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  text-align: center;
  margin-top: 50px;
  padding: 0 25%;
}

@media (max-width: 768px) {
  .narcLand .narcBenifit .sectionBlock .block .text {
    padding: 0 10%;
  }
}

/*# sourceMappingURL=narc-landing.css.map */