@media (max-width: 1160px) {
  .adjust-padding {
    padding: 0px 20px;
  }
}

@media (max-width: 981px) {
  #register-page .tabs .tab-buttons a.tab-btns {
    padding: 9px 36px;
  }
}

@media (max-width: 860px) {
  #register-page .tabs .tab-buttons a.tab-btns {
    padding: 9px 26px;
  }
}

@media (max-width: 1024px) {
  .top-banner-sec {
    height: 580px;
  }

  .register-new-child.card {
    padding: 20px 12px;
  }

  #register-page .participants-img {
    width: 240px;
    height: 160px;
  }

  #register-page .tabs .upper-sec .hdng,
  #register-page .sec-hdng p {
    padding: 3rem 0 1rem;
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  #register-page .col-md-7 {
    width: 58.3333333333% !important;
  }
}

@media (max-width: 767px) {
  #register-page .top-banner-sec {
    height: max-content;
    background-position: center;
  }

  #register-page .form-sec {
    padding: 3rem 0px;
  }

  #register-page .banner-overly .lndng-banner-form {
    order: 2;
  }

  #register-page .banner-overly .bnr-content-sec {
    order: 1;
    height: auto;
    padding-left: 32px;
    padding-top: 60px;
  }

  #register-page .col-md-7.bnr-content-sec {
    width: 100%;
  }

  #register-page .content-sec h1.hdng {
    font-size: 25px;
  }

  #register-page .content-sec p.desc-para {
    margin: 10px 0px;
  }

  #register-page .content-sec p.timeline {
    font-size: 14px;
    font-weight: 800;
  }

  /*  */
  #register-page .tabs .tab-buttons {
    padding-top: 18px;
  }

  #register-page .tabs .tab-buttons ul {
    justify-content: stretch;
    row-gap: 14px;
    column-gap: 10px;
  }

  #register-page .tabs .tab-buttons ul li.nav-item {
    display: contents;
  }

  #register-page #benefitCards {
    padding: 0rem 0;
  }

  #register-page #benefitCards .row .card {
    margin-top: 6rem;
  }

  #register-page .participants-card.row .col-12.col-md-4 {
    margin-bottom: 3rem;
  }

  #register-page .participants-card.row .col-12.col-md-4:last-child {
    margin-bottom: 0px;
  }

  #register-page .details.adjust-padding {
    padding: 0px;
  }

  #register-page .details .content {
    padding-top: 1rem;
  }

  #register-page .register-card-outer.adjust-padding {
    padding: 0px;
  }

  #register-page .register-card-outer .register-now {
    border-radius: 0px;
  }

  #register-page .register-card-outer .register-now {
    padding: 2rem 1rem;
  }

  #register-page .register-card-outer .register-now p.hdng {
    font-size: 20px;
  }

  #register-page .register-card-outer .register-now p.para {
    margin: 1rem 0 1.5rem;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .benefit-sec .sec-hdng p::after {
    height: 60px;
    width: 60px;
    transform: translate(-32%, -28%);
  }

  #register-page #benefitCards .row .card .card-img {
    width: 80px;
    height: 80px;
    top: -28%;
  }

  #register-page #benefitCards .row .card .card-text {
    height: 86px;
    padding: 0px 10px;
  }

  #register-page #benefitCards .row .card .card-text p span {
    font-size: 13px;
    margin-left: 8px;
    line-height: 18px;
  }
}