@media screen and (min-width: 320px) and (max-width: 480px) {
  .activity-details .narc-submission-form .formfram .formSection {
    min-height: 0 !important;
    padding: 0 15px;
  }

  .activity-details .narc-submission-form .formfram .formSection .head {
    display: none;
  }

  .activity-details .narc-submission-form .formfram .formSection .form.stepTwo {
    display: none;
  }

  .activity-details .banner-sec {
    height: 200px !important;
    margin-bottom: 0px;
  }

  .content-sec.d-block.container.mt-5 {
    margin-top: 2rem !important;
  }

  .activity-details .select-users {
    top: 150px !important;
    width: 260px;
  }

  .activity-details .form-select.select-users-option {
    margin: 0 auto;
    padding: 7px 10px !important;
  }

  .activity-details .box-outer-sec {
    padding: 20px 12px !important;
  }

  #submissions .sub-emp-content p {
    font-size: 22px;
  }

  .activity-details .add-img {
    display: block;
  }

  .activity-details .narcreg-formBanner .form-frame {
    padding: 0px;
  }

  .activity-details .narc-submission-form {
    background-size: cover;
    height: 200px;
  }

  .activity-details#act-details .content-sec .col-5 {
    width: 40%;
  }

  .activity-details#act-details .tab-header ul li {
    margin: 0px 8px;
  }

  .activity-details#act-details .tab-header ul li button {
    font-size: 14px;
  }

  .activity-details#act-details .tab-header ul li button span.mb-activity {
    display: none;
  }

  .upload-img-act img.upload-img {
    display: block;
    margin: 0 auto;
    max-height: 100px;
  }

  .activity-img-uploader .formGroup {
    width: 86%;
    margin: 0 auto;
    max-width: 400px;
  }

  #submissions .submissin-poster-post .carousel {
    height: 100% !important;
  }

  .slide___3-Nqo.slideHorizontal___1NzNV.carousel__slide.carousel__slide--visible {
    padding-bottom: 0px !important;
    height: 300px !important;
  }

  #submissions .carousel__slider--horizontal {
    height: 100% !important;
  }

  #submissions .carousel__inner-slide {
    height: 100% !important;
  }

  /*  */
  .allbooking .btn-group.profile-name .select-users {
    transform: translate(-45%, -68%);
    top: unset;
  }

  .allbooking .narcreg-formBanner .formtop-banner img {
    height: 100%;
  }

  .activity-details .content-sec .tab-header ul.nav {
    width: 100% !important;
    display: flex !important;
    justify-content: space-around;
  }
  /* eventQuiz */
  #eventQuiz .top-banner-sec{
      height: 575px;
      background-position: center;
  }
  #eventQuiz .content-sec .card-bottom-btn .btn.quiz-button-pnk-comn{
    padding: 8px 20px;
  }
}

@media screen and (min-width: 481px) and (max-width: 767px) {
  .activity-details .narc-submission-form .formfram .formSection {
    /* display: none; */
    min-height: 0 !important;
    padding: 0 15px;
  }

  .activity-details .narc-submission-form .formfram .formSection .head {
    display: none;
  }

  .activity-details .narc-submission-form .formfram .formSection .form.stepTwo {
    display: none;
  }

  .activity-details .banner-sec {
    height: 300px !important;
  }

  .activity-details .box-outer-sec {
    padding: 20px 12px !important;
  }

  .activity-details#act-details .tab-header ul li button {
    font-size: 16px;
  }

  .activity-details#act-details .tab-header ul li button span.mb-activity {
    display: none;
  }

  .activity-details .narcreg-formBanner .form-frame {
    padding: 0px;
  }

  .activity-details .narc-submission-form {
    background-size: cover;
    background-position: center;
    height: 300px;
  }

  .activity-details .add-img {
    display: block;
  }

  .activity-details#act-details .content-sec .col-5 {
    width: 40%;
  }

  .activity-details#act-details .content-sec.container {
    max-width: -webkit-fill-available;
  }

  .activity-guidlines button .guidline-icon {
    margin-bottom: 10px;
    max-height: 80px;
    width: 65px !important;
  }

  .upload-img-act img.upload-img {
    display: block;
    margin: 0 auto;
    max-height: 100px;
  }

  .activity-img-uploader .formGroup {
    width: 86% !important;
    margin: 0 auto !important;
    max-width: 400px;
  }

  #submissions .submissin-poster-post .carousel {
    height: 100% !important;
  }

  .slide___3-Nqo.slideHorizontal___1NzNV.carousel__slide.carousel__slide--visible {
    padding-bottom: 0px !important;
    height: 300px !important;
  }

  #submissions .carousel__slider--horizontal {
    height: 100% !important;
  }

  #submissions .carousel__inner-slide {
    height: 100% !important;
  }

  /*  */
  .allbooking .btn-group.profile-name .select-users {
    transform: translate(-45%, 0%);
  }

  .allbooking .narcreg-formBanner .bannerImg {
    position: unset;
  }

  .allbooking .narcreg-formBanner .formtop-banner img {
    height: 100%;
  }

  .activity-details .content-sec .tab-header ul.nav {
    width: 100% !important;
    display: flex !important;
    justify-content: space-around;
  }
  #eventQuiz .top-banner-sec{
    height: 580px;
    background-position: center;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .activity-details .banner-sec {
    height: 775px !important;
  }

  .activity-guidlines button .guidline-icon {
    margin-bottom: 10px;
    max-height: 80px;
    width: 65px !important;
  }

  .allbooking .narcreg-formBanner .bannerImg {
    position: unset !important;
  }

  .allbooking .narcreg-formBanner .bannerImg img.mb {
    object-position: 80%;
  }
  #eventQuiz .top-banner-sec{
    height: 580px;
    background-position: center;
  }
}

@media screen and (min-width: 992px) and (max-width: 1024px) {
  .activity-details .banner-sec {
    height: 600px !important;
  }

  .activity-details .narc-submission-form {
    background-image: unset !important;
  }

  .activity-details .narc-submission-form .formfram .formSection {
    margin-top: 65px;
  }

  .allbooking .narcreg-formBanner .bannerImg {
    position: unset !important;
  }

  .allbooking .narcreg-formBanner .bannerImg img.mb {
    object-position: 80%;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1199px) {
  .activity-details .banner-sec {
    height: 550px !important;
  }

  .activity-details .narc-submission-form .formfram .formSection {
    margin-top: 60px;
  }

  .activity-details .narc-submission-form .formfram {
    padding-left: 45px;
    padding-right: 45px;
  }

  .allbooking .narcreg-formBanner .bannerImg {
    position: unset !important;
  }

  .allbooking .narcreg-formBanner .bannerImg img.mb {
    object-position: 80%;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .activity-details .narc-submission-form .formfram .formSection {
    margin-top: 45px !important;
  }
}